export function userObj() {
	let cookieArr = document.cookie.split(';')
	let userObj = {}
	for(let i = 0; i < cookieArr.length; i++) {
		if(cookieArr[i].split('=')[0].includes('b_id')) {
			userObj.b_id = cookieArr[i].split('=')[1]
		} else if(cookieArr[i].split('=')[0].includes('id')) {
			userObj.b_manager_id = cookieArr[i].split('=')[1]
		}
	}
	return userObj

}
export default userObj