<template>
	<div class="task">
		<div class="taskBth">
			<el-button @click="readTaskFun">读取任务</el-button>
			<el-button @click="emptyTaskFun">清空任务</el-button>
			<el-button @click="issueTaskFun">下发任务</el-button>
			<el-button @click="organizationTaskFun">组织任务</el-button>
		</div>
		<el-table :data="taskData" border style="width: 100%" table-layout="auto" v-loading="taskLoading">
			<el-table-column label="任务ID" prop="task_id" />
			<el-table-column label="时间间隔" prop="step" />
			<el-table-column label="状态" prop="">
				<template #default="scope">
					<span v-if="scope.row.state==1">正常</span>
					<span v-if="scope.row.state==2">停用</span>
				</template>
			</el-table-column>
			<el-table-column label="开始时间" prop="start_time" />
			<el-table-column label="结束时间" prop="end_time" />
			<el-table-column label="方案编号" prop="case_no" />
			<el-table-column label="延时" prop="delay" />
			<el-table-column fixed="right" label="操作" width="120px">
				<template #default="scope">
					<ul>
						<li @click="editTaskFun(scope)">编辑</li>
						<li @click="delTaskFun(scope.row.id)">删除</li>
					</ul>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="prev, pager, next" :page-size="pageSize" :total="total" hide-on-single-page />

		<!--读取任务-->
		<el-dialog v-model="readTaskAlert" :close-on-click-modal="false" class="readTaskAlert" title="读取任务" width="80%">
			<div>
				<el-table :data="readData" border style="width: 100%" table-layout="auto" v-loading="readLoading">
					<el-table-column label="上报时间" min-width="1%" prop="apply_time">
					</el-table-column>
					<el-table-column label="上报内容" min-width="3%" prop="apply">
					</el-table-column>
					<el-table-column align="center" fixed="right" label="操作" min-width="1%" width="100px">
						<template #default="scope">
							<ul>
								<li class="editClass" @click="analysisClick(scope.row.command_log_id)">解析</li>
							</ul>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button class="confrim" type="primary" @click="readTaskAlert=false">确认</el-button>
			</div>
		</el-dialog>
		<!--解析弹窗-->
		<el-dialog v-model="analysisAlert" :close-on-click-modal="false" class="analysisAlert" title="任务解析" width="80%">
			<div>
				<!--任务解析-->
				<el-table :data="analysisList" border style="width: 100%" table-layout="auto" v-loading="analysisDataLoading">
					<el-table-column label="任务id" prop="task_id" width="130">
					</el-table-column>
					<el-table-column label="任务编号" prop="case_no" width="130">
					</el-table-column>
					<el-table-column label="执行间隔" prop="step" width="130">
					</el-table-column>
					<el-table-column label="状态" prop="state" width="130">
					</el-table-column>
					<el-table-column label="任务类型" prop="case_type" width="130">
					</el-table-column>
					<el-table-column label="开始时间" prop="start_time" width="130">
					</el-table-column>
					<el-table-column label="结束时间" prop="end_time" width="130">
					</el-table-column>
					<el-table-column label="延时" prop="delay" width="130">
					</el-table-column>
					<el-table-column label="任务运行时段" prop="time_type" width="130">
					</el-table-column>
					<el-table-column label="开始脚本ID" prop="pre_id" width="130">
					</el-table-column>
					<el-table-column label="完成脚本ID" prop="suf_id" width="130">
					</el-table-column>
					<el-table-column label="起始小时" prop="time_end_h" width="130">
					</el-table-column>
					<el-table-column label="起始分钟" prop="time_start_m" width="130">
					</el-table-column>
					<el-table-column label="结束小时" prop="time_end_h" width="130">
					</el-table-column>
					<el-table-column label="结束分钟" prop="time_end_m" width="130">
					</el-table-column>
					<el-table-column label="执行优先等级" prop="priority" width="130">
					</el-table-column>
				</el-table>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button class="confrim" type="primary" @click="analysisAlert=false">确认</el-button>
			</div>
		</el-dialog>
		<!--组织任务弹窗-->
		<el-dialog v-model="organizationTaskAlert" :close-on-click-modal="false" class="organizationTaskAlert" title="组织任务" width="80%">
			<div style="display: flex;">
				<div style="width:47%">
					<el-form :model="organizationTask">
						<el-form-item label="执行间隔" label-width="30%" prop="step">
							<div style="display: inline-block;width:49%;">
								<el-input v-model="organizationTask.step" autocomplete="off" placeholder="时间" type="text"></el-input>
							</div>
							<div style="display: inline-block;width:49%;">
								<el-select v-model="organizationTask.step_type" placeholder="单位" style="width:100%">
									<el-option v-for="item in step_typeList" :key="item.id" :label="item.name" :value="item.id">
									</el-option>
								</el-select>
							</div>
						</el-form-item>
						<el-form-item label="任务ID" label-width="30%" prop="task_id">
							<el-input v-model="organizationTask.task_id" autocomplete="off" placeholder="单行输入" type="text"></el-input>
						</el-form-item>
						<el-form-item label="方案编号" label-width="30%" prop="plan_id">
							<el-select v-model="organizationTask.plan_id" placeholder="请选择" style="width:100%">
								<el-option v-for="item in palnData" :key="item.id" :label="item.case_no" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="采集方案" label-width="30%" prop="case_type">
							<el-select v-model="organizationTask.case_type" placeholder="请选择" style="width:100%">
								<el-option v-for="item in case_typeList" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="开始时间" label-width="30%" prop="start_time">
							<el-date-picker v-model="organizationTask.start_time" placeholder="yyyy-MM-dd hh:mm:ss" style="width:100%" suffix-icon="close" type="date" value-format="YYYY-MM-DD hh:mm:ss">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="结束时间" label-width="30%" prop="end_time">
							<el-date-picker v-model="organizationTask.end_time" placeholder="yyyy-MM-dd hh:mm:ss" style="width:100%" suffix-icon="close" type="date" value-format="YYYY-MM-DD hh:mm:ss">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="延时" label-width="30%" prop="delay">
							<div style="display: inline-block;width:49%;">
								<el-input v-model="organizationTask.delay" autocomplete="off" placeholder="时间" type="text"></el-input>
							</div>
							<div style="display: inline-block;width:49%;">
								<el-select v-model="organizationTask.delay_type" placeholder="单位" style="width:100%">
									<el-option v-for="item in step_typeList" :key="item.id" :label="item.name" :value="item.id">
									</el-option>
								</el-select>
							</div>
						</el-form-item>
						<el-form-item label="任务运行时间段" label-width="30%"></el-form-item>
						<el-form-item label="类型" label-width="30%" prop="time_type">
							<el-select v-model="organizationTask.time_type" placeholder="请选择" style="width:100%">
								<el-option v-for="item in time_typeList" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-form>
				</div>
				<div style="width:47%">
					<el-form :model="organizationTask">
						<el-form-item label="执行优先等级" label-width="30%" prop="priority">
							<el-input v-model="organizationTask.priority" autocomplete="off" placeholder="单行输入" type="text"></el-input>
						</el-form-item>
						<el-form-item label="状态" label-width="30%" prop="state">
							<el-radio v-model="organizationTask.state" label="正常">正常（1）</el-radio>
							<el-radio v-model="organizationTask.state" label="停用">停用（2）</el-radio>
						</el-form-item>
						<el-form-item label="任务开始前脚本id" label-width="30%" prop="pre_id">
							<el-input v-model="organizationTask.pre_id" autocomplete="off" placeholder="单行输入" type="text"></el-input>
						</el-form-item>
						<el-form-item label="任务开始后脚本id" label-width="30%" prop="suf_id">
							<el-input v-model="organizationTask.suf_id" autocomplete="off" placeholder="单行输入" type="text"></el-input>
						</el-form-item>
						<el-form-item label="" label-width="30%">

						</el-form-item>
						<el-form-item label="" label-width="30%">

						</el-form-item>
						<el-form-item label="" label-width="30%">

						</el-form-item>
						<el-form-item label="" label-width="30%">

						</el-form-item>
						<el-form-item label="时段" label-width="30%">

						</el-form-item>
						<el-form-item label="起始小时" label-width="30%" prop="time_start_h">
							<el-input v-model="organizationTask.time_start_h" autocomplete="off" placeholder="单行输入" type="text"></el-input>
						</el-form-item>
						<el-form-item label="起始分钟" label-width="30%" prop="time_start_m">
							<el-input v-model="organizationTask.time_start_m" autocomplete="off" placeholder="单行输入" type="text"></el-input>
						</el-form-item>
						<el-form-item label="结束小时" label-width="30%" prop="time_end_h">
							<el-input v-model="organizationTask.time_end_h" autocomplete="off" placeholder="单行输入" type="text"></el-input>
						</el-form-item>
						<el-form-item label="结束分钟" label-width="30%" prop="time_end_m">
							<el-input v-model="organizationTask.time_end_m" autocomplete="off" placeholder="单行输入" type="text"></el-input>
						</el-form-item>
					</el-form>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button class="confrim" type="primary" @click="taskSave()">建立任务</el-button>
				<el-button class="cancle" @click="organizationTaskAlert=false">取消</el-button>
			</div>
		</el-dialog>
		<div v-if="progressShow" class="progressPop">
			<Progress></Progress>
		</div>
	</div>
</template>

<script setup>
	//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
	//例如:import 《组件名称》 from '《组件路径》';
	import { onMounted, reactive, ref, watch } from "vue"
	import { ElLoading, ElMessageBox, ElMessage } from "element-plus";
	import { Loading } from '@element-plus/icons-vue'
	import { getTotalList, getList, saveList, addList, cleanList, readList, applyList, getPlanList } from '../../../api/modules/gw_task.js'
	import userObj from '../../../assets/js/cookie'
	import moment from 'moment'
	import Progress from '../../../components/progress.vue'

	// 定义传值类型
	const props = defineProps({
		value: String //这里就接收到父组件传递的value
	})

	// 分页 **********************************************************
	// 当前页数
	let pageIndex = ref(1)
	// 每页条数
	let pageSize = ref(20)
	//每页多少
	const handleSizeChange = function(val) {
		pageSize.value = val;
		getListFun();
	}
	//更改页数
	const handleCurrentChange = function(val) {
		pageIndex.value = val;
		getListFun();
	}
	// 获取总条数
	const total = ref(0)
	const getTotalListFun = function() {
		getTotalList({
			gateway_id: props.value[0].id
		}).then(res => {
			//console.log(res);
			total.value = res.data
		}).catch(err => {
			console.log(err);
		})
	}
	// 获取列表
	let taskLoading = ref(false)
	let taskData = ref([])
	const getListFun = function() {
		taskLoading.value = true
		getList({
			pageSize: pageSize.value,
			pageIndex: pageIndex.value,
			gateway_id: props.value[0].id
		}).then(res => {
			//console.log(res);
			taskLoading.value = false
			if(res.data.length > 0) {
				taskData.value = res.data
				getTotalListFun()
			} else {
				taskData.value = []
			}
		}).catch(err => {
			console.log(err);
		})
	}
	//全部方案列表
	let palnData = ref([])
	const getPlanListFun = function() {
		getPlanList({
			pageSize: -1,
			pageIndex: 1,
			gateway_id: props.value[0].id
		}).then(res => {
			if(res.data.length > 0) {
				palnData.value = res.data
			} else {
				palnData.value = []
			}
		}).catch(err => {

		})
	}

	// 读取任务***********************************************************
	// 读取任务弹窗----------------------------------------------
	let readTaskAlert = ref(false)
	let readLoading = ref(false)
	// 读取任务列表
	// 点击读取任务
	const readTaskFun = function() {
		analysisDataLoading.value = true
		analysisList.value = []
		readData.value = []
		//openFullScreen()
		progressShow.value = true
		readTaskAlert.value = true
		readList({
			gateway_id: props.value[0].id,
			b_manager_id: userObj().b_manager_id
		}).then(res => {
			//console.log(res);
			//loadingVal.value.close()
			progressShow.value = false
			readLoading.value = false
			analysisDataLoading.value = false
			if(res.data.res == 1) {
				if(res.data.dataRes.length > 0) {
					analysisList.value = res.data.dataRes
				} else {
					analysisList.value = []
				}
				getReadList(res.data.id);
				ElMessage({
					type: 'success',
					message: '读取任务成功!'
				});
			} else {
				ElMessage({
					type: 'error',
					message: res.data.msg + '!'
				});
			}
		}).catch(err => {
			console.log(err);
		})
	}

	let readData = ref([])
	const getReadList = function(id) {
		applyList({
			pageSize: -1,
			pageIndex: 1,
			command_log_id: id
		}).then(res => {
			//console.log(res);
			readLoading.value = false
			if(res.data.length > 0) {
				readData.value = res.data
				readData.value.forEach(item => {
					item.apply_time = moment(item.apply_time).format('YYYY-MM-DD HH:mm:ss')
				})
			} else {
				readData.value = []
			}
		}).catch(err => {
			console.log(err);
		})
	}
	/*加载loading*/
	const loadingVal = ref()
	const progressShow = ref(false)
	const openFullScreen = () => {
		loadingVal.value = ElLoading.service({
			lock: true,
			text: '',
			background: 'rgba(0, 0, 0, 0.7)',
		})
	}

	// 解析弹窗----------------------------------------------
	let analysisAlert = ref(false)
	// 任务解析列表
	let analysisDataLoading = ref(false)
	let analysisList = ref([])
	// 点击查看解析
	const analysisClick = function() {
		analysisAlert.value = true
	}

	// 清空任务***********************************************************
	const emptyTaskFun = function() {
		ElMessageBox.confirm("确认清空此网关的任务吗？", '清空任务', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'success',
		}).then(() => {
			//openFullScreen()
			progressShow.value = true
			cleanList({
				gateway_id: props.value[0].id,
				b_manager_id: userObj().b_manager_id
			}).then(res => {
				//console.log(res);
				//loadingVal.value.close()
				progressShow.value = false
				if(res.data.res == 1) {
					ElMessage({
						message: '任务清空成功！',
						type: 'success',
					})
				} else {
					ElMessage({
						type: 'error',
						message: res.data.msg + '!'
					});
				}
			}).catch(err => {
				console.log(err);
			})
		}).catch(() => {
			ElMessage({
				type: 'info',
				message: '已取消清空任务!'
			});
		});
	}

	// 下发任务***********************************************************
	const issueTaskFun = function() {
		ElMessageBox.confirm("确认下发此网关的任务吗？", '下发任务', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'success'
		}).then(() => {
			//openFullScreen()
			progressShow.value = true
			addList({
				gateway_id: props.value[0].id,
				b_manager_id: userObj().b_manager_id
			}).then(res => {
				//console.log(res);
				//loadingVal.value.close()
				progressShow.value = false
				if(res.data.res == 1) {
					ElMessage({
						message: '任务下发成功！',
						type: 'success',
					})
				} else {
					ElMessage({
						type: 'error',
						message: res.data.msg + '!'
					});
				}
			}).catch(err => {
				console.log(err);
			})
		}).catch(() => {
			ElMessage({
				type: 'info',
				message: '已取消下装任务!'
			});
		});
	}

	// 组织任务***********************************************************
	// 组织任务弹窗
	let organizationTaskAlert = ref(false)
	// 时间选择列表
	let step_typeList = ref([{
			name: '秒（0）',
			id: 0
		},
		{
			name: '分（1）',
			id: 1
		},
		{
			name: '时（2）',
			id: 2
		},
		{
			name: '日（3）',
			id: 3
		},
		{
			name: '月（4）',
			id: 4
		},
		{
			name: '年（5）',
			id: 5
		},
	])
	// 采集方案列表
	let case_typeList = ref([{
			name: '普通采集方案（1）',
			id: 1
		},
		{
			name: '事件采集方案（2）',
			id: 2
		},
		{
			name: '透明方案（3）',
			id: 3
		},
		{
			name: '上报方案（4）',
			id: 4
		},
		{
			name: '脚本方案（5）',
			id: 5
		},
	])
	// 任务运行时间段
	let time_typeList = ref([{
		name: '前闭后开（0）',
		id: 0,
	}, {
		name: '前开后闭（1）',
		id: 1,
	}, {
		name: '前闭后闭（2）',
		id: 2,
	}, {
		name: '前开后开（3）',
		id: 3,
	}])
	// 组织任务表单
	let organizationTask = ref({
		step: 30,
		step_type: 1,
		task_id: 1,
		plan_id: '',
		case_type: 1,
		start_time: '2001-01-01 12:00:00',
		end_time: '2099-01-01 12:00:00',
		delay: 0,
		delay_type: 0,
		time_type: 0,
		priority: 0,
		state: '正常',
		pre_id: 0,
		suf_id: 0,
		time_start_h: 0,
		time_start_m: 0,
		time_end_h: 0,
		time_end_m: 0,
	})
	// 点击组织任务
	const organizationTaskFun = function() {
		organizationTask.value = {
			step: 30,
			step_type: 1,
			task_id: 1,
			plan_id: '',
			case_type: 1,
			start_time: '2001-01-01 12:00:00',
			end_time: '2099-01-01 12:00:00',
			delay: 0,
			delay_type: 0,
			time_type: 0,
			priority: 0,
			state: '正常',
			pre_id: 0,
			suf_id: 0,
			time_start_h: 0,
			time_start_m: 0,
			time_end_h: 0,
			time_end_m: 0,
		}
		edit_Id.value = ''
		organizationTaskAlert.value = true
	}
	// 点击编辑组织任务
	let edit_Id = ref()
	const editTaskFun = function(scope) {
		edit_Id.value = scope.row.id
		organizationTaskAlert.value = true
		organizationTask.value.plan_id = scope.row.plan_id
		organizationTask.value.task_id = scope.row.task_id
		organizationTask.value.step_type = scope.row.step_type
		organizationTask.value.step = scope.row.step
		organizationTask.value.delay = scope.row.delay
		organizationTask.value.start_time = scope.row.start_time
		organizationTask.value.end_time = scope.row.end_time
		organizationTask.value.delay_type = scope.row.delay_type
		organizationTask.value.delay = scope.row.delay
		if(scope.row.state == 1) {
			organizationTask.value.state = '正常'
		} else if(scope.row.state == 2) {
			organizationTask.value.state = '停用'
		}
		organizationTask.value.pre_id = scope.row.pre_id
		organizationTask.value.suf_id = scope.row.suf_id
		organizationTask.value.time_type = scope.row.time_type
		organizationTask.value.time_start_h = scope.row.time_start_h
		organizationTask.value.time_start_h = scope.row.time_start_h
		organizationTask.value.time_end_h = scope.row.time_end_h
		organizationTask.value.time_end_m = scope.row.time_end_m
	}
	// 点击建立任务
	const taskSave = function() {
		var state;
		if(organizationTask.value.state == '停用') {
			state = 2
		} else if(organizationTask.value.state == '正常') {
			state = 1
		}
		if(edit_Id.value) { //编辑
			var params = {
				id: edit_Id.value,
				plan_id: organizationTask.value.plan_id, //所属方案
				task_id: organizationTask.value.task_id, //任务ID
				step_type: organizationTask.value.step_type, //时间间隔单位
				step: organizationTask.value.step, //时间间隔数值
				case_type: organizationTask.value.case_type, //方案类型
				start_time: organizationTask.value.start_time, //开始时间
				end_time: organizationTask.value.end_time, //结束时间
				delay_type: Number(organizationTask.value.delay_type), //延时间隔单位
				delay: organizationTask.value.delay, //延时间隔数值
				priority: organizationTask.value.priority, //执行优先级
				state: state, //任务状态
				pre_id: organizationTask.value.pre_id, //任务开始前脚本ID
				suf_id: organizationTask.value.suf_id, //任务完成后脚本ID
				time_type: organizationTask.value.time_type, //任务运行时段类型
				time_start_h: organizationTask.value.time_start_h, //起始小时
				time_start_m: organizationTask.value.time_start_m, //起始分钟
				time_end_h: organizationTask.value.time_end_h, //结束小时
				time_end_m: organizationTask.value.time_end_m, //结束分钟
			}
		} else { //添加
			var params = {
				b_manager_id: userObj().b_manager_id,
				gateway_id: props.value[0].id,
				plan_id: organizationTask.value.plan_id, //所属方案
				task_id: organizationTask.value.task_id, //任务ID
				step_type: organizationTask.value.step_type, //时间间隔单位
				step: organizationTask.value.step, //时间间隔数值
				case_type: organizationTask.value.case_type, //方案类型
				start_time: organizationTask.value.start_time, //开始时间
				end_time: organizationTask.value.end_time, //结束时间
				delay_type: Number(organizationTask.value.delay_type), //延时间隔单位
				delay: organizationTask.value.delay, //延时间隔数值
				priority: organizationTask.value.priority, //执行优先级
				state: state, //任务状态
				pre_id: organizationTask.value.pre_id, //任务开始前脚本ID
				suf_id: organizationTask.value.suf_id, //任务完成后脚本ID
				time_type: organizationTask.value.time_type, //任务运行时段类型
				time_start_h: organizationTask.value.time_start_h, //起始小时
				time_start_m: organizationTask.value.time_start_m, //起始分钟
				time_end_h: organizationTask.value.time_end_h, //结束小时
				time_end_m: organizationTask.value.time_end_m, //结束分钟
				isset: 0,
				create_time: Date.now(),
				status: 1,
				version: 1
			}
		}
		//console.log(params)
		if(organizationTask.value.plan_id) {
			saveList(params).then(res => {
				//console.log(res);
				getListFun()
				organizationTaskAlert.value = false
			}).catch(err => {
				console.log(err);
			})
		} else {
			ElMessage({
				message: '请输入必填项！',
				type: 'info',
			})
		}
	}

	const delTaskFun = function(id) {
		var params = {
			id: id,
			status: 0
		}
		ElMessageBox.confirm("确认删除该任务吗？", '删除任务', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning',
		}).then(() => {
			saveList(
				params
			).then(res => {
				//console.log(res);
				if(res.data.id) {
					ElMessage({
						message: '删除成功',
						type: 'success',
					})
					getListFun()
				}
			}).catch(err => {
				console.log(err);
			})
		}).catch(() => {
			ElMessage({
				message: '已取消删除',
				type: 'info',
			})
		});
	}
	watch(() => props.value, (newValue, oldValue) => {
		getListFun()
		getPlanListFun()
	}, {
		immediate: true
	})
</script>

<style lang="less">
	.task {
		position: relative;
		//显示弹窗头部
		.el-dialog__header {
			display: block !important;
			span {
				font-size: 14px;
			}
		}
		//按钮列表
		.taskBth {
			display: flex;
			align-items: center;
			height: 40px;
			position: absolute;
			right: 10px;
			top: -45px;
			.el-button {
				height: 30px;
				background-color: #2477F2;
				color: #eeeeee;
				padding: 5px;
			}
		}
		//表格
		.el-table {
			padding: 10px;
			.el-scrollbar__view,
			.el-table__body {
				width: 100% !important;
			}
		}
		.analysisAlert {
			.el-table {
				padding: 10px;
				.el-scrollbar__view,
				.el-table__body {
					width: max-content !important;
				}
			}
		}
		//组织任务,读取任务,解析任务
		.organizationTaskAlert,
		.readTaskAlert,
		.analysisAlert {
			.el-table__body-wrapper {
				tbody {
					.el-table__row {
						height: 40px !important;
						.el-table__cell {
							height: 40px !important;
							padding: 0;
						}
					}
				}
				ul {
					display: flex;
					justify-content: space-around;
					padding: 0;
					margin: 0;
					li {
						color: #2477F2;
						cursor: pointer;
					}
				}
			}
			.el-table__cell {
				padding: 12px 0;
			}
			.el-button {
				background-color: #2477F2;
				color: #eeeeee;
				width: 80px;
				height: 30px;
				margin: 30px 50% 20px;
				transform: translateX(-40px);
			}
		}
		.organizationTaskAlert {
			.dialog-footer {
				display: flex;
				justify-content: center;
				.el-button {
					margin: 20px;
					transform: translateX(0);
				}
			}
		}
		.progressPop {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.7);
			z-index: 9999;
		}
	}
</style>