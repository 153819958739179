<template>
	<div class="scheme">
		<div class="schemeBth">
			<el-button @click="readSchemeFun">读取方案</el-button>
			<el-button @click="emptySchemeFun()">清空方案</el-button>
			<el-button @click="issueSchemeFun()">下发方案</el-button>
			<el-button @click="organizationScheme">组织方案</el-button>
		</div>
		<el-table v-loading="schemeLoading" :data="schemeData" border style="width: 100%" table-layout="auto">
			<el-table-column label="方案编号" min-width="1%" prop="">
				<template #default="scope">{{ scope.row.case_no }}</template>
			</el-table-column>
			<el-table-column label="方案类型" min-width="1%" prop="case_type">
				<template #default="scope">{{ scope.row.case_typeVal}}</template>
			</el-table-column>
			<el-table-column label="采集类型" min-width="1%" prop="collect_type">
				<template #default="scope">{{ scope.row.collect_typeVal }}</template>
			</el-table-column>
			<el-table-column label="用户类型" min-width="2%" prop="collect_type">
				<template #default="scope">{{ scope.row.ms_user }}</template>
			</el-table-column>
			<el-table-column label="数据项" min-width="3%" prop="planList">
				<template #default="scope">
					<div v-for='(item,i) in scope.row.planList' style="display: inline-block;">
						<span>{{ item.plan_code }}</span>
						<span v-if="i!=scope.row.planList.length-1">、</span>
					</div>
				</template>
			</el-table-column>
			<el-table-column label="操作" min-width="1%" prop="">
				<template #default="scope">
					<ul>
						<li class="editClass" @click.prevent="editSchemeFun(scope)">编辑</li>
						<li class="editClass" @click.prevent="delSchemeFun(scope)">删除</li>
					</ul>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination :page-size="pageSize" :total="total" background hide-on-single-page layout="prev, pager, next" @size-change="handleSizeChange" @current-change="handleCurrentChange" />

		<!--读取方案弹窗-->
		<el-dialog v-model="readSchemeAlert" :close-on-click-modal="false" class="readSchemeAlert" title="读取方案" width="80%">
			<div>
				<el-table v-loading='readLoading' :data="readData" border style="width: 100%" table-layout="auto">
					<el-table-column label="上报时间" min-width="1%" prop="apply_time">
					</el-table-column>
					<el-table-column label="上报内容" min-width="3%" prop="apply">
					</el-table-column>
					<el-table-column align="center" fixed="right" label="操作" min-width="1%" width="100px">
						<template #default="scope">
							<ul>
								<li class="editClass" @click="analysisClick(scope.row.command_log_id)">解析</li>
							</ul>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button class="confrim" type="primary" @click="readSchemeAlert=false">确认</el-button>
			</div>
		</el-dialog>
		<!--解析弹窗-->
		<el-dialog v-model="analysisAlert" :close-on-click-modal="false" class="analysisAlert" title="方案解析" width="80%">
			<div>
				<!--方案解析-->
				<el-table v-loading="analysisLoading" :data="analysisList" border style="width: 100%" table-layout="auto">
					<el-table-column label="采集方案" min-width="1%" prop="case_type">
					</el-table-column>
					<el-table-column label="方案编号" min-width="1%" prop="case_no">
					</el-table-column>
					<el-table-column label="存储深度" min-width="1%" prop="store_depth">
					</el-table-column>
					<el-table-column label="采集方式" min-width="1%" prop="collect_type">
					</el-table-column>
					<el-table-column label="功能码" min-width="1%" prop="collect_content">
					</el-table-column>
					<el-table-column label="表计集合" min-width="1%" prop="ms">
					</el-table-column>
					<el-table-column label="表计内容" min-width="1%" prop="ms_user">
					</el-table-column>
					<el-table-column label="储存时标选择" min-width="1%" prop="time_marker">
					</el-table-column>
					<el-table-column label="记录选择" min-width="1%" prop="columns">
					</el-table-column>
				</el-table>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button class="confrim" type="primary" @click="analysisAlert=false">确认</el-button>
			</div>
		</el-dialog>
		<!--组织方案弹窗-->
		<el-dialog v-model="organizationSchemeAlert" :close-on-click-modal="false" class="organizationSchemeAlert" title="组织方案" width="80%">
			<!--表单-->
			<el-form v-model="organizationSchemeForm" :inline="true">
				<el-form-item label="采集方案" label-width="40%" prop="case_type">
					<el-select v-model="organizationSchemeForm.case_type" placeholder="请选择" style="width:100%">
						<el-option v-for="item in case_typeList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="方案编号" label-width="40%">
					<el-input v-model="organizationSchemeForm.case_no" autocomplete="off" placeholder="单行输入" type="number"></el-input>
				</el-form-item>
				<el-form-item label="存储深度" label-width="40%">
					<el-input v-model="organizationSchemeForm.store_depth" autocomplete="off" placeholder="单行输入" type="number"></el-input>
				</el-form-item>
				<el-form-item label="采集方式" label-width="40%">
					<el-select v-model="organizationSchemeForm.collect_type" placeholder="请选择" style="width:100%">
						<el-option v-for="item in collect_typeList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if='organizationSchemeForm.collect_type==1||organizationSchemeForm.collect_type==5' label="功能码" label-width="40%">
					<el-input v-model="organizationSchemeForm.collect_content" autocomplete="off" placeholder="单行输入" type="text"></el-input>
				</el-form-item>
				<el-form-item label="ms数据类型分类" label-width="40%">
					<el-select v-model="organizationSchemeForm.ms" placeholder="请选择" style="width:100%">
						<el-option v-for="item in msList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<div v-for="(item,index) in userList" v-if='organizationSchemeForm.ms==2' class="el-form-item" style="display: inline-block;width:30%">
					<el-form-item label="一组用户类型对应值" label-width="50%" style="width: 100%">
						<el-input v-model="item.oneVal" autocomplete="off" placeholder="单行输入" style="width: calc(100% - 20px);" type="number"></el-input>
						<el-button v-if="index==0" :icon="Plus" circle type="info" @click="addClick" />
						<el-button v-if="index!=0" :icon="Minus" circle type="info" @click="minusClick(index)" />
					</el-form-item>
				</div>
				<el-form-item label="储存时标选择" label-width="40%">
					<el-select v-model="organizationSchemeForm.time_marker" placeholder="请选择" style="width:100%">
						<el-option v-for="item in time_markerList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="监测设备类型" label-width="40%">
					<el-select v-model="model_id" placeholder="请选择" style="width:100%" @change="modelPlanFun" clearable>
						<el-option v-for="item in modelList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>

			</el-form>
			<!--表格-->
			<el-table ref="multipleTableRef" v-loading="organizationSchemeLoading" :data="organizationSchemeData" border style="width: 100%;margin-top: 5px" table-layout="auto" @selection-change="handleSelectionChange">
				<el-table-column label="选取" style="height:20px" type="selection" width="60" />
				<el-table-column label="方案名称" prop="name" />
				<el-table-column label="数据标识" prop="code" />
				<el-table-column label="包含数据项" prop="total" />
				<el-table-column label="协议类型" prop="type" />
			</el-table>
			<el-button @click="organizationSchemeFun">确定</el-button>
		</el-dialog>
		<div v-if="progressShow" class="progressPop">
			<Progress></Progress>
		</div>
	</div>
</template>

<script setup>
	//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
	//例如:import 《组件名称》 from '《组件路径》';
	import { nextTick, onMounted, reactive, ref, watch } from "vue"
	import axios from "axios";
	import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
	import { Plus, Minus } from '@element-plus/icons-vue'
	import {
		getTotalList,
		getList,
		saveList,
		addList,
		cleanList,
		readList,
		applyList,
		getModelList,
		getModelPlanList,
		savePlanList,
		getPlanList,
		getGatewayPlanList,
		getListModelPlan,
		getListModel
	} from '../../../api/modules/gw_plan.js'
	import userObj from '../../../assets/js/cookie'
	import moment from 'moment'
	import Progress from '../../../components/progress.vue'
	import qs from 'qs';

	// 定义传值类型
	const props = defineProps({
		value: String //这里就接收到父组件传递的value
	})

	// 分页 **********************************************************
	// 当前页数
	let pageIndex = ref(1)
	// 每页条数
	let pageSize = ref(20)
	//每页多少
	const handleSizeChange = function(val) {
		pageSize.value = val;
		getListFun();
	}
	//更改页数
	const handleCurrentChange = function(val) {
		pageIndex.value = val;
		getListFun();
	}
	// 获取总条数
	const total = ref(0)
	const getTotalListFun = function() {
		getTotalList({
			gateway_id: props.value[0].id
		}).then(res => {
			//console.log(res);
			total.value = res.data
		}).catch(err => {
			console.log(err);
		})
	}
	// 获取列表
	let schemeLoading = ref(false)
	let schemeData = ref([])
	const getListFun = function() {
		schemeLoading.value = true
		getList({
			pageSize: pageSize.value,
			pageIndex: pageIndex.value,
			gateway_id: props.value[0].id
		}).then(res => {
			//console.log(res);
			schemeLoading.value = false
			if(res.data.length > 0) {
				schemeData.value = res.data
				schemeData.value.forEach(item => {
					case_typeList.value.forEach(items => {
						if(item.case_type == items.id) {
							item.case_typeVal = items.name
						}
					})
					collect_typeList.value.forEach(items => {
						if(item.collect_type == items.id) {
							item.collect_typeVal = items.name
						}
					})
				})
				getTotalListFun()
			} else {
				schemeData.value = []
			}
		}).catch(err => {
			console.log(err);
		})
	}

	// 读取方案***********************************************************
	// 读取方案弹窗----------------------------------------------
	let readSchemeAlert = ref(false)
	let readLoading = ref(false)
	// 读取方案列表
	// 点击读取方案
	const readSchemeFun = function() {
		analysisLoading.value = true
		analysisList.value = []
		readData.value = []
		//openFullScreen()
		progressShow.value = true
		readSchemeAlert.value = true
		readList({
			gateway_id: props.value[0].id,
			b_manager_id: userObj().b_manager_id
		}).then(res => {
			//console.log(res);
			//loadingVal.value.close()
			progressShow.value = false
			readLoading.value = false
			analysisLoading.value = false
			if(res.data.res == 1) {
				if(res.data.dataRes.length > 0) {
					analysisList.value = res.data.dataRes
				} else {
					analysisList.value = []
				}
				getReadList(res.data.id);
				ElMessage({
					type: 'success',
					message: '读取方案成功!'
				});
			} else {
				ElMessage({
					type: 'error',
					message: res.data.msg + '!'
				});
			}
		}).catch(err => {
			console.log(err);
		})
	}
	let readData = ref([])
	const getReadList = function(id) {
		applyList({
			pageSize: -1,
			pageIndex: 1,
			command_log_id: id
		}).then(res => {
			//console.log(res);
			readLoading.value = false
			if(res.data.length > 0) {
				readData.value = res.data
				readData.value.forEach(item => {
					item.apply_time = moment(item.apply_time).format('YYYY-MM-DD HH:mm:ss')
				})
			} else {
				readData.value = []
			}
		}).catch(err => {
			console.log(err);
		})
	}
	/*加载loading*/
	const loadingVal = ref()
	const progressShow = ref(false)
	const openFullScreen = () => {
		loadingVal.value = ElLoading.service({
			lock: true,
			text: '',
			background: 'rgba(0, 0, 0, 0.7)',
		})
		//		setTimeout(() => {
		//			loadingVal.close()
		//		}, 2000)
	}
	// 解析弹窗----------------------------------------------
	let analysisAlert = ref(false)
	// 方案解析列表
	let analysisList = ref([])
	let analysisLoading = ref(false)
	// 点击查看解析
	const analysisClick = function() {
		analysisAlert.value = true
	}

	// 清空方案***********************************************************
	const emptySchemeFun = function() {
		ElMessageBox.confirm("确认清空此网关的方案吗？", '清空方案', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'success',
		}).then(() => {
			//openFullScreen()
			progressShow.value = true
			cleanList({
				gateway_id: props.value[0].id,
				b_manager_id: userObj().b_manager_id
			}).then(res => {
				//console.log(res);
				//loadingVal.value.close()
				progressShow.value = false
				if(res.data.res == 1) {
					ElMessage({
						message: '方案清空成功！',
						type: 'success',
					})
				} else {
					ElMessage({
						type: 'error',
						message: res.data.msg + '!'
					});
				}
			}).catch(err => {
				console.log(err);
			})
		}).catch(() => {
			ElMessage({
				type: 'info',
				message: '已取消清空方案!'
			});
		});
	}

	// 下发方案***********************************************************
	const issueSchemeFun = function() {
		ElMessageBox.confirm("确认下发此网关的方案吗？", '下发方案', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'success'
		}).then(() => {
			//openFullScreen()
			progressShow.value = true
			addList({
				gateway_id: props.value[0].id,
				b_manager_id: userObj().b_manager_id
			}).then(res => {
				//console.log(res);
				//loadingVal.value.close()
				progressShow.value = false
				if(res.data.res == 1) {
					ElMessage({
						message: '方案下发成功！',
						type: 'success',
					})
				} else {
					ElMessage({
						type: 'error',
						message: res.data.msg + '!'
					});
				}
			}).catch(err => {
				console.log(err);
			})
		}).catch(() => {
			ElMessage({
				type: 'info',
				message: '已取消下装方案!'
			});
		});
	}

	// 组织方案相关事件及数据***********************************************************
	let oneVal1 = ref()

	function Sell(oneVal) {
		oneVal1.value = oneVal;
	}

	Sell.prototype.setVar1 = function(value) {
		oneVal1.value = value;
	};
	let userList = ref([])
	userList.value.push(new Sell());

	// 采集方案列表
	let case_typeList = ref([{
			name: '普通采集方案（1）',
			id: 1
		},
		{
			name: '事件采集方案（2）',
			id: 2
		},
		{
			name: '透明方案（3）',
			id: 3
		},
		{
			name: '上报方案（4）',
			id: 4
		},
		{
			name: '脚本方案（5）',
			id: 5
		},
	])
	// 组织方案ms数据类型分类
	let msList = ref([{
			id: 1,
			name: '全部用户地址（1）'
		},
		{
			id: 2,
			name: ' 一组用户类型（2）'
		}
	])
	// 组织方案采集方式
	let collect_typeList = ref([{
			name: '当前数据（0）',
			id: 0
		},
		{
			name: '冻结数据（1）',
			id: 1
		},
		{
			name: 'modbus功能码（5）',
			id: 5
		},
	])
	// 组织方案存储时标选择
	let time_markerList = ref([{
			id: 0,
			name: '未定义（0）'
		}, {
			id: 1,
			name: '任务开始时间（1）'
		},
		{
			id: 2,
			name: '相对当日0点0分（2）'
		},
		{
			id: 3,
			name: '相对上日23点59分（3）'
		},
		{
			id: 4,
			name: '相对上日0点0分（4）'
		},
		{
			id: 5,
			name: '相对当月1日0点0分（5）'
		},
		{
			id: 6,
			name: '数据冻结时标（6）'
		},
		{
			id: 7,
			name: '相对上月月末23点59分（7）'
		},
	])

	const addClick = function() {
		userList.value.push(new Sell());
	}
	//删除
	const minusClick = function(index) {
		userList.value.splice(index, 1);
	}

	// 组织方案弹窗
	let organizationSchemeAlert = ref(false)
	// 组织方案表单数据
	let organizationSchemeForm = ref({
		case_type: case_typeList.value[0].id,
		case_no: '',
		store_depth: '1',
		collect_type: '',
		collect_content: '',
		ms: '',
		time_marker: time_markerList.value[0].id,
		b_manager_id: userObj().b_manager_id,
		gateway_id: props.value[0].id,
		isset: 0,
		create_time: Date.now(),
		status: 1,
		version: 1,
		set_time: 0
	})
	// 组织方案表格数据
	let organizationSchemeLoading = ref(false)
	let organizationSchemeData = ref([])
	let model_id = ref() //监测设备类型
	let modelList = ref([]) //监测设备类型列表
	const getModelFun = function() {
		getListModel({
			gatewayId: props.value[0].id,
			bId: userObj().b_id
		}).then(res => {
			modelList.value = res.data.result
		}).catch(err => {
			console.log(err);
		})
	}
	// 点击查看组织方案弹窗
	const organizationScheme = async function() {
		organizationSchemeForm.value = {
			case_type: case_typeList.value[0].id,
			case_no: '',
			store_depth: '1',
			collect_type: '',
			collect_content: '',
			ms: '',
			time_marker: time_markerList.value[0].id,
			b_manager_id: userObj().b_manager_id,
			gateway_id: props.value[0].id,
			isset: 0,
			create_time: Date.now(),
			status: 1,
			version: 1,
			set_time: 0
		}
		model_id.value = ''
		organizationSchemeAlert.value = true
		stateFlag.value = true
		organizationSchemeLoading.value = true
		/*await getGatewayPlanList({
			gateway_id: props.value[0].id
		}).then(res => {
			organizationSchemeLoading.value = false
			organizationSchemeData.value = res.data
		}).catch(err => {
			console.log(err);
		})*/
		modelPlanFun()
	}
	const modelPlanFun = async function() {
		await getListModelPlan({
			gatewayId: props.value[0].id,
			modelId: model_id.value
		}).then(res => {
			organizationSchemeLoading.value = false
			organizationSchemeData.value = res.data.result
			console.log(organizationSchemeData.value)
		}).catch(err => {
			console.log(err);
		})
	}
	// 选中的表格列表
	let multipleSelection = ref([])
	const handleSelectionChange = function(val) {
		console.log(val)
		multipleSelection.value = val
	}

	// 判断是新增还是编辑
	let stateFlag = ref()
	// 点击编辑方案
	let editId = ref()
	let multipleTableRef = ref()
	// 点击编辑
	const editSchemeFun = async function(scope) {
		editId.value = scope.row.id
		model_id.value = ''
		organizationSchemeForm.value = scope.row
		var arr = []
		var arr2 = []
		if(scope.row.ms_user) {
			arr = scope.row.ms_user.split(',')
			arr.forEach(item => {
				arr2.push({
					'oneVal': item
				})
			})
			userList.value = arr2
		} else {
			userList.value = []
			userList.value.push(new Sell());
		}
		stateFlag.value = false
		organizationSchemeAlert.value = true
		organizationSchemeLoading.value = true
		/*await getGatewayPlanList({
			gateway_id: props.value[0].id
		}).then(res => {
			organizationSchemeLoading.value = false
			organizationSchemeData.value = res.data
		}).catch(err => {
			console.log(err);
		})*/
		await modelPlanFun()
		await nextTick(() => {
			let rows = []
			getPlanList({
				plan_id: scope.row.id,
				pageSize: -1,
				pageIndex: 1
			}).then(res => {
				let arr = res.data
				arr.map(row => {
					multipleTableRef.value.toggleRowSelection(organizationSchemeData.value.find(item => row.model_plan_id == item.id), true); // 注意这里寻找的字段要唯一
				});
			}).catch(err => {
				console.log(err);
			})
		})
	}

	// 提交组织方案弹窗
	const organizationSchemeFun = function() {
		var userList1 = '';
		userList.value.forEach((item, index) => {
			if(item.oneVal) {
				if(index == userList.value.length - 1) {
					userList1 += item.oneVal
				} else {
					userList1 += item.oneVal + ','
				}
			}
		})
		if(organizationSchemeForm.value.ms == 1) {
			userList1 = ''
		}
		if(organizationSchemeForm.value.ms == 2) {
			organizationSchemeForm.value.ms_user = userList1
		}
		if(!stateFlag.value) {
			organizationSchemeForm.value.id = editId.value
		}
		if(organizationSchemeForm.value.case_type && organizationSchemeForm.value.case_no && organizationSchemeForm.value.store_depth && organizationSchemeForm.value.collect_type != undefined && organizationSchemeForm.value.ms && organizationSchemeForm.value.time_marker != undefined) {
			saveList(organizationSchemeForm.value).then(async res => {
				organizationSchemeAlert.value = false
				if(res.data.id) {
					getListFun()
					if(!stateFlag.value) {
						await getPlanList({
							plan_id: res.data.id,
							pageSize: -1,
							pageIndex: 1
						}).then(async res => {
							for(let i = 0; i < res.data.length; i++) {
								await savePlanList({
									id: res.data[i].id,
									status: 0,
								}).then(res => {
									getListFun()
									//									if(i === res.data.length - 1) {
									//										getListFun()
									//									}
								}).catch(err => {
									console.log(err);
								})
							}
						}).catch(err => {
							console.log(err);
						})
					}
					for(let i = 0; i < multipleSelection.value.length; i++) {
						savePlanList({
							b_manager_id: userObj().b_manager_id,
							plan_id: res.data.id,
							model_plan_id: multipleSelection.value[i].id,
							create_time: Date.now(),
							status: 1,
							version: 1
						}).then(res => {
							console.log(res);
							if(i === multipleSelection.value.length - 1) {
								getListFun()
							}
						}).catch(err => {
							console.log(err);
						})
					}
				}
			}).catch(err => {
				console.log(err);
			})
		} else {
			ElMessage({
				message: '请输入必填项！',
				type: 'info',
			})
		}
	}

	// 删除方案列表
	const delSchemeFun = function(scope) {
		ElMessageBox.confirm("确认删除该方案吗？", '删除方案', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning',
		}).then(() => {
			saveList({
				id: scope.row.id,
				case_no: scope.row.case_no + '_' + scope.row.id,
				status: 0,
			}).then(res => {
				if(res.data.id) {
					getListFun()
					ElMessage({
						message: '删除成功',
						type: 'success'
					})
				}
			}).catch(err => {
				console.log(err);
			})
		}).catch(() => {
			ElMessage({
				message: '已取消删除',
				type: 'info',
			})
		});
	}

	watch(() => props.value, (newValue, oldValue) => {
		getListFun()
		getModelFun()
	}, {
		immediate: true
	})
</script>

<style lang="less">
	.scheme {
		position: relative;
		//显示弹窗头部
		.el-dialog__header {
			display: block !important;
			span {
				font-size: 14px;
			}
		}
		//按钮列表
		.schemeBth {
			display: flex;
			align-items: center;
			height: 40px;
			position: absolute;
			right: 10px;
			top: -45px;
			.el-button {
				height: 30px;
				background-color: #2477F2;
				color: #eeeeee;
				padding: 5px;
			}
		}
		//表格
		.el-table {
			padding: 10px;
			.el-scrollbar__view,
			.el-table__body {
				width: 100% !important;
			}
			.editClass {}
		}
		//组织方案,读取方案,解析方案
		.organizationSchemeAlert,
		.readSchemeAlert,
		.analysisAlert {
			.el-table__body-wrapper {
				tbody {
					.el-table__row {
						height: 40px !important;
						.el-table__cell {
							height: 40px !important;
							padding: 0;
						}
					}
				}
				ul {
					display: flex;
					justify-content: space-around;
					padding: 0;
					margin: 0;
					li {
						color: #2477F2;
						cursor: pointer;
					}
				}
			}
			.el-table__cell {
				padding: 12px 0;
			}
			.el-button {
				background-color: #2477F2;
				color: #eeeeee;
				width: 80px;
				height: 30px;
				margin: 30px 50% 20px;
				transform: translateX(-40px);
			}
			.el-button--info {
				background-color: #919399 !important;
				width: 20px !important;
				height: 20px !important;
				margin: 0 !important;
				transform: translateX(0) !important;
			}
		}
		//表单
		.el-form {
			.el-form-item {
				width: 30%;
			}
		}
		.progressPop {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.7);
			z-index: 9999;
		}
	}
</style>