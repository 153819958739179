<template>
	<div class="admin">
		<div class="common-layout">
			<el-container>
				<!--头部-->
				<el-header>
					<div class="title">网关管理平台B端</div>
					<div class="right">
						<div class="user">
							<div class="circle">
								<img alt="" src="../../assets/images/user.svg">
							</div>
							<p @click="loginOut" style="cursor: pointer;">{{ name }}</p>
							<el-icon :size='16'>
								<component :is="ArrowDownBold"></component>
							</el-icon>
						</div>
					</div>
				</el-header>
				<el-container>
					<!--侧边-->
					<el-aside :width="isCollapse?'64px':'200px'">
						<p class="packUp" @click="isShowChange">
							<img v-if="!isCollapse" alt="" src="../../assets/images/packUp.svg" style="cursor: pointer;">
							<img v-if="isCollapse" alt="" src="../../assets/images/open.svg" style="cursor: pointer;">
						</p>
						<el-menu :collapse="isCollapse" :default-active="openMenu" class="el-menu-vertical-demo">
							<router-link to="/admin/gateway">
								<el-menu-item index="1">
									<div class="circle"></div>
									<template #title>网关管理</template>
								</el-menu-item>
							</router-link>
							<router-link to="/admin/integratedEquipment">
								<el-menu-item index="2">
									<div class="circle"></div>
									<template #title>一体设备管理</template>
								</el-menu-item>
							</router-link>
							<router-link to="/admin/monitoring">
								<el-menu-item index="3">
									<div class="circle"></div>
									<template #title>监测设备管理</template>
								</el-menu-item>
							</router-link>
							<router-link to="/admin/project">
								<el-menu-item index="4">
									<div class="circle"></div>
									<template #title>项目管理</template>
								</el-menu-item>
							</router-link>
							<router-link to="/admin/administrator">
								<el-menu-item index="5">
									<div class="circle"></div>
									<template #title>管理员管理</template>
								</el-menu-item>
							</router-link>
						</el-menu>
					</el-aside>
					<!--内容-->
					<el-main>
						<keep-alive>
							<router-view></router-view>
						</keep-alive>
					</el-main>
				</el-container>
			</el-container>
		</div>
	</div>
</template>

<script setup>
	//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
	//例如:import 《组件名称》 from '《组件路径》';
	import { reactive, ref, onMounted, watch } from "vue"
	import { ArrowDownBold, Fold } from '@element-plus/icons-vue'
	import router from "@/router";
	import { useRouter, onBeforeRouteUpdate } from 'vue-router';

	// 用户名称获取
	let name = ref('czz')
	onMounted(() => {
		let cookieArr = document.cookie.split(';')
		for(let i = 0; i < cookieArr.length; i++) {
			if(cookieArr[i].split('=')[0].includes('userName')) {
				name.value = cookieArr[i].split('=')[1]
			}
		}
	})

	// 退出登陆
	const loginOut = function() {
		router.push('/login')

		function setCookie(cname, cvalue, exdays) {
			var d = new Date();
			d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
			var expires = "expires=" + d.toUTCString();
			document.cookie = cname + "=" + cvalue + "; " + expires;
		}
		setCookie('userName', "", -1);
		setCookie('id', "", -1);
		setCookie('b_id', "", -1);
	}

	// 左边侧边栏收起展开
	let isCollapse = ref(false)
	const isShowChange = function() {
		isCollapse.value = !isCollapse.value
	}

	// 侧边栏点击选中
	let openMenu = ref('1')
	let path = router.currentRoute.value.path;
	if(path === '/admin/gateway') {
		openMenu.value = '1'
	} else if(path === '/admin/integratedEquipment') {
		openMenu.value = '2'
	} else if(path === '/admin/monitoring') {
		openMenu.value = '3'
	} else if(path === '/admin/project') {
		openMenu.value = '4'
	} else if(path === '/admin/administrator') {
		openMenu.value = '5'
	}

	// 监测路由变化
	watch(() => router.currentRoute.value, (newValue) => {
		let routerPath = newValue.path
		if(routerPath === '/admin/gateway' || routerPath === '/admin') {
			openMenu.value = '1'
		} else if(routerPath === '/admin/integratedEquipment') {
			openMenu.value = '2'
		} else if(routerPath === '/admin/monitoring') {
			openMenu.value = '3'
		} else if(routerPath === '/admin/project') {
			openMenu.value = '4'
		} else if(path === '/admin/administrator') {
			openMenu.value = '5'
		}
	}, {
		immediate: true
	})
</script>

<style lang="less" scoped>
	.admin {
		width: 100vw;
		height: 100vh;
		.common-layout {
			height: 100%;
		}
		//头部
		header {
			width: 100%;
			height: 50px;
			display: flex;
			justify-content: space-between;
			color: #666666;
			.title {
				line-height: 50px;
			}
			.right {
				display: flex;
				justify-content: space-between;
				.cont {
					span {
						margin-right: 10px;
						line-height: 50px;
					}
				}
				.user {
					width: 110px;
					height: 50px;
					display: flex;
					justify-content: space-between;
					align-content: center;
					.circle {
						width: 30px;
						height: 30px;
						border-radius: 50%;
						//background-color: #1890FF;
						margin-top: 10px;
						img {
							width: 100%;
						}
					}
					p {
						line-height: 50px;
					}
					i {
						margin-top: 17px;
					}
				}
			}
		}
		//侧边
		.el-aside {
			position: relative;
			overflow: visible;
			color: #262626;
			.packUp {
				position: fixed;
				left: 200px;
				top: 11px;
				z-index: 99;
			}
			ul {
				border: 0;
				li {
					height: 40px;
					.circle {
						width: 8px;
						height: 8px;
						background-color: #5C5C66;
						border-radius: 50%;
						margin-right: 10px;
					}
				}
				.is-active {
					background-color: #E3EBF6;
					.circle {
						background-color: #409eff;
					}
				}
			}
		}
		//内容
		.el-main {
			background-color: #EAEAEA;
			padding: 30px 80px;
			div {
				min-width: 700px;
			}
		}
		.el-container {
			height: 100%;
			.el-container {
				height: calc(100% - 50px);
			}
		}
	}
</style>