<template>
	<div class="administrator">
		<!--标题-->
		<div class="title">
			<p>管理员管理</p>
			<button @click="createAdministrator">新建管理员</button>
		</div>
		<!--管理员表格-->
		<el-table :data="administratorData" border style="width: 100%;margin-top: 10px" table-layout="auto" v-loading="loading">
			<el-table-column label="序号" prop="idx" width="100px">
				<template #default="scope">
					{{(pageIndex-1)*pageSize+scope.$index+1}}
				</template>
			</el-table-column>
			<el-table-column label="管理员用户名" prop="username" />
			<el-table-column fixed="right" label="操作" width="150px">
				<template #default="scope">
					<ul>
						<li @click="editAdministrator(scope)">编辑</li>
						<li @click="delAdministrator(scope)" v-if="scope.row.lev==2">删除</li>
					</ul>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="prev, pager, next" :page-size="pageSize" :total="total" hide-on-single-page />

		<!--新增编辑管理员弹窗-->
		<el-dialog v-model="administratorAlert" :close-on-click-modal="false" :title="stateFlag?'新增管理员':'编辑管理员'" class="administratorAlert" width="40%">
			<el-form ref="administratorFormRef" :model="administratorForm" :rules="administratorRules" :validate="validate">
				<el-form-item label="管理员用户名" prop="username">
					<el-input v-model="administratorForm.username" placeholder="单行输入" />
				</el-form-item>
				<el-form-item label="密码" prop="password">
					<el-input type="password" v-model="administratorForm.password" placeholder="单行输入" show-password />
				</el-form-item>
				<el-form-item type="password" label="确认密码" prop="againPassword">
					<el-input v-model="administratorForm.againPassword" placeholder="单行输入" show-password />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="administratorFormSubmit">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<!--删除管理员弹窗-->
		<el-dialog v-model="delAdministratorAlert" :close-on-click-modal="false" class="delGatewayTypeAlert" width="40%">
			<h3>您确定要删除该管理员账号吗？</h3>
			<template #footer>
				<span class="dialog-footer">
        <el-button @click="delAdministratorAlert = false">取消</el-button>
        <el-button type="primary" @click="delAdministratorFun">确定</el-button>
      </span>
			</template>
		</el-dialog>
	</div>
</template>

<script setup>
	import { onMounted, reactive, ref } from "vue"
	import './administrator.less'
	import { ElMessage } from "element-plus";

	import { getTotalList, getList, saveList, userQuery } from '../../api/modules/administrator'
	import userObj from '../../assets/js/cookie'
	import qs from "qs";
	import md5 from '../../assets/js/md5'

	onMounted(() => {
		getListFun()
		//console.log(atob('MTIzNDU=')) //base4解码
		//console.log(btoa('12345')) //base4加密
	})

	// 分页 **********************************************************
	// 当前页数
	let pageIndex = ref(1)
	// 每页条数
	let pageSize = ref(20)
	//每页多少
	const handleSizeChange = function(val) {
		pageSize.value = val;
		getListFun();
	}
	//更改页数
	const handleCurrentChange = function(val) {
		pageIndex.value = val;
		getListFun();
	}
	// 获取总条数
	const total = ref(0)
	const getTotalListFun = function() {
		getTotalList({
			b_id: userObj().b_id
		}).then(res => {
			//console.log(res);
			total.value = res.data
		}).catch(err => {
			console.log(err);
		})
	}
	// 获取列表
	let loading = ref(false)
	let administratorData = ref([])
	const getListFun = function() {
		loading.value = true
		getList({
			pageSize: pageSize.value,
			pageIndex: pageIndex.value,
			b_id: userObj().b_id
		}).then(res => {
			//console.log(res);
			loading.value = false
			if(res.data.length > 0) {
				administratorData.value = res.data
				getTotalListFun()
			} else {
				administratorData.value = []
			}
		}).catch(err => {
			console.log(err);
		})
	}
	// 新建编辑的相关事件与数据******************************************************
	// 新建或编辑状态
	let stateFlag = ref(true)
	// 新建或编辑弹窗
	let administratorAlert = ref(false)
	// 新建或编辑的弹窗表单
	let administratorForm = ref({
		username: '',
		password: '',
		againPassword: '',
		lev: ''
	})
	// 新建点击
	const createAdministrator = function() {
		edit_Id.value = ''
		administratorForm.value = {}
		stateFlag.value = true
		administratorAlert.value = true
	}
	let edit_Id = ref()
	let listRow = ref({})
	// 编辑点击
	const editAdministrator = function(scope) {
		administratorForm.value = {}
		edit_Id.value = scope.row.id
		listRow.value = scope.row
		administratorForm.value.username = scope.row.username
		stateFlag.value = false
		administratorAlert.value = true
	}

	// 编辑新建的表单规则
	let againPass = (rule, value, callback) => {
		if(!value ) {
			callback(new Error('请再次输入密码'))
		} else if(value !== administratorForm.value.password) {
			callback(new Error('两次输入密码不一致!'))
		} else {
			callback()
		}

	}
	let username = (rule, value, callback) => {
		let notDo = false
		if(value) {
			userQuery({
				username: value
			}).then(res => {
				if(res.data.id) {
					if(edit_Id && administratorForm.value.username == listRow.value.username) {
						notDo = false
						callback()
						return
					}
					callback(new Error('管理员用户名重复!'))
					notDo = true
					administratorForm.value.username = ''
				} else {
					notDo = false
					//				administratorForm.value.password = ''
					//				administratorForm.value.againPassword = ''
					callback()
				}
			}).catch(err => {
				console.log(err);
			})
		}else{
			callback(new Error('请输入管理员用户名!'))
		}
	}
	const administratorRules = reactive({
		username: [{
			required: true,
			trigger: "blur",
			validator: username
		}],
		password: [{
			required: true,
			message: "请输入管理员密码",
			trigger: "blur"
		}],
		againPassword: [{
			required: true,
			trigger: "blur",
			validator: againPass
		}]
	})

	// 表单元素
	let administratorFormRef = ref()
	// 新建或编辑提交
	const administratorFormSubmit = function() {
		if(administratorFormRef) {
			administratorFormRef.value.validate((valid) => {
				//提交成功
				if(valid) {
					let params = {}
					if(edit_Id.value) {
						params = {
							id: edit_Id.value,
							lev: administratorForm.value.lev,
							src: 2,
							b_id: userObj().b_id,
							b_manager_id: userObj().b_manager_id,
							username: administratorForm.value.username,
							password: administratorForm.value.password,
							status: 1,
							create_time: Date.now(),
							version: 1
						}
					} else {
						params = {
							lev: 2,
							src: 2,
							b_id: userObj().b_id,
							b_manager_id: userObj().b_manager_id,
							username: administratorForm.value.username,
							password: administratorForm.value.password,
							status: 1,
							create_time: Date.now(),
							version: 1
						}
					}

					params.password = md5(params.password + params.username + 'gw')
					saveList(
						params
					).then(res => {
						//console.log(res);
						getListFun()
						administratorAlert.value = false
					}).catch(err => {
						console.log(err);
					})
				}
			})
		}
	}

	// 删除的相关事件与数据******************************************************
	let delAdministratorAlert = ref(false)
	// 删除
	const delAdministrator = function(scope) {
		edit_Id.value = scope.row.id
		listRow.value = scope.row
		delAdministratorAlert.value = true
	}
	// 确定删除
	const delAdministratorFun = function() {
		let params = {
			id: edit_Id.value,
			username: listRow.value.username + '-' + edit_Id.value,
			status: 0
		}
		saveList(
			params
		).then(res => {
			//console.log(res);
			if(res.data.id) {
				delAdministratorAlert.value = false
				ElMessage({
					message: '删除成功',
					type: 'success',
				})
				getListFun()
			}
		}).catch(err => {
			console.log(err);
		})
	}
</script>