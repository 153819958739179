<template>
	<div class="equipment">
		<div class="equipmentBth">
			<div style="display: none;">
				<el-upload class="upload-demo" action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" v-model:file-list="fileList" :data="personData" :before-upload="beforeUpload" :show-file-list="false" accept=".xls,.xlsx">
					<button class="selectFile" slot="trigger" size="small" type="primary" id="fileName">选择文件</button>
				</el-upload>
			</div>
			<el-button @click="batchDeleteFun">批量删除</el-button>
			<el-button @click="registerFun">拓扑管理</el-button>
			<el-button @click="readFun3(1)">读取档案</el-button>
			<el-button @click="kongFileClick">清空档案</el-button>
			<el-button @click="sendFileClick">下发档案</el-button>
		</div>
		<el-table :data="equipmentData" border style="width: 100%" table-layout="auto" @selection-change="handleSelectionChange" v-loading="loading">
			<el-table-column label="选取" type="selection" width="55" style="height:20px">
			</el-table-column>
			<el-table-column label="序号" prop="idx" width="100px">
				<template #default="scope">
					{{(pageIndex-1)*pageSize+scope.$index+1}}
				</template>
			</el-table-column>
			<el-table-column label="PID+别名" prop="">
				<template #default="scope">
					{{scope.row.model_code}}+{{scope.row.model_name}}
				</template>
			</el-table-column>
			<el-table-column label="通讯地址" prop="addr" />
			<el-table-column label="端口" prop="portVal" />
			<el-table-column label="波特率" prop="rate_typeVal" />
			<el-table-column label="规约类型" prop="ruler_typeVal" />
			<el-table-column label="电压变比" prop="rate_v" />
			<el-table-column label="电流变比" prop="rate_a" />
			<el-table-column label="密码" prop="password" />
			<el-table-column fixed="right" label="操作" width="150px">
				<template #default="scope">
					<ul>
						<li @click="extendClick(scope.row)">扩展录入</li>
						<li @click="delEquipmentFun(scope.row.id)">删除</li>
					</ul>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="prev, pager, next" :page-size="pageSize" :total="total" hide-on-single-page />
		<!--删除设备弹窗-->
		<el-dialog v-model="delEquipmentAlert" class="delEquipmentAlert" width="40%" :close-on-click-modal="false">
			<h3>您确定要删除该设备吗？</h3>
			<template #footer>
				<span class="dialog-footer">
        <el-button @click="delEquipmentAlert = false">取消</el-button>
        <el-button type="primary" @click="delEquipmentSubmit">确定</el-button>
      </span>
			</template>
		</el-dialog>

		<!--扩展录入-->
		<el-dialog title="扩展录入" v-model="extendDialog" width="80%" :close-on-click-modal="false" class="extendDialogClass">
			<div class="checkAllBox2" v-if="!large">
				<el-checkbox v-model="checkedAll2" @change="checkedAllChange2()">全选</el-checkbox>
			</div>
			<div style="display: flex;">
				<div style="flex: 4;">
					<el-form>
						<div class="largeCheckBoxClass">
							<el-checkbox v-model="extendEquipmentForm.model_idChecked" @change="checkedRadioChange2()" class='largeCheckClass' v-if="!large"></el-checkbox>
							<el-form-item label="设备型号" label-width="30%">
								<el-select v-model="extendEquipmentForm.model_id" placeholder="请选择" style="width: 100%;">
									<el-option v-for="item in modelData" :key="item.id" :label="item.name" :value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
						<!--<div class="largeCheckBoxClass">
							<el-checkbox v-model="extendEquipmentForm.category_idChecked" @change="checkedRadioChange2()" class='largeCheckClass' v-if="!large"></el-checkbox>
							<el-form-item label="监测设备类型" label-width="30%">
								<el-select v-model="extendEquipmentForm.category_id" placeholder="请选择" style="width:100%" @change="categoryChange()">
									<el-option v-for="item in categoryData" :key="item.id" :label="item.name" :value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
						<div class="largeCheckBoxClass">
							<el-checkbox v-model="extendEquipmentForm.product_idChecked" @change="checkedRadioChange2()" class='largeCheckClass' v-if="!large"></el-checkbox>
							<el-form-item label="监测设备型号" label-width="30%">
								<el-select v-model="extendEquipmentForm.product_id" placeholder="请选择" style="width:100%">
									<el-option v-for="item in projectData" :key="item.id" :label="item.model" :value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
						<div class="largeCheckBoxClass">
							<el-form-item label="设备编号" label-width="30%" v-if="large">
								<span>{{extendEquipmentForm.equipmentCode}}</span>
							</el-form-item>
						</div>-->
						<div class="largeCheckBoxClass">
							<el-checkbox v-model="extendEquipmentForm.rate_vChecked" @change="checkedRadioChange2()" class='largeCheckClass' v-if="!large"></el-checkbox>
							<el-form-item label="电压变比" label-width="30%">
								<el-input type="text" v-model="extendEquipmentForm.rate_v" autocomplete="off" @input="extendEquipmentForm.rate_v=extendEquipmentForm.rate_v.replace(/^(0+)|[^\d]+/g,'')"></el-input>
							</el-form-item>
						</div>
						<div class="largeCheckBoxClass">
							<el-checkbox v-model="extendEquipmentForm.rate_aChecked" @change="checkedRadioChange2()" class='largeCheckClass' v-if="!large"></el-checkbox>
							<el-form-item label="电流变比" label-width="30%">
								<el-input type="text" v-model="extendEquipmentForm.rate_a" autocomplete="off" @input="extendEquipmentForm.rate_a=extendEquipmentForm.rate_a.replace(/^(0+)|[^\d]+/g,'')"></el-input>
							</el-form-item>
						</div>
						<div class="largeCheckBoxClass">
							<el-checkbox v-model="extendEquipmentForm.addrChecked" @change="checkedRadioChange2()" class='largeCheckClass' v-if="!large"></el-checkbox>
							<el-form-item label="通讯地址" label-width="30%">
								<el-input type="text" v-model="extendEquipmentForm.addr" autocomplete="off" @change="addrChange"></el-input>
							</el-form-item>
						</div>
						<div class="largeCheckBoxClass">
							<el-checkbox v-model="extendEquipmentForm.rate_typeChecked" @change="checkedRadioChange2()" class='largeCheckClass' v-if="!large"></el-checkbox>
							<el-form-item label="波特率" label-width="30%">
								<el-select v-model="extendEquipmentForm.rate_type" placeholder="请选择" style="width:100%">
									<el-option v-for="item in rate_typeData" :key="item.id" :label="item.name" :value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
						<div class="largeCheckBoxClass">
							<el-checkbox v-model="extendEquipmentForm.rated_vChecked" @change="checkedRadioChange2()" class='largeCheckClass' v-if="!large"></el-checkbox>
							<el-form-item label="额定电压" label-width="30%">
								<el-input type="text" v-model="extendEquipmentForm.rated_v" autocomplete="off"></el-input>
							</el-form-item>
						</div>
						<div class="largeCheckBoxClass">
							<el-checkbox v-model="extendEquipmentForm.rated_aChecked" @change="checkedRadioChange2()" class='largeCheckClass' v-if="!large"></el-checkbox>
							<el-form-item label="额定电流" label-width="30%">
								<el-input type="text" v-model="extendEquipmentForm.rated_a" autocomplete="off"></el-input>
							</el-form-item>
						</div>
					</el-form>
				</div>
				<div style="flex: 1;"></div>
				<div style="flex: 4;">
					<el-form>
						<div class="largeCheckBoxClass">
							<el-checkbox v-model="extendEquipmentForm.ruler_typeChecked" @change="checkedRadioChange2()" class='largeCheckClass' v-if="!large"></el-checkbox>
							<el-form-item label="规约类型" label-width="30%">
								<el-select v-model="extendEquipmentForm.ruler_type" placeholder="请选择" style="width:100%">
									<el-option v-for="item in ruler_typeData" :key="item.id" :label="item.name" :value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
						<div class="largeCheckBoxClass">
							<el-checkbox v-model="extendEquipmentForm.portChecked" @change="checkedRadioChange2()" class='largeCheckClass' v-if="!large"></el-checkbox>
							<el-form-item label="端口" label-width="30%">
								<el-select v-model="extendEquipmentForm.port" placeholder="请选择" style="width:100%">
									<el-option v-for="item in portData" :key="item.value" :label="item.name" :value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
						<div class="largeCheckBoxClass">
							<el-checkbox v-model="extendEquipmentForm.passwordChecked" @change="checkedRadioChange2()" class='largeCheckClass' v-if="!large"></el-checkbox>
							<el-form-item label="通信密码" label-width="30%">
								<el-input type="text" v-model="extendEquipmentForm.password" autocomplete="off"></el-input>
							</el-form-item>
						</div>
						<div class="largeCheckBoxClass">
							<el-checkbox v-model="extendEquipmentForm.fee_numChecked" @change="checkedRadioChange2()" class='largeCheckClass' v-if="!large"></el-checkbox>
							<el-form-item label="费率个数" label-width="30%">
								<el-input type="text" v-model="extendEquipmentForm.fee_num" autocomplete="off"></el-input>
							</el-form-item>
						</div>
						<div class="largeCheckBoxClass">
							<el-checkbox v-model="extendEquipmentForm.user_typeChecked" @change="checkedRadioChange2()" class='largeCheckClass' v-if="!large"></el-checkbox>
							<el-form-item label="用户类型" label-width="30%">
								<el-input type="text" v-model="extendEquipmentForm.user_type" autocomplete="off"></el-input>
							</el-form-item>
						</div>
						<div class="largeCheckBoxClass">
							<el-checkbox v-model="extendEquipmentForm.join_typeChecked" @change="checkedRadioChange2()" class='largeCheckClass' v-if="!large"></el-checkbox>
							<el-form-item label="接线方式" label-width="30%">
								<el-select v-model="extendEquipmentForm.join_type" placeholder="请选择" style="width:100%">
									<el-option v-for="item in join_typeData" :key="item.id" :label="item.name" :value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
						<div class="largeCheckBoxClass">
							<el-checkbox v-model="extendEquipmentForm.p_codeChecked" @change="checkedRadioChange2()" class='largeCheckClass' v-if="!large"></el-checkbox>
							<el-form-item label="资产号" label-width="30%">
								<el-input type="text" v-model="extendEquipmentForm.p_code" autocomplete="off"></el-input>
							</el-form-item>
						</div>
					</el-form>
				</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
       <el-button @click="extendSave()" class="confrim" type="primary">立即创建</el-button>
				<el-button @click="extendDialog=false" class="cancle">取消</el-button>
      </span>
			</template>
		</el-dialog>
		<!--子设备注册弹窗-->
		<el-dialog v-model="registerAlert" class="registerAlert" title="子设备注册" width="80%" :close-on-click-modal="false">
			<div class="btn">
				<el-button @click="readFun">读取拓扑</el-button>
				<el-button @click="readFun2(2)">读取完整档案</el-button>
				<el-button @click="queryFun">查询执行结果</el-button>
				<el-button @click="unbindFun">子设备解绑</el-button>
				<el-button @click="deleteFun">子设备删除</el-button>
				<el-button @click="sonRegisterFun">子设备注册</el-button>
			</div>
			<el-input v-model="result" readonly style="width: 100%;;height: 200px"></el-input>
		</el-dialog>
		<!--读取拓扑的弹窗-->
		<el-dialog v-model="readAlert" class="readAlert" title="子设备拓扑" width="80%" :close-on-click-modal="false">
			<el-table :data="readTopologyData" border style="width: 100%" table-layout="auto" v-loading="readTopologyLoading">
				<el-table-column label="序号" prop="idx" width="100px">
					<template #default="scope">
						{{scope.$index+1}}
					</template>
				</el-table-column>
				<el-table-column label="PID编号" prop="pid_no" />
				<el-table-column label="PID" prop="pid" />
				<el-table-column label="注册ID" prop="reg_id" />
				<el-table-column label="设备ID" prop="did" />
				<el-table-column label="设备名称" prop="d_name" />
			</el-table>
			<el-button @click="readAlert=false">确定</el-button>
		</el-dialog>
		<!--删除子设备弹窗-->
		<el-dialog v-model="delSonEquipmentAlert" class="delSonEquipmentAlert" title="" width="40%" :close-on-click-modal="false">
			<!--确认发布提示-->
			<div class="confirmBody">
				<img alt="" src="../../../assets/images/jinggao.svg">
				<p>确认删除子设备，删除不可逆！</p>
			</div>
			<!--取消确认-->
			<div class="operation">
				<el-button @click="delSonEquipmentSubmit">确认</el-button>
			</div>
		</el-dialog>
		<!--发送解绑、注册、删除的弹窗-->
		<el-dialog v-model="timeAlert" class="timeAlert" :close-on-click-modal="false">
			<el-icon>
				<Select/>
			</el-icon>
			<p>已发送{{ timeAlertValue }}</p>
		</el-dialog>
		<!--读取弹窗-->
		<el-dialog title="档案读取列表" v-model="readDialog" width="80%" :close-on-click-modal="false">
			<div>
				<el-table :data="readData" border style="width: 100%" table-layout="auto" v-loading="readLoading">
					<el-table-column prop="apply_time" label="上报时间" min-width="1%">
					</el-table-column>
					<el-table-column prop="apply" label="上报内容" min-width="3%">
					</el-table-column>
					<el-table-column prop="" label="操作" min-width="1%">
						<template #default="scope">
							<a class="editClass" @click.prevent="analysisClick(scope.row.command_log_id)">解析</a>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<template #footer>
				<span class="dialog-footer">
        <el-button @click="readDialog=false" class="confrim" style='background-color: #2477F2;color:#fff;'>确认</el-button>
      </span>
			</template>
		</el-dialog>
		<!--解析弹窗-->
		<el-dialog title="档案解析" v-model="analysisDialog" width="80%" :close-on-click-modal="false">
			<!--档案解析-->
			<el-table :data="analysisList" border style="width: 100%" table-layout="auto" v-loading="analysisLoading">
				<el-table-column prop="pid" label="PID" width="130" v-if="readShow==2">
				</el-table-column>
				<el-table-column prop="did" label="设备ID" width="130" v-if="readShow==2">
				</el-table-column>
				<el-table-column prop="code" label="设备状态" width="130" v-if="readShow==2">
				</el-table-column>
				<el-table-column prop="archive_no" label="设备编号" width="130">
				</el-table-column>
				<el-table-column prop="tsa" label="通讯地址" width="130">
				</el-table-column>
				<el-table-column prop="user_type" label="用户类型" width="130">
				</el-table-column>
				<el-table-column prop="port" label="端口" width="130">
				</el-table-column>
				<el-table-column prop="rate_type" label="波特率" width="130">
				</el-table-column>
				<el-table-column prop="ruler_type" label="规约类型" width="130">
				</el-table-column>
				<el-table-column prop="rate_v" label="电压变比" width="130">
				</el-table-column>
				<el-table-column prop="rate_a" label="电流变比" width="130">
				</el-table-column>
				<el-table-column prop="password" label="通讯密码" width="130">
				</el-table-column>
				<el-table-column prop="fee_num" label="费率个数" width="130">
				</el-table-column>
				<el-table-column prop="join_type" label="接线方式" width="130">
				</el-table-column>
				<el-table-column prop="rated_v" label="额定电压" width="130">
				</el-table-column>
				<el-table-column prop="rated_a" label="额定电流" width="130">
				</el-table-column>
				<el-table-column prop="p_code" label="资产号" width="130">
				</el-table-column>
			</el-table>
			<template #footer>
				<span class="dialog-footer">
       <el-button @click="analysisDialog=false" class="confrim" type="primary">确认</el-button>
      </span>
			</template>
		</el-dialog>
		<div v-if="progressShow" class="progressPop">
			<Progress></Progress>
		</div>
	</div>
</template>

<script setup>
	//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
	//例如:import 《组件名称》 from '《组件路径》';
	import { onMounted, reactive, ref, watch } from "vue"
	import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
	import { Select } from '@element-plus/icons-vue'
	import { getTotalList, getList, saveList, getModelList, addList, cleanList, readList, applyList, readSubTopoList, readSubArchiveList, unbandSubFun, delSubFun, registerSubFun, querySubFun, uploadFile, importDevice } from '../../../api/modules/gw_device.js'
	import userObj from '../../../assets/js/cookie'
	import { v1 as uuidv1 } from 'uuid'
	import moment from 'moment'
	import Progress from '../../../components/progress.vue'

	// 定义传值类型
	const props = defineProps({
		value: String //这里就接收到父组件传递的value
	})

	// 分页 **********************************************************
	// 当前页数
	let pageIndex = ref(1)
	// 每页条数
	let pageSize = ref(20)
	//每页多少
	const handleSizeChange = function(val) {
		pageSize.value = val;
		getListFun();
	}
	//更改页数
	const handleCurrentChange = function(val) {
		pageIndex.value = val;
		getListFun();
	}
	// 获取总条数
	const total = ref(0)
	const getTotalListFun = function() {
		getTotalList({
			gateway_id: props.value[0].id
		}).then(res => {
			//console.log(res);
			total.value = res.data
		}).catch(err => {
			console.log(err);
		})
	}
	// 获取列表
	let loading = ref(false)
	let equipmentData = ref([])
	const getListFun = function() {
		loading.value = true
		getList({
			pageSize: pageSize.value,
			pageIndex: pageIndex.value,
			gateway_id: props.value[0].id
		}).then(res => {
			//console.log(res);
			loading.value = false
			if(res.data.length > 0) {
				equipmentData.value = res.data
				equipmentData.value.forEach(item => {
					portData.value.forEach(items => {
						if(item.port == items.value) {
							item.portVal = items.name
						}
					})
					rate_typeData.value.forEach(itemc => {
						if(item.rate_type == itemc.id) {
							item.rate_typeVal = itemc.name
						}
					})
					ruler_typeData.value.forEach(itemz => {
						if(item.ruler_type == itemz.id) {
							item.ruler_typeVal = itemz.name
						}
					})
				})
				getTotalListFun()
			} else {
				equipmentData.value = []
			}
		}).catch(err => {
			console.log(err);
		})
	}
	//获取设备型号列表
	let modelData = ref()
	const getModelListFun = function() {
		getModelList({
			pageSize: -1,
			pageIndex: 1,
			b_id: userObj().b_id
		}).then(res => {
			//console.log(res);
			if(res.data.length > 0) {
				modelData.value = res.data
				modelData.value.forEach(item => {
					item.codeName = item.code + '+' + item.name
				})
			} else {
				modelData.value = []
			}
		}).catch(err => {
			console.log(err);
		})
	}
	//端口列表
	let portData = ref([{
			name: 'F2010201:4851',
			value: 'F2010201'
		},
		{
			name: 'F2010202:4852',
			value: 'F2010202'
		},
		{
			name: 'F20B0201:蓝牙',
			value: 'F20B0201'
		},
		{
			name: 'F2090201:载波/微功率无线接口',
			value: 'F2090201'
		},
	])

	//扩展录入相关事件**************************************************************
	let join_typeData = ref([{
			id: 0,
			name: '未知（0）'
		},
		{
			id: 1,
			name: '单相（1）'
		},
		{
			id: 2,
			name: '三相三线（2）'
		},
		{
			id: 3,
			name: '三相四线（3）'
		},
	]) //接线方式列表
	let rate_typeData = ref([{
			id: 255,
			name: '自适应（偶校验）'
		},
		{
			id: 0,
			name: '300bps（偶校验）'
		},
		{
			id: 1,
			name: '600bps（偶校验）'
		},
		{
			id: 2,
			name: '1200bps（偶校验）'
		},
		{
			id: 3,
			name: '2400bps（偶校验）'
		},
		{
			id: 4,
			name: '4800bps（偶校验）'
		},
		{
			id: 5,
			name: '7200bps（偶校验）'
		},
		{
			id: 6,
			name: '9600bps（偶校验）'
		},
		{
			id: 7,
			name: '19200bps（偶校验）'
		},
		{
			id: 8,
			name: '38400bps（偶校验）'
		},
		{
			id: 9,
			name: '57600bps（偶校验）'
		},
		{
			id: 10,
			name: '115200bps（偶校验）'
		},
		{
			id: 128,
			name: '300bps（无校验）'
		},
		{
			id: 129,
			name: '600bps（无校验）'
		},
		{
			id: 130,
			name: '1200bps（无校验）'
		},
		{
			id: 131,
			name: '2400bps（无校验）'
		},
		{
			id: 132,
			name: '4800bps（无校验）'
		},
		{
			id: 133,
			name: '7200bps（无校验）'
		},
		{
			id: 134,
			name: '9600bps（无校验）'
		},
		{
			id: 135,
			name: '19200bps（无校验）'
		},
		{
			id: 136,
			name: '38400bps（无校验）'
		},
		{
			id: 137,
			name: '57600bps（无校验）'
		},
		{
			id: 138,
			name: '115200bps（无校验）'
		}
	]) //波特率列表
	let ruler_typeData = ref([{
			id: 0,
			name: '未知（0）'
		},
		{
			id: 1,
			name: 'DL/T 645-1997（1）'
		},
		{
			id: 2,
			name: 'DL/T 645—2007（2）'
		},
		{
			id: 3,
			name: 'DL/T 698.45（3）'
		},
		{
			id: 4,
			name: 'CJ/T 188—2004（4）'
		},
		{
			id: 5,
			name: 'ModBus（5）'
		},
		{
			id: 6,
			name: 'ModBus高字节在前CRC（6）'
		}
	]) //规约类型列表
	// 扩展录入******************************************************************
	let large = ref(false)
	let extendDialog = ref(false)
	let extendEquipmentForm = ref({
		model_id: '',
		rate_v: '',
		rate_a: '',
		addr: '',
		fee_num: '',
		user_type: '',
		rated_v: '',
		rated_a: '',
		p_code: '',
		password: '',
		port: '',
		join_type: '',
		rate_type: '',
		ruler_type: '',
		category_id: '',
		product_id: '',
		equipmentCode: '',
		/*左*/
		model_idChecked: false,
		category_idChecked: false,
		product_idChecked: false,
		rate_vChecked: false,
		rate_aChecked: false,
		addrChecked: false,
		rate_typeChecked: false,
		/*右*/
		ruler_typeChecked: false,
		portChecked: false,
		passwordChecked: false,
		fee_numChecked: false,
		user_typeChecked: false,
		join_typeChecked: false,
		rated_vChecked: false,
		rated_aChecked: false,
		p_codeChecked: false,
	})
	//通讯地址改变
	const addrChange = function() {
		extendEquipmentForm.value.addr = extendEquipmentForm.value.addr.padStart(12, '0')
	}

	let edit_Id = ref()
	const extendClick = function(val) {
		edit_Id.value = val.id
		large.value = true
		extendDialog.value = true
		extendEquipmentForm.value.model_id = val.model_id
		extendEquipmentForm.value.password = val.password //通信密码
		extendEquipmentForm.value.ruler_type = val.ruler_type //规约类型
		extendEquipmentForm.value.fee_num = val.fee_num //费率个数
		extendEquipmentForm.value.user_type = val.user_type //用户类型
		extendEquipmentForm.value.rate_type = val.rate_type //波特率
		extendEquipmentForm.value.join_type = val.join_type //接线方式
		extendEquipmentForm.value.rated_v = val.rated_v //额定电压
		extendEquipmentForm.value.rated_a = val.rated_a //额定电流
		extendEquipmentForm.value.p_code = val.p_code //资产号
		extendEquipmentForm.value.port = val.port //端口
		extendEquipmentForm.value.rate_v = val.rate_v //电压变比
		extendEquipmentForm.value.rate_a = val.rate_a //电流变比
		extendEquipmentForm.value.addr = val.addr //通讯地址
	}
	let checkedAll2 = ref(false)
	//批量扩展录入全选
	const checkedAllChange2 = function() {
		if(checkedAll2.value == true) {
			extendEquipmentForm.value.model_idChecked = true
			extendEquipmentForm.value.category_idChecked = true
			extendEquipmentForm.value.product_idChecked = true
			extendEquipmentForm.value.rate_vChecked = true
			extendEquipmentForm.value.rate_aChecked = true
			extendEquipmentForm.value.addrChecked = true
			extendEquipmentForm.value.rate_typeChecked = true
			/*右*/
			extendEquipmentForm.value.ruler_typeChecked = true
			extendEquipmentForm.value.portChecked = true
			extendEquipmentForm.value.passwordChecked = true
			extendEquipmentForm.value.fee_numChecked = true
			extendEquipmentForm.value.user_typeChecked = true
			extendEquipmentForm.value.join_typeChecked = true
			extendEquipmentForm.value.rated_vChecked = true
			extendEquipmentForm.value.rated_aChecked = true
			extendEquipmentForm.value.p_codeChecked = true
		} else {
			extendEquipmentForm.value.model_idChecked = false
			extendEquipmentForm.value.category_idChecked = false
			extendEquipmentForm.value.product_idChecked = false
			extendEquipmentForm.value.rate_vChecked = false
			extendEquipmentForm.value.rate_aChecked = false
			extendEquipmentForm.value.addrChecked = false
			extendEquipmentForm.value.rate_typeChecked = false
			/*右*/
			extendEquipmentForm.value.ruler_typeChecked = false
			extendEquipmentForm.value.portChecked = false
			extendEquipmentForm.value.passwordChecked = false
			extendEquipmentForm.value.fee_numChecked = false
			extendEquipmentForm.value.user_typeChecked = false
			extendEquipmentForm.value.join_typeChecked = false
			extendEquipmentForm.value.rated_vChecked = false
			extendEquipmentForm.value.rated_aChecked = false
			extendEquipmentForm.value.p_codeChecked = false
		}
	}
	const checkedRadioChange2 = function() {
		/*extendEquipmentForm.value.category_idChecked && extendEquipmentForm.value.product_idChecked*/
		if(extendEquipmentForm.value.model_idChecked && extendEquipmentForm.value.rate_vChecked && extendEquipmentForm.value.rate_aChecked && extendEquipmentForm.value.addrChecked && extendEquipmentForm.value.rate_typeChecked && extendEquipmentForm.value.ruler_typeChecked && extendEquipmentForm.value.portChecked && extendEquipmentForm.value.passwordChecked && extendEquipmentForm.value.fee_numChecked && extendEquipmentForm.value.user_typeChecked && extendEquipmentForm.value.join_typeChecked && extendEquipmentForm.value.rated_vChecked && extendEquipmentForm.value.rated_aChecked && extendEquipmentForm.value.p_codeChecked) {
			checkedAll2.value = true
		} else {
			checkedAll2.value = false
		}
	}
	//扩展录入的保存
	const extendSave = function() {
		extendConfirm()
	}
	//扩展录入编辑保存
	const extendConfirm = function() {
		var params = {
			id: edit_Id.value,
			model_id: extendEquipmentForm.value.model_id,
			password: extendEquipmentForm.value.password, //通信密码
			ruler_type: extendEquipmentForm.value.ruler_type, //规约类型
			fee_num: extendEquipmentForm.value.fee_num, //费率个数
			user_type: extendEquipmentForm.value.user_type, //用户类型
			rate_type: extendEquipmentForm.value.rate_type, //波特率
			join_type: extendEquipmentForm.value.join_type, //接线方式
			rated_v: extendEquipmentForm.value.rated_v, //额定电压
			rated_a: extendEquipmentForm.value.rated_a, //额定电流
			p_code: extendEquipmentForm.value.p_code, //资产号
			port: extendEquipmentForm.value.port, //端口
			rate_v: extendEquipmentForm.value.rate_v, //电压变比
			rate_a: extendEquipmentForm.value.rate_a, //电流变比
			addr: extendEquipmentForm.value.addr, //通讯地址
		}
		saveList(params).then(res => {
			//console.log(res);
			getListFun()
			extendDialog.value = false
		}).catch(err => {
			console.log(err);
		})
	}

	// 删除弹窗***********************************************************
	let delEquipmentAlert = ref(false)
	// 删除物模型

	const delEquipmentFun = function(id) {
		delEquipmentAlert.value = true
		edit_Id.value = id
	}
	// 确定删除物模型
	const delEquipmentSubmit = function() {
		let params = {
			id: edit_Id.value,
			status: 0
		}
		saveList(
			params
		).then(res => {
			//console.log(res);
			if(res.data.id) {
				delEquipmentAlert.value = false
				ElMessage({
					message: '删除成功',
					type: 'success',
				})
				getListFun()
			}
		}).catch(err => {
			console.log(err);
		})
	}
	//复选框触发
	let multipleSelection = ref([])
	const handleSelectionChange = function(val) {
		multipleSelection.value = val;
	}
	//批量删除
	const batchDeleteFun = function() {
		if(multipleSelection.value.length > 0) {
			ElMessageBox.confirm("确认要删除吗？", '注意:', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'success',
			}).then(() => {
				for(var i = 0; i < multipleSelection.value.length; i++) {
					saveList({
						id: multipleSelection.value[i].id,
						status: 0
					}).then(res => {
						//console.log(res);
						if(res.data.id) {
							getListFun()
						}
					}).catch(err => {
						console.log(err)
						ElMessage({
							type: 'error',
							message: '删除失败!'
						});
						return
					})
				}
				ElMessage({
					type: 'success',
					message: '删除成功!'
				});
			}).catch(() => {
				ElMessage({
					type: 'info',
					message: '已取消删除!'
				});
			})
		} else {
			ElMessage({
				type: 'info',
				message: '请至少选择一条批量删除的数据!'
			})
		}
	}

	// 子设备注册相关事件及数据***********************************************************
	// 子设备注册弹窗
	let registerAlert = ref(false)
	// 点击显示注册弹窗
	const registerFun = function() {
		registerAlert.value = true
	}

	// 读取拓扑相关事件及数据***********************************************************
	let readTopologyLoading = ref(false)
	// 读取拓扑弹窗
	let readAlert = ref(false)
	// 读取拓扑弹窗数据
	let readTopologyData = ref([])
	// 点击显示读取拓扑弹窗
	const readFun = function() {
		readAlert.value = true
		readTopologyLoading.value = true
		readSubTopoList({
			gateway_id: props.value[0].id,
			b_manager_id: userObj().b_manager_id
		}).then(res => {
			//console.log(res);
			readTopologyLoading.value = false
			if(res.data.res == 1) {
				if(res.data.dataRes && res.data.dataRes.length > 0) {
					readTopologyData.value = res.data.dataRes
				} else {
					readTopologyData.value = []
				}
			} else {
				ElMessage({
					type: 'error',
					message: res.data.msg + '!'
				});
			}
		}).catch(err => {
			console.log(err);
		})
	}
	// 查询注册结果相关事件及数据***********************************************************
	let result = ref()
	const queryFun = function() {
		openFullScreen()
		querySubFun({
			gateway_id: props.value[0].id,
			b_manager_id: userObj().b_manager_id
		}).then(res => {
			//console.log(res);
			loadingVal.value.close()
			if(res.data.res != 0) {
				result.value = res.data.msg + '！'
				ElMessage({
					type: 'success',
					message: '查询结果成功!'
				});
			} else {
				ElMessage({
					type: 'error',
					message: res.data.msg + '!'
				});
			}
		}).catch(err => {
			console.log(err);
		})
	}

	// 子设备解绑相关事件及数据***********************************************************
	// 发送解绑、注册、删除的弹窗
	let timeAlert = ref(false)
	let timeAlertValue = ref('')
	const unbindFun = function() {
		timeAlertValue.value = '解绑'
		openFullScreen()
		unbandSubFun({
			gateway_id: props.value[0].id,
			b_manager_id: userObj().b_manager_id
		}).then(res => {
			//console.log(res);
			loadingVal.value.close()
			if(res.data.id) {
				result.value = res.data.msg + '！'
				timeAlert.value = true
				setTimeout(() => {
					timeAlert.value = false
				}, 1000)
			} else {
				ElMessage({
					type: 'error',
					message: res.data.msg + '!'
				});
			}
		}).catch(err => {
			console.log(err);
		})
	}

	// 子设备注册相关事件及数据***********************************************************
	const sonRegisterFun = function() {
		timeAlertValue.value = '注册'
		openFullScreen()
		registerSubFun({
			gateway_id: props.value[0].id,
			b_manager_id: userObj().b_manager_id
		}).then(res => {
			//console.log(res);
			loadingVal.value.close()
			if(res.data.id) {
				result.value = res.data.msg + '！'
				timeAlert.value = true
				setTimeout(() => {
					timeAlert.value = false
				}, 1000)
			} else {
				ElMessage({
					type: 'error',
					message: res.data.msg + '!'
				});
			}
		}).catch(err => {
			console.log(err);
		})
	}

	// 子设备删除相关事件及数据***********************************************************
	// 子设备删除弹窗
	let delSonEquipmentAlert = ref(false)
	// 点击显示子设备删除弹窗
	const deleteFun = function() {
		delSonEquipmentAlert.value = true
	}
	const delSonEquipmentSubmit = function() {
		openFullScreen()
		delSonEquipmentAlert.value = false
		timeAlertValue.value = '删除'
		delSubFun({
			gateway_id: props.value[0].id,
			b_manager_id: userObj().b_manager_id
		}).then(res => {
			//console.log(res);
			loadingVal.value.close()
			if(res.data.id) {
				result.value = res.data.msg + '！'
				timeAlert.value = true
				setTimeout(() => {
					timeAlert.value = false
				}, 1000)
			} else {
				ElMessage({
					type: 'error',
					message: res.data.msg + '!'
				});
			}
		}).catch(err => {
			console.log(err);
		})
	}
	// 读取档案相关事件及数据***********************************************************
	let readLoading = ref(false)
	let readDialog = ref(false)
	let analysisDialog = ref(false)
	// 读取完整档案相关事件及数据
	const readFun2 = function(val) {
		readShow.value = val
		analysisList.value = []
		readData.value = []
		//openFullScreen()
		progressShow.value = true
		readDialog.value = true
		readSubArchiveList({
			gateway_id: props.value[0].id,
			b_manager_id: userObj().b_manager_id
		}).then(res => {
			//console.log(res);
			//loadingVal.value.close()
			progressShow.value = false
			if(res.data.res == 1) {
				if(res.data.dataRes.length > 0) {
					analysisList.value = res.data.dataRes
				} else {
					analysisList.value = []
				}
				getReadList(res.data.id);
				ElMessage({
					type: 'success',
					message: '读取完整档案成功!'
				});
			} else {
				ElMessage({
					type: 'error',
					message: res.data.msg + '!'
				});
			}
		}).catch(err => {
			console.log(err);
		})
	}
	// 读取完整档案相关事件及数据
	const readShow = ref()
	const readFun3 = function(val) {
		readShow.value = val
		analysisLoading.value = true
		analysisList.value = []
		readData.value = []
		//openFullScreen()
		progressShow.value = true
		readDialog.value = true
		readList({
			gateway_id: props.value[0].id,
			b_manager_id: userObj().b_manager_id
		}).then(res => {
			//console.log(res);
			//loadingVal.value.close()
			progressShow.value = false
			analysisLoading.value = false
			readLoading.value = true
			readLoading.value = false
			if(res.data.res == 1) {
				if(res.data.dataRes.length > 0) {
					analysisList.value = res.data.dataRes
				} else {
					analysisList.value = []
				}
				getReadList(res.data.id);
				ElMessage({
					type: 'success',
					message: '读取档案成功!'
				});
			} else {
				ElMessage({
					type: 'error',
					message: res.data.msg + '!'
				});
			}
		}).catch(err => {
			console.log(err);
		})
	}
	let readData = ref([])
	const getReadList = function(id) {
		applyList({
			pageSize: -1,
			pageIndex: 1,
			command_log_id: id
		}).then(res => {
			//console.log(res);
			if(res.data.length > 0) {
				readData.value = res.data
				readData.value.forEach(item => {
					item.apply_time = moment(item.apply_time).format('YYYY-MM-DD HH:mm:ss')
				})
			} else {
				readData.value = []
			}
		}).catch(err => {
			console.log(err);
		})
	}
	//解析
	const analysisClick = function() {
		analysisDialog.value = true
	}
	let analysisLoading = ref(false)
	let analysisList = ref([])
	// 清空档案*********************************************************************
	const kongFileClick = function() {
		ElMessageBox.confirm("确认清空此网关的档案吗？", '确认要清空档案吗？', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'success',
		}).then(() => {
			//openFullScreen()
			progressShow.value = true
			cleanList({
				gateway_id: props.value[0].id,
				b_manager_id: userObj().b_manager_id
			}).then(res => {
				//console.log(res);
				//loadingVal.value.close()
				progressShow.value = false
				if(res.data.res == 1) {
					ElMessage({
						message: '档案清空成功！',
						type: 'success',
					})
				} else {
					ElMessage({
						type: 'error',
						message: res.data.msg + '!'
					});
				}
			}).catch(err => {
				console.log(err);
			})
		}).catch(() => {
			ElMessage({
				type: 'info',
				message: '已取消清空档案！',
			})
		})
	}
	// 下发档案******************************************************************
	const sendFileClick = function() {
		ElMessageBox.confirm("确认下发此网关的档案吗？", '确认要下发档案吗？', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'success',
		}).then(() => {
			//openFullScreen()
			progressShow.value = true
			addList({
				gateway_id: props.value[0].id,
				b_manager_id: userObj().b_manager_id
			}).then(res => {
				//loadingVal.value.close()
				progressShow.value = false
				//console.log(res);
				if(res.data.res == 1) {
					ElMessage({
						message: '档案下发成功！',
						type: 'success',
					})
				} else {
					ElMessage({
						type: 'error',
						message: res.data.msg + '!'
					});
				}
			}).catch(err => {
				console.log(err);
			})
		}).catch(() => {
			ElMessage({
				type: 'info',
				message: '已取消档案下发！',
			})
		})
	}
	/*加载loading*/
	const loadingVal = ref()
	const progressShow = ref(false)
	const openFullScreen = () => {
		loadingVal.value = ElLoading.service({
			lock: true,
			text: '',
			background: 'rgba(0, 0, 0, 0.7)',
		})
	}

	watch(() => props.value, (newValue, oldValue) => {
		getListFun()
		getModelListFun()
	}, {
		immediate: true
	})
</script>

<style lang="less">
	.equipment {
		position: relative;
		//显示弹窗头部
		.el-dialog__header {
			display: block !important;
			span {
				font-size: 14px;
			}
		}
		//按钮列表
		.equipmentBth {
			display: flex;
			align-items: center;
			height: 40px;
			position: absolute;
			right: 10px;
			top: -45px;
			.el-button {
				height: 30px;
				background-color: #2477F2;
				color: #eeeeee;
				padding: 5px;
			}
		}
		//表格
		.el-table {
			padding: 10px;
			.el-scrollbar__view,
			.el-table__body {
				width: 100% !important;
			}
		}
		//删除设备弹窗
		.delEquipmentAlert {
			padding: 50px 20px 20px;
		}
		//新增设备弹窗
		.createEquipmentAlert {
			.el-form {
				padding: 30px 20px;
				.el-form-item {
					display: block;
				}
				.el-form-item:nth-child(3),
				.el-form-item:nth-child(4) {
					width: 49%;
					display: inline-block;
				}
				.el-form-item:nth-child(4) {
					margin-left: 2%;
				}
				.el-select {
					width: 100%;
				}
			}
			.el-button {
				width: 80px;
				height: 30px;
				background-color: #2477F2;
				color: #eeeeee;
				margin-left: 50%;
				transform: translateX(-40px);
				margin-top: 50px;
				margin-bottom: 20px;
			}
		}
		//批量导入弹窗
		.importEquipmentAlert {
			.close {
				width: 80px;
				height: 30px;
				margin-top: 30px;
				margin-bottom: 20px;
				background-color: #A4ADB3;
				color: #eeeeee;
				margin-left: 50%;
				transform: translateX(-40px);
				border: 0;
			}
		}
		//子设备注册
		.registerAlert {
			.el-dialog__body {
				padding: 0 10px;
				.el-input {
					margin-bottom: 50px;
				}
				.btn {
					display: flex;
					justify-content: right;
					align-items: center;
					height: 40px;
					margin-bottom: 10px;
					.el-button {
						height: 30px;
						background-color: #2477F2;
						color: #eeeeee;
						padding: 5px;
					}
				}
			}
		}
		//读取拓扑弹窗
		.readAlert {
			.el-button {
				margin: 30px 50% 20px;
				transform: translateX(-40px);
				width: 80px;
				background-color: #2477F2;
				color: #eeeeee;
			}
		}
		//发送解绑、注册、删除的弹窗
		.timeAlert {
			width: 120px;
			height: 120px;
			background-color: rgba(0, 0, 0, 0.8);
			margin-top: 10%;
			.el-dialog__header {
				display: none !important;
			}
			.el-dialog__body {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.el-icon {
					color: aliceblue;
					font-size: 40px;
				}
				p {
					color: #eeeeee;
				}
			}
		}
		//删除子设备弹窗
		.delSonEquipmentAlert {
			.el-dialog__header {
				display: none;
			}
			//确认描述
			.confirmBody {
				width: 100%;
				height: 150px;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				align-items: center;
				img {
					width: 50px;
				}
				p {
					font-size: 20px;
					color: #2d2d2d;
				}
			}
			//操作按钮
			.operation {
				display: flex;
				justify-content: center;
				font-size: 14px;
				.el-button {
					margin: 30px auto 20px;
					width: 80px;
					height: 30px;
					background-color: #2477F2;
					color: #fff;
				}
			}
		}
		/*档案读取*/
		.editClass {
			color: #2477F2;
			cursor: pointer;
		}
		.el-dialog__footer {
			position: relative;
			text-align: center;
		}
		.largeCheckBoxClass {
			position: relative;
		}
		.largeCheckClass {
			position: absolute;
			top: 0px;
			left: 3%;
		}
		.extendDialogClass .el-dialog__body {
			padding: 30px!important;
		}
		.progressPop {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.7);
			z-index: 9999;
		}
	}
</style>