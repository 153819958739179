import {api, apiCopy} from "@/api";
import headers from '../../assets/js/headers'

// 获取列表总数
export const getTotalList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimitCount').pass_token,
            "time": headers.headers('getListByLimitCount').timestamp
        },
        url: '/device/getListByLimitCount',
        method: 'GET',
        params: data
    })
}

// 获取某页列表
// export const getList = (data) => {
//     return api({
//         headers: {
//             "pass-token": headers.headers('getListByLimit').pass_token,
//             "time": headers.headers('getListByLimit').timestamp
//         },
//         url: '/device/getListByLimit',
//         method: 'GET',
//         params: data
//     })
// }
export const getList = (data) => {
    return apiCopy({
        headers: {
            "pass-token": headers.headers('getListByLimit').pass_token,
            "time": headers.headers('getListByLimit').timestamp
        },
        url: '/zouk/device/page',
        method: 'GET',
        params: data
    })
}

// 获取设备型号列表
export const getModelList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimit').pass_token,
            "time": headers.headers('getListByLimit').timestamp
        },
        url: '/model/getListByLimit',
        method: 'GET',
        params: data
    })
}

// 新增,编辑，删除 列表
export const saveList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('save').pass_token,
            "time": headers.headers('save').timestamp
        },
        url: '/device/save',
        method: 'POST',
        data: data
    })
}
export const editList = (data) => {
    return apiCopy({
        headers: {
            "pass-token": headers.headers('save').pass_token,
            "time": headers.headers('save').timestamp
        },
        url: '/zouk/device/update',
        method: 'POST',
        data: data
    })
}

//下发档案
export const addList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('addArchives').pass_token,
            "time": headers.headers('addArchives').timestamp
        },
        url: '/gateway/addArchives',
        method: 'GET',
        params: data
    })
}
//清空档案
export const cleanList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('cleanArchives').pass_token,
            "time": headers.headers('cleanArchives').timestamp
        },
        url: '/gateway/cleanArchives',
        method: 'GET',
        params: data
    })
}
//读取档案
export const readList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('readArchives').pass_token,
            "time": headers.headers('readArchives').timestamp
        },
        url: '/gateway/readArchives',
        method: 'GET',
        params: data
    })
}
//解析
export const applyList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimit').pass_token,
            "time": headers.headers('getListByLimit').timestamp
        },
        url: '/command_apply/getListByLimit',
        method: 'GET',
        params: data
    })
}
/*子设备注册******************************************************/
//读取拓扑
export const readSubTopoList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('readSubTopo').pass_token,
            "time": headers.headers('readSubTopo').timestamp
        },
        url: '/gateway/readSubTopo',
        method: 'GET',
        params: data
    })
}
//读取子设备完整档案
export const readSubArchiveList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('readSubArchive').pass_token,
            "time": headers.headers('readSubArchive').timestamp
        },
        url: '/gateway/readSubArchive',
        method: 'GET',
        params: data
    })
}
//查询注册结果todo

//子设备解绑
export const unbandSubFun = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('unbandSub').pass_token,
            "time": headers.headers('unbandSub').timestamp
        },
        url: '/gateway/unbandSub',
        method: 'GET',
        params: data
    })
}
///子设备删除
export const delSubFun = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('delSub').pass_token,
            "time": headers.headers('delSub').timestamp
        },
        url: '/gateway/delSub',
        method: 'GET',
        params: data
    })
}
//子设备注册
export const registerSubFun = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('registerSub').pass_token,
            "time": headers.headers('registerSub').timestamp
        },
        url: '/gateway/registerSub',
        method: 'GET',
        params: data
    })
}
//子设备查询注册结果
export const querySubFun = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('querySub').pass_token,
            "time": headers.headers('querySub').timestamp
        },
        url: '/gateway/querySub',
        method: 'GET',
        params: data
    })
}
//上传
export const uploadFile = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('uploadFile').pass_token,
            "time": headers.headers('uploadFile').timestamp
        },
        url: '/upload/uploadFile',
        method: 'POST',
        data: data
    })
}
//批量导入设备
export const importDevice = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('importDevice').pass_token,
            "time": headers.headers('importDevice').timestamp
        },
        url: '/device/importDevice',
        method: 'POST',
        data: data
    })
}