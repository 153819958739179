import {api} from "@/api";
import headers from '../../assets/js/headers'

// 获取列表总数
export const getTotalList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimitCount').pass_token,
            "time": headers.headers('getListByLimitCount').timestamp
        },
        url: '/command_log/getListByLimitCount',
        method: 'GET',
        params: data
    })
}

// 获取某页列表
export const getList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('getListByLimit').pass_token,
            "time": headers.headers('getListByLimit').timestamp
        },
        url: '/command_log/getListByLimit',
        method: 'GET',
        params: data
    })
}

// 新增,编辑，删除 列表
export const saveList = (data) => {
    return api({
        headers: {
            "pass-token": headers.headers('save').pass_token,
            "time": headers.headers('save').timestamp
        },
        url: '/command_log/save',
        method: 'POST',
        data: data
    })
}