<template>
	<div class="login">
		<div class="title">
			<h1 class="tit1">网关平台</h1>
			<h2 class="tit2">B端</h2>
		</div>
		<el-form ref="formRef" :model="formValue" :rules="rules" :validate="validate">
			<el-form-item prop="username" required="true">
				<el-input v-model="formValue.username" placeholder="请输入账号" @change="usernameBlur()">
					<template #prefix>
						<img alt="" src="../../assets/images/zhanghao.svg">
					</template>
				</el-input>
			</el-form-item>
			<el-form-item prop="password" required="true">
				<el-input v-model="formValue.password" placeholder="请输入密码" type="password">
					<template #prefix>
						<img alt="" src="../../assets/images/suo.svg">
					</template>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-button @click="onSubmit(formRef)">登陆</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script setup>
	//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
	//例如:import 《组件名称》 from '《组件路径》';
	import { reactive, ref } from "vue"
	import { UserFilled, Lock } from '@element-plus/icons-vue'
	import router from "@/router";
	// import {userLogin} from '../../api/modules/user'
	import { ElMessage, ElLoading } from 'element-plus'
	import { login, userQuery } from "@/api/modules/administrator";
	import md5 from "@/assets/js/md5";

	let formValue = ref({
		username: '',
		password: '',
	})

	const rules = reactive({
		username: [{
			required: true,
			message: "请输入账号",
			trigger: "blur"
		}],
		password: [{
			required: true,
			message: "请输入密码",
			trigger: "blur"
		}]
	})

	const formRef = ref(null);
	const onSubmit = () => {
		if(formRef) {
			formRef.value.validate((valid) => {
				//提交成功
				if(valid) {
					const loading = ElLoading.service({
						lock: true,
						text: '',
						background: 'rgba(255, 255, 255, 0.9)'
					})
					let obj = formValue.value
					obj.password = md5(formValue.value.password + formValue.value.username + 'gw')
					login(obj).then(res => {
						if(res.data.id) {
							loading.close()
							router.push({
								path: '/admin'
							})
							// 本地存储
							let Days = 1; // 时间长度
							let exp = new Date();
							exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
							document.cookie = "userName=" + formValue.value.username + ";expires=" + exp.toGMTString();
							document.cookie = "id=" + res.data.id + ";expires=" + exp.toGMTString();
							document.cookie = "b_id=" + res.data.b_id + ";expires=" + exp.toGMTString();
						} else {
							loading.close()
							formValue.value.password = ''
							ElMessage({
								message: '用户名或密码错误！',
								type: 'error',
							})
						}
					}).catch(err => {
						console.log(err);
					})
				}
			})
		}
	}
	const usernameBlur = function() {
		userQuery({
			username: formValue.value.username
		}).then(res => {
			if(!res.data.id) {
				ElMessage({
					message: '该用户名不存在！',
					type: 'info',
				})
				formValue.value.username = ''
			}
		}).catch(err => {
			console.log(err);
		})
	}
	document.onkeydown = function(e) { // 回车提交表单
		// 兼容FF和IE和Opera
		var theEvent = window.event || e;
		var code = theEvent.keyCode || theEvent.which || theEvent.charCode;
		if(code == 13) {
			onSubmit();
		}
	}
</script>

<style lang="less">
	.login {
		width: 26vw;
		height: 60vh;
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		border: 1px solid #eeeeee;
		box-shadow: 0 0 6px #ccc;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		.title {
			color: #3A62D7;
			text-align: center;
			.tit1,
			.tit2 {
				font-weight: 400;
			}
			.tit1 {
				margin-top: 12%;
			}
		}
		.el-form {
			width: 70%;
			margin-left: 15%;
			.el-form-item {
				height: 50px;
				div,
				input {
					height: 50px;
				}
			}
			.el-form-item:first-of-type {
				//margin: 0;
			}
			.el-form-item:last-of-type {
				margin-top: 10%;
				margin-bottom: 30%;
				button {
					width: 100%;
					height: 50px;
					background-color: #3A62D7;
					color: white;
				}
			}
		}
	}
</style>