import {api} from "@/api";
import headers from '../../assets/js/headers'

// 获取列表总数
export const getTotalList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getWholeByLimitCount').pass_token,
			"time": headers.headers('getWholeByLimitCount').timestamp
		},
		url: '/gateway/getWholeByLimitCount',
		method: 'GET',
		params: data
	})
}

// 获取某页列表
export const getList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getWholeByLimit').pass_token,
			"time": headers.headers('getWholeByLimit').timestamp
		},
		url: '/gateway/getWholeByLimit',
		method: 'GET',
		params: data
	})
}
// 获取所有未绑定列表
export const getGatewayList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getListByLimit').pass_token,
			"time": headers.headers('getListByLimit').timestamp
		},
		url: '/gateway/getListByLimit',
		method: 'GET',
		params: data
	})
}
// 获取项目列表
export const getProjectList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getListByLimit').pass_token,
			"time": headers.headers('getListByLimit').timestamp
		},
		url: '/project/getListByLimit',
		method: 'GET',
		params: data
	})
}

// 获取网关产品列表
export const getProductList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getListByLimit').pass_token,
			"time": headers.headers('getListByLimit').timestamp
		},
		url: '/product/getListByLimit',
		method: 'GET',
		params: data
	})
}

// 根据addr查询
export const addrChange = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getByAddr').pass_token,
			"time": headers.headers('getByAddr').timestamp
		},
		url: '/gateway/getByAddr',
		method: 'GET',
		params: data
	})
}
// 查询网关产品
export const getProductById = (url) => {
	return api({
		headers: {
			"pass-token": headers.headers(url).pass_token,
			"time": headers.headers(url).timestamp
		},
		url: '/product/' + url,
		method: 'GET'
	})
}
// 查询lot(通道名称)
export const getChannelById = (url) => {
	return api({
		headers: {
			"pass-token": headers.headers(url).pass_token,
			"time": headers.headers(url).timestamp
		},
		url: '/channel/' + url,
		method: 'GET'
	})
}

// 新增,编辑，删除 列表
export const saveList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('save').pass_token,
			"time": headers.headers('save').timestamp
		},
		url: '/gateway/save',
		method: 'POST',
		data: data
	})
}
// 设备的删除 列表
export const deviceSave = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('save').pass_token,
			"time": headers.headers('save').timestamp
		},
		url: '/device/save',
		method: 'POST',
		data: data
	})
}

//上传
export const uploadFile = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('uploadFile').pass_token,
			"time": headers.headers('uploadFile').timestamp
		},
		url: '/upload/uploadFile',
		method: 'POST',
		data: data
	})
}
//导入网关
export const importWhole = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('importWhole').pass_token,
			"time": headers.headers('importWhole').timestamp
		},
		url: '/gateway/importWhole',
		method: 'POST',
		data: data
	})
}
//校时
export const checkTimeFun = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('checkTime').pass_token,
			"time": headers.headers('checkTime').timestamp
		},
		url: '/gateway/checkTime',
		method: 'GET',
		params: data
	})
}
//透传
export const sendCommandList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('sendCommand').pass_token,
			"time": headers.headers('sendCommand').timestamp
		},
		url: '/gateway/sendCommand',
		method: 'GET',
		params: data
	})
}
//透传读取列表
export const command_applyList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getListByLimit').pass_token,
			"time": headers.headers('getListByLimit').timestamp
		},
		url: '/command_apply/getListByLimit',
		method: 'GET',
		params: data
	})
}
// 获取设备型号列表
export const getModelList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getListByLimit').pass_token,
			"time": headers.headers('getListByLimit').timestamp
		},
		url: '/model/getListByLimit',
		method: 'GET',
		params: data
	})
}
// 获取设备列表
export const getDeviceList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getListByLimit').pass_token,
			"time": headers.headers('getListByLimit').timestamp
		},
		url: '/device/getListByLimit',
		method: 'GET',
		params: data
	})
}
//设备的保存
export const saveDeviceList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('save').pass_token,
			"time": headers.headers('save').timestamp
		},
		url: '/device/save',
		method: 'POST',
		data: data
	})
}
//读取网络参数列表
export const readNetParamsList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('readNetParams').pass_token,
			"time": headers.headers('readNetParams').timestamp
		},
		url: '/gateway/readNetParams',
		method: 'GET',
		params: data
	})
}