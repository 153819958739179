<template>
	<!--物模型组件-->
	<div class="model">
		<div class="modelBth">
			<el-button @click="batchDeleteFun">批量删除</el-button>
			<el-button @click="emptyModelFun">清空物模型</el-button>
			<el-button @click="readModelFun">读取物模型</el-button>
			<el-button @click="issueModelFun">下发物模型</el-button>
			<el-button @click="selectModelClick">选择物模型</el-button>
		</div>
		<div class="modelTitle">
			<p>PID:{{props.value[0].product_code}} &nbsp;&nbsp;PID别名:{{props.value[0].product_name}}</p>
		</div>
		<div v-for="(item,index) in gw_modelData">
			<div class="selectAll">
				<div style="display: inline-block;margin-right: 10px;">
					<el-button v-if="!item.foldVal" @click="flodClick2(index)" type="primary">
						<el-icon style="transform:rotate(90deg)!important">
							<DArrowRight />
						</el-icon>
					</el-button>
					<el-button v-else="item.foldVal" @click="flodClick2(index)" type="primary">
						<el-icon style="transform:rotate(-90deg)!important">
							<DArrowRight />
						</el-icon>
					</el-button>
				</div>
				<span>序号：{{item.sortNumber}}&nbsp;&nbsp;&nbsp;&nbsp;PID:{{item.code}} &nbsp;&nbsp;PID别名:{{item.name}}</span>
			</div>
			<el-table :data="item.dpList" border style="width: 100%" table-layout="auto" v-loading="modelLoading" @selection-change="handleSelectionChange2($event,index)" :row-class-name="getRowClass2" ref="tableRef" v-show="!item.foldVal">
				<el-table-column label="选取" type="selection" width="60" style="height:20px" />
				<!--<el-table-column label="序号" prop="idx" width="100px">
						<template #default="scope">
							{{scope.$index+1}}
						</template>
					</el-table-column>-->
				<!--<el-table-column label="物模型PID" prop="modelCode">
				</el-table-column>-->
				<el-table-column label="DP序号" prop="no" width="100px">
				</el-table-column>
				<el-table-column label="DP ID" prop="dpid" />
				<el-table-column label="DPCODE" prop="dp_code" />
				<el-table-column label="数据传输类型" prop="trans_type">
					<template #default="scope">
						<p v-if="scope.row.trans_type==='rw'">可下发可上报</p>
						<p v-if="scope.row.trans_type==='ro'">只上报</p>
						<p v-if="scope.row.trans_type==='wr'">只下发</p>
					</template>
				</el-table-column>
				<el-table-column label="数据类型" prop="">
					<template #default="scope">
						<p v-if="scope.row.dp_type==='value'">value,数值型</p>
						<p v-if="scope.row.dp_type==='string'">string,字符型</p>
						<p v-if="scope.row.dp_type==='date'">date,时间型</p>
						<p v-if="scope.row.dp_type==='bool'">bool,布尔型</p>
						<p v-if="scope.row.dp_type==='enum'">enum,枚举型</p>
						<p v-if="scope.row.dp_type==='raw'">raw,透传型</p>
						<p v-if="scope.row.dp_type==='bitmap'">bitmap,故障型</p>
						<p v-if="scope.row.dp_type==='float'">float,单精度浮点型</p>
						<p v-if="scope.row.dp_type==='double'">double,双精度浮点型</p>
					</template>
				</el-table-column>
				<el-table-column class-name="expansion" label="数据定义" prop="" type="expand" width="150px">
					<template #default="props">
						<div m="4">
							<ol>
								<li v-for="(item,index) in props.row.itemList" :key="index">{{ item.i_content }}</li>
							</ol>
							<ol>
								<li v-for="(item,index) in props.row.itemList" :key="index">{{ item.i_val }}</li>
							</ol>
						</div>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="80px">
					<template #default="scope">
						<ul>
							<li @click="delModelFun(scope)">删除</li>
						</ul>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!--删除物模型弹窗-->
		<el-dialog v-model="delModelAlert" :close-on-click-modal="false" class="delModelAlert" width="40%">
			<h3>您确定要删除该物模型吗？</h3>
			<template #footer>
				<span class="dialog-footer">
        <el-button @click="delModelAlert = false">取消</el-button>
        <el-button type="primary" @click="delModelSubmit">确定</el-button>
      </span>
			</template>
		</el-dialog>

		<!--选择物模型弹窗-->
		<el-dialog v-model="selectModelAlert" :close-on-click-modal="false" class="selectModelAlert" title="选择物模型" width="80%">
			<div v-for="(item,index) in modelTreeData">
				<div class="selectAll">
					<div style="display: inline-block;margin-right: 10px;">
						<el-button v-if="!item.foldVal" @click="flodClick(index)" type="primary">
							<el-icon style="transform:rotate(90deg)!important">
								<DArrowRight />
							</el-icon>
						</el-button>
						<el-button v-else="item.foldVal" @click="flodClick(index)" type="primary">
							<el-icon style="transform:rotate(-90deg)!important">
								<DArrowRight />
							</el-icon>
						</el-button>
					</div>
					<span>序号：{{item.sortNumber}}&nbsp;&nbsp;&nbsp;&nbsp;PID:{{item.code}} &nbsp;&nbsp;PID别名:{{item.name}}</span>

				</div>
				<el-table :data="item.children" border style="width: 100%" table-layout="auto" v-loading="selectModelLoading" @selection-change="handleSelectionChange($event,index)" :row-class-name="getRowClass2" ref="tableRef" v-show="!item.foldVal">
					<el-table-column label="选取" type="selection" width="60" style="height:20px" />
					<!--<el-table-column label="序号" prop="idx" width="100px">
						<template #default="scope">
							{{scope.$index+1}}
						</template>
					</el-table-column>-->
					<el-table-column label="DP序号" prop="no" width="100px">
					</el-table-column>
					<el-table-column label="DP ID" prop="dpid" />
					<el-table-column label="DPCODE" prop="code" />
					<el-table-column label="数据传输类型" prop="trans_type">
						<template #default="scope">
							<p v-if="scope.row.trans_type==='rw'">可下发可上报</p>
							<p v-if="scope.row.trans_type==='ro'">只上报</p>
							<p v-if="scope.row.trans_type==='wr'">只下发</p>
						</template>
					</el-table-column>
					<el-table-column label="数据类型" prop="">
						<template #default="scope">
							<p v-if="scope.row.type==='value'">value,数值型</p>
							<p v-if="scope.row.type==='string'">string,字符型</p>
							<p v-if="scope.row.type==='date'">date,时间型</p>
							<p v-if="scope.row.type==='bool'">bool,布尔型</p>
							<p v-if="scope.row.type==='enum'">enum,枚举型</p>
							<p v-if="scope.row.type==='raw'">raw,透传型</p>
							<p v-if="scope.row.type==='bitmap'">bitmap,故障型</p>
							<p v-if="scope.row.type==='float'">float,单精度浮点型</p>
							<p v-if="scope.row.type==='double'">double,双精度浮点型</p>
						</template>
					</el-table-column>
					<el-table-column class-name="expansion" label="数据定义" prop="" type="expand" width="150px">
						<template #default="props">
							<div m="4">
								<ol>
									<li v-for="(item,index) in props.row.itemList" :key="index">{{ item.i_content }}</li>
								</ol>
								<ol>
									<li v-for="(item,index) in props.row.itemList" :key="index">{{ item.i_val }}</li>
								</ol>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<!--<el-pagination background @size-change="model_dpHandleSizeChange" @current-change="model_dpHandleCurrentChange" layout="prev, pager, next" :page-size="model_dpPageSize" :total="model_dpTotal" hide-on-single-page/>-->
			<el-button @click="selectModelSubmit" class="cofirm">确定</el-button>
		</el-dialog>

		<!--读取物模型的弹窗-->
		<el-dialog v-model="readAlert" :close-on-click-modal="false" class="readAlert" width="90%">
			<div class="header">
				<div class="left">
					<div class="tit">读取物模型</div>
					<el-input placeholder="搜索DP名称" v-model='searchVal'></el-input>
					<el-button class="search" @click="searchClick(searchVal,gw_modelDataAll)">搜索</el-button>
				</div>
				<el-button class="close" @click="readAlert=false">关闭</el-button>
			</div>
			<div class="content">
				<div class="leftCont">
					<p class="tit">原物模型</p>
					<div v-for="(item,index) in gw_modelDataCopy">
						<div class="selectAll">
							<div style="display: inline-block;margin-right: 10px;">
								<el-button v-if="!item.foldVal" @click="flodClick3(index)" type="primary">
									<el-icon style="transform:rotate(90deg)!important">
										<DArrowRight />
									</el-icon>
								</el-button>
								<el-button v-else="item.foldVal" @click="flodClick3(index)" type="primary">
									<el-icon style="transform:rotate(-90deg)!important">
										<DArrowRight />
									</el-icon>
								</el-button>
							</div>
							<span>序号：{{item.sortNumber}}&nbsp;&nbsp;&nbsp;&nbsp;PID:{{item.code}} &nbsp;&nbsp;PID别名:{{item.name}}</span>
						</div>
						<el-table :data="item.dpList" border style="width: 100%" table-layout="auto" v-loading="all_model" :row-class-name="getRowClass2" ref="tableRef" v-show="!item.foldVal">
							<el-table-column label="DP序号" prop="no" width="100px">
							</el-table-column>
							<el-table-column label="DP ID" prop="dpid" />
							<el-table-column label="DPCODE" prop="dp_code" />
							<el-table-column label="数据传输类型" prop="trans_type">
								<template #default="scope">
									<p v-if="scope.row.trans_type==='rw'">可下发可上报</p>
									<p v-if="scope.row.trans_type==='ro'">只上报</p>
									<p v-if="scope.row.trans_type==='wr'">只下发</p>
								</template>
							</el-table-column>
							<el-table-column label="数据类型" prop="">
								<template #default="scope">
									<p v-if="scope.row.dp_type==='value'">value,数值型</p>
									<p v-if="scope.row.dp_type==='string'">string,字符型</p>
									<p v-if="scope.row.dp_type==='date'">date,时间型</p>
									<p v-if="scope.row.dp_type==='bool'">bool,布尔型</p>
									<p v-if="scope.row.dp_type==='enum'">enum,枚举型</p>
									<p v-if="scope.row.dp_type==='raw'">raw,透传型</p>
									<p v-if="scope.row.dp_type==='bitmap'">bitmap,故障型</p>
									<p v-if="scope.row.dp_type==='float'">float,单精度浮点型</p>
									<p v-if="scope.row.dp_type==='double'">double,双精度浮点型</p>
								</template>
							</el-table-column>
							<el-table-column class-name="expansion" label="数据定义" prop="" type="expand" width="150px">
								<template #default="props">
									<div m="4">
										<ol>
											<li v-for="(item,index) in props.row.itemList" :key="index">{{ item.i_content }}</li>
										</ol>
										<ol>
											<li v-for="(item,index) in props.row.itemList" :key="index">{{ item.i_val }}</li>
										</ol>
									</div>
								</template>
							</el-table-column>
							<el-table-column fixed="right" label="操作" width="80px">
								<template #default="scope">
									<ul>
										<li @click="delModelFun(scope)">删除</li>
									</ul>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="rightCont">
					<p class="tit">读取的模型</p>
					<el-table :data="readModelData" border style="width: 100%" table-layout="auto" v-loading="readModelLoading" :row-class-name="getRowClass">
						<!--<el-table-column label="序号" prop="idx" width="100px">
							<template #default="scope">
								{{scope.$index+1}}
							</template>
						</el-table-column>-->
						<el-table-column label="DP序号" prop="no" width="100px">
						</el-table-column>
						<el-table-column label="DP ID" prop="dpid" />
						<el-table-column label="DPCODE" prop="dp_code" />
						<el-table-column label="数据传输类型" prop="trans_type" />
						<el-table-column label="数据类型" prop="type" />
						<el-table-column class-name="expansion" label="数据定义" prop="" type="expand" width="150px">
							<template #default="props">
								<div m="4">
									<ol>
										<li v-for="(item,index) in props.row.contentList" :key="index">{{ item.name }}</li>
									</ol>
									<ol>
										<li v-for="(item,index) in props.row.contentList" :key="index">{{ item.val }}</li>
									</ol>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</el-dialog>
		<div v-if="progressShow" class="progressPop">
			<Progress></Progress>
		</div>
	</div>
</template>

<script setup>
	//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
	//例如:import 《组件名称》 from '《组件路径》';
	import { onMounted, reactive, ref, watch } from "vue"
	import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
	import { DArrowRight } from '@element-plus/icons-vue'
	import { Select } from '@element-plus/icons-vue'
	import {
		getTotalList,
		getList,
		saveList,
		getModelList,
		getModel_dpTotalList,
		getModel_dpList,
		cleanDPList,
		cleanPIDList,
		readDPList,
		addPIDList,
		addDPList,
		getGatewayDp
	} from '../../../api/modules/gw_model.js'
	import userObj from '../../../assets/js/cookie'
	import Progress from '../../../components/progress.vue'
	import axios from 'axios';
	import qs from 'qs';

	// 定义传值类型
	const props = defineProps({
		value: String //这里就接收到父组件传递的value
	})

	// 分页 **********************************************************
	// 当前页数
	let pageIndex = ref(1)
	// 每页条数
	let pageSize = ref(20)
	//每页多少
	const handleSizeChange = function(val) {
		pageSize.value = val;
		getListFun();
	}
	//更改页数
	const handleCurrentChange = function(val) {
		pageIndex.value = val;
		getListFun();
	}
	// 获取总条数
	const total = ref(0)
	const getTotalListFun = function() {
		getTotalList({
			gateway_id: props.value[0].id
		}).then(res => {
			//console.log(res);
			total.value = res.data
		}).catch(err => {
			console.log(err);
		})
	}
	// 获取列表
	let modelLoading = ref(false)
	let gw_modelData = ref([])
	let gw_modelDataCopy = ref([])
	const getListFun = function() {
		modelLoading.value = true
		getGatewayDp({
			gatewayId: props.value[0].id
		}).then(res => {
			if(res.data.ok) {
				modelLoading.value = false
				gw_modelData.value = res.data.result
				var resCopy = JSON.stringify(res.data.result)
				resCopy = JSON.parse(resCopy)
				gw_modelDataCopy.value = resCopy
				tableArr2.value = []
				gw_modelData.value.forEach((item, index) => {
					item.foldVal = false
					tableArr2.value.push({
						idx: index
					})
				})
				gw_modelDataCopy.value.forEach((item, index) => {
					item.foldVal = false
				})
			} else {
				gw_modelData.value = []
				gw_modelDataCopy.value = []
				ElMessage({
					type: 'error',
					message: res.message,
				})
			}
		}).catch(err => {
			console.log(err);
		})

	}
	//所有物模型
	let gw_modelDataAll = ref([])
	let all_model = ref(false)
	const getAllListFun = async function() {
		all_model.value = true
		await getList({
			pageSize: -1,
			pageIndex: 1,
			gateway_id: props.value[0].id
		}).then(res => {
			//console.log(res);
			all_model.value = false
			if(res.data.length > 0) {
				gw_modelDataAll.value = res.data
			} else {
				gw_modelDataAll.value = []
			}
		}).catch(err => {
			console.log(err);
		})
	}
	// 分页 **********************************************************
	// 当前页数
	/*let model_dpPageIndex = ref(1)
	// 每页条数
	let model_dpPageSize = ref(20)
	//每页多少
	const model_dpHandleSizeChange = function(val) {
		model_dpPageSize.value = val;
		getModel_dpListFun();
	}
	//更改页数
	const model_dpHandleCurrentChange = function(val) {
		model_dpPageIndex.value = val;
		getModel_dpListFun();
	}
	// 获取物理模型总条数
	const model_dpTotal = ref(0)
	const getModel_dpTotalListFun = function() {
		getModel_dpTotalList({
			gateway_id: props.value[0].id
		}).then(res => {
			//console.log(res);
			model_dpTotal.value = res.data
		}).catch(err => {
			console.log(err);
		})
	}*/
	//网关模型复选框触发
	let multipleSelectionCopy = ref([])
	const handleSelectionChangeCopy = function(val) {
		multipleSelectionCopy.value = val;
	}
	//批量删除
	const batchDeleteFun = function() {
		/*获取表格所有选中的行*/
		tableArr2.value.forEach(item => {
			if(item.children && item.children.length > 0) {
				multipleSelectionCopy.value.push(...item.children)
			}
		})
		if(multipleSelectionCopy.value.length > 0) {
			ElMessageBox.confirm("确认要删除吗？", '注意:', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'success',
			}).then(() => {
				for(var i = 0; i < multipleSelectionCopy.value.length; i++) {
					saveList({
						id: multipleSelectionCopy.value[i].id,
						status: 0
					}).then(res => {
						//console.log(res);
						if(res.data.id) {
							getListFun()
						}
					}).catch(err => {
						console.log(err)
						ElMessage({
							type: 'error',
							message: '删除失败!'
						});
						return
					})
				}
				ElMessage({
					type: 'success',
					message: '删除成功!'
				});
			}).catch(() => {
				ElMessage({
					type: 'info',
					message: '已取消删除!'
				});
			})
		} else {
			ElMessage({
				type: 'info',
				message: '请至少选择一条批量删除的数据!'
			})
		}
	}
	//获取设备型号列表
	let modelData = ref([])
	const getModelListFun = function() {
		getModelList({
			pageSize: -1,
			pageIndex: 1,
			b_id: userObj().b_id
		}).then(res => {
			//console.log(res);
			if(res.data.length > 0) {
				modelData.value = res.data
				modelData.value.forEach(item => {
					item.children = []
				})
				for(var i = 0; i < modelData.value.length; i++) {
					getModel_dpListFun(modelData.value[i].id, i)
				}
			} else {
				modelData.value = []
			}
		}).catch(err => {
			console.log(err);
		})
	}
	//物理模型列表
	let model_dpData = ref([])
	let modelTreeData = ref([])
	let selectModelLoading = ref(false)
	const getModel_dpListFun = function(model_id, i) {
		selectModelLoading.value = true
		getModel_dpList({
			pageSize: -1,
			pageIndex: 1,
			model_id: model_id
		}).then(res => {
			//console.log(res);
			selectModelLoading.value = false
			modelData.value.forEach((item, index) => {
				if(i == index) {
					item.children = res.data
				}
			})
			modelTreeData.value = modelData.value
			if(i + 1 == modelTreeData.value.length) {
				tableArr.value = []
				modelTreeData.value.forEach((item, index) => {
					item.foldVal = false
					tableArr.value.push({
						idx: index
					})
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	/*const getModel_dpListFun = function(model_id, i) {
		selectModelLoading.value = true
		getModel_dpList({
			pageSize: -1,
			pageIndex: 1,
			model_id: model_id
		}).then(res => {
			console.log(res);
			selectModelLoading.value = false
			if(res.data.length > 0) {
				var arrS = [] //物理模型
				var arrP = [] //设备型号
				res.data.forEach(item => {
					modelData.value.push(item)
				})
				modelData.value.forEach(item => {
					if(item.model_id != undefined) {
						arrS.push(item)
					}
				})
				modelData.value.forEach(item => {
					if(item.model_id == undefined) {
						arrP.push(item)
					}
				})
				//处理树
				var childList = arrS
				arrP.forEach(item => {
					var arr = []
					childList.forEach(item_c => {
						if(item.id == item_c.model_id) {
							arr.push(item_c)
							item.children = arr
						}
					})
				})
				modelTreeData.value = arrP
			} else {
				if(i == 0) {
					modelTreeData.value = modelData.value
				}
			}
			if(i + 1 == modelTreeData.value.length) {
				tableArr.value = []
				modelTreeData.value.forEach((item, index) => {
					item.foldVal = false
					tableArr.value.push({
						idx: index
					})
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}*/
	//折叠的点击
	const flodClick = function(index) {
		modelTreeData.value.forEach((item, i) => {
			if(i == index) {
				item.foldVal = !item.foldVal
			}
		})
	}
	//折叠的点击2
	const flodClick2 = function(index) {
		gw_modelData.value.forEach((item, i) => {
			if(i == index) {
				item.foldVal = !item.foldVal
			}
		})
	}
	//折叠的点击2
	const flodClick3 = function(index) {
		gw_modelDataCopy.value.forEach((item, i) => {
			if(i == index) {
				item.foldVal = !item.foldVal
			}
		})
	}
	// 删除物模型弹窗************************************************
	let delModelAlert = ref(false)
	// 删除物模型
	let edit_Id = ref()
	const delModelFun = function(scope) {
		delModelAlert.value = true
		edit_Id.value = scope.row.id
	}
	// 确定删除物模型
	const delModelSubmit = function(id) {
		let params = {
			id: edit_Id.value,
			status: 0
		}
		saveList(
			params
		).then(res => {
			//console.log(res);
			if(res.data.id) {
				delModelAlert.value = false
				ElMessage({
					message: '删除成功',
					type: 'success',
				})
				getListFun()
			}
		}).catch(err => {
			console.log(err);
		})
	}

	// 选择物模型弹窗************************************************
	let selectModelAlert = ref(false)
	// 点击显示物模型弹窗
	const selectModelClick = function() {
		selectModelAlert.value = true
		getModelListFun()
	}
	//选择
	let multipleSelection = ref([])
	let tableArr = ref([])
	const handleSelectionChange = function(e, i) {
		/*创建所有表格数组*/
		tableArr.value.forEach((item, index) => {
			/*根据索引找到表格，把选中的children给它*/
			if(index == i) {
				item.children = e
			}
		})
	}
	//选择2
	let tableArr2 = ref([])
	const handleSelectionChange2 = function(e, i) {
		/*创建所有表格数组*/
		tableArr2.value.forEach((item, index) => {
			/*根据索引找到表格，把选中的children给它*/
			if(index == i) {
				item.children = e
			}
		})
	}

	// 关闭选择物模型弹窗
	const selectModelSubmit = function() {
		multipleSelection.value = []
		/*获取表格所有选中的行*/
		tableArr.value.forEach(item => {
			if(item.children && item.children.length > 0) {
				multipleSelection.value.push(...item.children)
			}
		})
		for(var i = 0; i < multipleSelection.value.length; i++) {
			saveList({
				gateway_id: props.value[0].id,
				model_dp_id: multipleSelection.value[i].id,
				b_manager_id: userObj().b_manager_id,
				model_id: multipleSelection.value[i].model_id,
				status: 1
			}).then(res => {
				//console.log(res);
				getListFun()
				selectModelAlert.value = false
			}).catch(err => {
				console.log(err);
			})
		}
	}

	// 读取物模型************************************************
	let originalModelLoading = ref(false)
	let readAlert = ref(false)
	// 原物模型数据
	let originalModelData = ref([])
	// 读取物模型数据
	let readModelLoading = ref(false)
	let readModelData = ref([])
	let readBeforeModelData = ref([])
	// 点击读取物模型
	const readModelFun = function() {
		readModelData.value = []
		readModelLoading.value = true
		readBeforeModelData.value = []
		getAllListFun()
		readAlert.value = true
		progressShow.value = true
		//openFullScreen()
		readDPList({
			gateway_id: props.value[0].id,
			b_manager_id: userObj().b_manager_id
		}).then(res => {
			//console.log(res);
			//loadingVal.value.close()
			progressShow.value = false
			readModelLoading.value = false
			if(res.data.res == 1) {
				ElMessage({
					type: 'success',
					message: '读取物模型成功!'
				});
				if(res.data.dataRes.length > 0) {
					res.data.dataRes.forEach(item => {
						item.dpList.forEach(items => {
							readModelData.value.push(items)
							readBeforeModelData.value.push(items)
						})
					})
				} else {
					readModelData.value = []
					readBeforeModelData.value = []
				}
			} else {
				ElMessage({
					type: 'error',
					message: res.data.msg + '!'
				});
			}
		}).catch(err => {
			console.log(err);
		})
	}

	// 清空物模型DP
	const emptyModelFun = function() {
		ElMessageBox.confirm("确认清空此物模型吗？", '确认要清空物模型吗？', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'success',
		}).then(() => {
			progressShow.value = true
			//openFullScreen()
			cleanDPList({
				gateway_id: props.value[0].id,
				b_manager_id: userObj().b_manager_id
			}).then(res => {
				//console.log(res);
				cleanPIDListFun()
			}).catch(err => {
				console.log(err);
			})
		}).catch(() => {
			ElMessage({
				type: 'info',
				message: '已取消清空物模型!'
			});
		})
	}
	//清空物模型PID
	const cleanPIDListFun = function() {
		cleanPIDList({
			gateway_id: props.value[0].id,
			b_manager_id: userObj().b_manager_id
		}).then(res => {
			//console.log(res);
			//loadingVal.value.close()
			progressShow.value = false
			if(res.data.res == 1) {
				ElMessage({
					message: '物模型清空成功！',
					type: 'success',
				})
			} else {
				ElMessage({
					type: 'error',
					message: res.data.msg + '!'
				});
			}
		}).catch(err => {
			console.log(err);
		})
	}
	// 下发物模型pid
	const issueModelFun = function() {
		ElMessageBox.confirm("确认下发此物模型吗？", '确认要下发物模型？', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'success',
		}).then(() => {
			//openFullScreen()
			progressShow.value = true
			addPIDList({
				gateway_id: props.value[0].id,
				b_manager_id: userObj().b_manager_id
			}).then(res => {
				//console.log(res);
				addDPListFun()
			}).catch(err => {
				console.log(err);
			})
		}).catch(() => {
			ElMessage({
				type: 'info',
				message: '已取消下发物模型!'
			});
		})
	}
	//下发物模型dp
	const addDPListFun = function() {
		addDPList({
			gateway_id: props.value[0].id,
			b_manager_id: userObj().b_manager_id
		}).then(res => {
			progressShow.value = false
			//loadingVal.value.close()
			//console.log(res);
			if(res.data.res == 1) {
				ElMessage({
					message: '物模型下发成功！',
					type: 'success',
				})
			} else {
				ElMessage({
					type: 'error',
					message: res.data.msg + '!'
				});
			}
		}).catch(err => {
			console.log(err);
		})
	}

	/*加载loading*/
	const loadingVal = ref()
	const progressShow = ref(false)
	const openFullScreen = () => {
		loadingVal.value = ElLoading.service({
			lock: true,
			text: '',
			background: 'rgba(0, 0, 0, 0.7)',
		})
	}
	const getRowClass = function(row, rowIndex) {
		// 判断当前行是否有子数据
		if(row.row.contentList === null || row.row.contentList === undefined || row.row.contentList.length === 0) {
			return 'row-hidden-expand-icon'
		}
	}
	const getRowClass2 = function(row, rowIndex) {
		// 判断当前行是否有子数据
		if(row.row.itemList === null || row.row.itemList === undefined || row.row.itemList.length === 0) {
			return 'row-hidden-expand-icon'
		}
	}
	let searchVal = ref()
	//搜索
	const searchClick = async function(value, arr) {
		await getAllListFun()
		readModelData.value = readBeforeModelData.value
		afterSearchClick(searchVal.value, gw_modelDataAll.value)
		searchClickCopy(searchVal.value, readModelData.value)
	}
	const afterSearchClick = function(value, arr) {
		var newarr = [];
		if(value) {
			arr.forEach(item => {
				if(item.dp_code.indexOf(value) > -1) { // 判断条件
					newarr.push(item);
				}
			});
		} else {
			getAllListFun()
		}
		gw_modelDataAll.value = newarr
		return newarr;
	}
	const searchClickCopy = function(value, arr) {
		var newarr2 = [];
		if(value) {
			arr.forEach(item => {
				if(item.dp_code.indexOf(value) > -1) { // 判断条件
					newarr2.push(item);
				}
			});
		} else {
			newarr2 = readBeforeModelData.value
		}
		readModelData.value = newarr2
		return newarr2;
	}
	watch(() => props.value, (newValue, oldValue) => {
		getListFun()
	}, {
		immediate: true
	})
</script>

<style lang="less">
	.model {
		position: relative;
		background-color: #fff;
		.el-dialog__header {
			display: block !important;
			span {
				font-size: 14px;
			}
		}
		.modelBth {
			display: flex;
			align-items: center;
			height: 40px;
			position: absolute;
			right: 10px;
			top: -45px;
			.el-button {
				height: 30px;
				background-color: #2477F2;
				color: #eeeeee;
				padding: 5px;
			}
		}
		.modelTitle {
			background-color: #fff;
			padding: 10px;
		}
		.el-table {
			padding: 10px;
			.el-scrollbar__view,
			.el-table__body {
				width: 100% !important;
			}
		}
		//删除物模型弹窗
		.delModelAlert {
			padding: 50px 20px 20px;
		}
		//选择物模型弹窗
		.selectModelAlert {
			.selectAll {
				padding: 7px 10px;
				margin: 10px;
				position: relative;
				background-color: #fff;
				/*display: flex;
				justify-content: space-between;*/
			}
			.cofirm {
				width: 80px;
				height: 30px;
				background-color: #2477F2;
				color: #eeeeee;
				margin-left: 50%;
				transform: translateX(-40px);
				margin-top: 50px;
				margin-bottom: 20px;
			}
		}
		//读取物模型弹窗
		.readAlert {
			margin: 50px auto;
			height: calc(100% - 100px) !important;
			background-color: transparent;
			overflow: auto;
			.el-dialog__header {
				display: none !important;
			}
			.el-dialog__body {
				height: 100%;
			}
			.header {
				width: clac(100% - 20px);
				height: 40px;
				background-color: #fff;
				padding: 0 10px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.left {
					width: 500px;
					height: 30px;
					display: flex;
					line-height: 30px;
					.el-input {
						width: 200px;
						margin-left: 30px;
					}
					.el-button {
						width: 80px;
						height: 30px;
						margin-left: 20px;
						background-color: #2477F2;
						color: #eeeeee;
					}
				}
				.el-button {
					width: 80px;
					height: 30px;
					border: 1px solid #2477F2;
					color: #2477F2;
					background-color: #fff;
				}
			}
			.content {
				width: 100%;
				height: calc(100% - 100px);
				overflow: auto;
				display: flex;
				justify-content: space-between;
				margin-top: 10px;
				.leftCont,
				.rightCont {
					overflow: auto;
					width: calc(49.5% - 20px);
					background-color: #fff;
					padding: 20px 10px;
					.el-table {
						height: 100%;
					}
				}
			}
		}
		.el-table__expanded-cell {
			position: relative;
			height: 87px !important;
			ol {
				display: flex;
				margin: 0;
				position: absolute;
				top: 12px;
				right: 0;
				li {
					width: 100px;
					height: 30px;
					border: 1px solid #efefef;
					text-align: center;
					line-height: 30px;
				}
			}
			ol:nth-child(2) {
				top: 43px;
			}
		}
		.row-hidden-expand-icon td .el-icon svg {
			display: none!important;
		}
		.progressPop {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.7);
			z-index: 9999;
		}
		.selectAll {
			padding: 7px 10px;
			margin: 10px;
			position: relative;
		}
	}
</style>