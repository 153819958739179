<template>
  <div class="equipment">
    <div class="equipmentBth">
      <div style="display: none;">
        <el-upload v-model:file-list="fileList" :before-upload="beforeUpload"
                   :data="personData" :show-file-list="false" accept=".xls,.xlsx"
                   action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
                   class="upload-demo">
          <button id="fileName2" slot="trigger" class="selectFile" size="small" type="primary">选择文件</button>
        </el-upload>
      </div>
      <el-button><a href="https://lotb.380v.com/template/batch_import_device_template.xlsx"
                    style="color: #fff;">模板下载</a></el-button>
      <el-button @click="batchDeleteFun">批量删除</el-button>
      <el-button @click="registerFun">拓扑管理</el-button>
      <el-button @click="importFun">批量导入设备</el-button>
      <el-button @click="batchClick">批量扩展录入</el-button>
      <el-button @click="readFun3(1)">读取档案</el-button>
      <el-button @click="kongFileClick">清空档案</el-button>
      <el-button @click="sendFileClick">下发档案</el-button>
      <el-button @click="createEquipmentFun">新增设备</el-button>
    </div>
    <el-table v-loading="loading" :data="equipmentData" border style="width: 100%"
              table-layout="auto" @selection-change="handleSelectionChange">
      <el-table-column label="选取" style="height:20px" type="selection" width="55">
      </el-table-column>
      <el-table-column label="序号" prop="idx" width="70px">
        <template #default="scope">
          {{ (pageIndex - 1) * pageSize + scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="PID+别名" prop="">
        <template #default="scope">
          <!--          {{ scope.row.model_code }}+{{ scope.row.model_name }}-->
          {{ scope.row.modelCode }}+{{ scope.row.modelName }}
        </template>
      </el-table-column>
      <el-table-column label="通讯地址" prop="addr"/>
      <el-table-column label="端口" prop="portVal"/>
      <!--      <el-table-column label="波特率" prop="rate_typeVal"/>-->
      <el-table-column label="波特率" prop="rateTypeStr"/>
      <!--      <el-table-column label="规约类型" prop="ruler_typeVal"/>-->
      <!--      <el-table-column label="规约类型" prop="rulerTypeStr"/>-->
      <!--      <el-table-column label="电压变比" prop="rate_v"/>-->
      <el-table-column label="电压变比" prop="rateV"/>
      <!--      <el-table-column label="电流变比" prop="rate_a"/>-->
      <el-table-column label="电流变比" prop="rateA"/>
      <el-table-column label="备注" prop="remark"/>
      <el-table-column fixed="right" label="操作" width="150px">
        <template #default="scope">
          <ul>
            <li @click="extendClick(scope.row)">扩展录入</li>
            <li @click="delEquipmentFun(scope.row.id)">删除</li>
          </ul>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :page-size="pageSize" :total="total" background
                   hide-on-single-page layout="prev, pager, next" @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"/>
    <!--删除设备弹窗-->
    <el-dialog v-model="delEquipmentAlert" :close-on-click-modal="false" class="delEquipmentAlert" width="40%">
      <h3>您确定要删除该设备吗？</h3>
      <template #footer>
				<span class="dialog-footer">
        <el-button @click="delEquipmentAlert = false">取消</el-button>
        <el-button type="primary" @click="delEquipmentSubmit">确定</el-button>
      </span>
      </template>
    </el-dialog>
    <!--新增设备弹窗-->
    <el-dialog v-model="createEquipmentAlert" :close-on-click-modal="false" class="createEquipmentAlert" title="新增设备"
               width="40%">
      <el-form :model="createEquipmentForm">
        <el-form-item label="设备PID" prop="model_id">
          <el-select v-model="createEquipmentForm.model_id" placeholder="请选择设备PID+设备别名">
            <el-option v-for="item in modelData" :label="item.codeName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="端口" prop="port">
          <el-select v-model="createEquipmentForm.port" placeholder="请选择端口">
            <el-option v-for="item in portData" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="变比" prop="rate_a">
          <el-input v-model="createEquipmentForm.rate_a" placeholder="电流"/>
        </el-form-item>
        <el-form-item prop="rate_v">
          <el-input v-model="createEquipmentForm.rate_v" placeholder="电压"/>
        </el-form-item>
        <el-form-item label="通讯地址" prop="addr">
          <el-input v-model="createEquipmentForm.addr" placeholder="单行输入" @change="addrChange"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="createEquipmentFormSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--扩展录入-->
    <el-dialog v-model="extendDialog" :close-on-click-modal="false" class="extendDialogClass" title="扩展录入" width="80%">
      <div v-if="!large" class="checkAllBox2">
        <el-checkbox v-model="checkedAll2" @change="checkedAllChange2()">全选</el-checkbox>
      </div>
      <div style="display: flex;">
        <div style="flex: 4;">
          <el-form>
            <div class="largeCheckBoxClass">
              <el-checkbox v-if="!large" v-model="extendEquipmentForm.model_idChecked"
                           class='largeCheckClass' @change="checkedRadioChange2()"></el-checkbox>
              <el-form-item label="设备型号" label-width="30%">
                <el-select v-model="extendEquipmentForm.model_id" placeholder="请选择" style="width: 100%;">
                  <el-option v-for="item in modelData" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <!--<div class="largeCheckBoxClass">
              <el-checkbox v-model="extendEquipmentForm.category_idChecked" @change="checkedRadioChange2()" class='largeCheckClass' v-if="!large"></el-checkbox>
              <el-form-item label="监测设备类型" label-width="30%">
                <el-select v-model="extendEquipmentForm.category_id" placeholder="请选择" style="width:100%" @change="categoryChange()">
                  <el-option v-for="item in categoryData" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="largeCheckBoxClass">
              <el-checkbox v-model="extendEquipmentForm.product_idChecked" @change="checkedRadioChange2()" class='largeCheckClass' v-if="!large"></el-checkbox>
              <el-form-item label="监测设备型号" label-width="30%">
                <el-select v-model="extendEquipmentForm.product_id" placeholder="请选择" style="width:100%">
                  <el-option v-for="item in projectData" :key="item.id" :label="item.model" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="largeCheckBoxClass">
              <el-form-item label="设备编号" label-width="30%" v-if="large">
                <span>{{extendEquipmentForm.equipmentCode}}</span>
              </el-form-item>
            </div>-->
            <div class="largeCheckBoxClass">
              <el-checkbox v-if="!large" v-model="extendEquipmentForm.rate_vChecked"
                           class='largeCheckClass' @change="checkedRadioChange2()"></el-checkbox>
              <el-form-item label="电压变比" label-width="30%">
                <el-input v-model="extendEquipmentForm.rate_v" autocomplete="off" type="text"
                          @input="extendEquipmentForm.rate_v=extendEquipmentForm.rate_v.replace(/^(0+)|[^\d]+/g,'')"></el-input>
              </el-form-item>
            </div>
            <div class="largeCheckBoxClass">
              <el-checkbox v-if="!large" v-model="extendEquipmentForm.rate_aChecked"
                           class='largeCheckClass' @change="checkedRadioChange2()"></el-checkbox>
              <el-form-item label="电流变比" label-width="30%">
                <el-input v-model="extendEquipmentForm.rate_a" autocomplete="off" type="text"
                          @input="extendEquipmentForm.rate_a=extendEquipmentForm.rate_a.replace(/^(0+)|[^\d]+/g,'')"></el-input>
              </el-form-item>
            </div>
            <div class="largeCheckBoxClass">
              <el-checkbox v-if="!large" v-model="extendEquipmentForm.addrChecked"
                           class='largeCheckClass' @change="checkedRadioChange2()"></el-checkbox>
              <el-form-item label="通讯地址" label-width="30%">
                <el-input v-model="extendEquipmentForm.addr" autocomplete="off" type="text"
                          @change="addrChangeCopy"></el-input>
              </el-form-item>
            </div>
            <div class="largeCheckBoxClass">
              <el-checkbox v-if="!large" v-model="extendEquipmentForm.rate_typeChecked"
                           class='largeCheckClass' @change="checkedRadioChange2()"></el-checkbox>
              <el-form-item label="波特率" label-width="30%">
                <el-select v-model="extendEquipmentForm.rate_type" placeholder="请选择" style="width:100%">
                  <el-option v-for="item in rate_typeData" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="largeCheckBoxClass">
              <el-checkbox v-if="!large" v-model="extendEquipmentForm.rated_vChecked"
                           class='largeCheckClass' @change="checkedRadioChange2()"></el-checkbox>
              <el-form-item label="额定电压" label-width="30%">
                <el-input v-model="extendEquipmentForm.rated_v" autocomplete="off" type="text"></el-input>
              </el-form-item>
            </div>
            <div class="largeCheckBoxClass">
              <el-checkbox v-if="!large" v-model="extendEquipmentForm.rated_aChecked"
                           class='largeCheckClass' @change="checkedRadioChange2()"></el-checkbox>
              <el-form-item label="额定电流" label-width="30%">
                <el-input v-model="extendEquipmentForm.rated_a" autocomplete="off" type="text"></el-input>
              </el-form-item>
            </div>
            <div class="largeCheckBoxClass">
              <el-checkbox v-if="!large" v-model="extendEquipmentForm.rated_remarkChecked"
                           class='largeCheckClass' @change="checkedRadioChange2()"></el-checkbox>
              <el-form-item label="备注" label-width="30%">
                <el-input v-model="extendEquipmentForm.remark" autocomplete="off" type="text"></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div style="flex: 1;"></div>
        <div style="flex: 4;">
          <el-form>
            <div class="largeCheckBoxClass">
              <el-checkbox v-if="!large" v-model="extendEquipmentForm.ruler_typeChecked"
                           class='largeCheckClass' @change="checkedRadioChange2()"></el-checkbox>
              <el-form-item label="规约类型" label-width="30%">
                <el-select v-model="extendEquipmentForm.ruler_type" placeholder="请选择" style="width:100%">
                  <el-option v-for="item in ruler_typeData" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="largeCheckBoxClass">
              <el-checkbox v-if="!large" v-model="extendEquipmentForm.portChecked"
                           class='largeCheckClass' @change="checkedRadioChange2()"></el-checkbox>
              <el-form-item label="端口" label-width="30%">
                <el-select v-model="extendEquipmentForm.port" placeholder="请选择" style="width:100%">
                  <el-option v-for="item in portData" :key="item.value" :label="item.name" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="largeCheckBoxClass">
              <el-checkbox v-if="!large" v-model="extendEquipmentForm.passwordChecked"
                           class='largeCheckClass' @change="checkedRadioChange2()"></el-checkbox>
              <el-form-item label="通信密码" label-width="30%">
                <el-input v-model="extendEquipmentForm.password" autocomplete="off" type="text"></el-input>
              </el-form-item>
            </div>
            <div class="largeCheckBoxClass">
              <el-checkbox v-if="!large" v-model="extendEquipmentForm.fee_numChecked"
                           class='largeCheckClass' @change="checkedRadioChange2()"></el-checkbox>
              <el-form-item label="费率个数" label-width="30%">
                <el-input v-model="extendEquipmentForm.fee_num" autocomplete="off" type="text"></el-input>
              </el-form-item>
            </div>
            <div class="largeCheckBoxClass">
              <el-checkbox v-if="!large" v-model="extendEquipmentForm.user_typeChecked"
                           class='largeCheckClass' @change="checkedRadioChange2()"></el-checkbox>
              <el-form-item label="用户类型" label-width="30%">
                <el-input v-model="extendEquipmentForm.user_type" autocomplete="off" type="text"></el-input>
              </el-form-item>
            </div>
            <div class="largeCheckBoxClass">
              <el-checkbox v-if="!large" v-model="extendEquipmentForm.join_typeChecked"
                           class='largeCheckClass' @change="checkedRadioChange2()"></el-checkbox>
              <el-form-item label="接线方式" label-width="30%">
                <el-select v-model="extendEquipmentForm.join_type" placeholder="请选择" style="width:100%">
                  <el-option v-for="item in join_typeData" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="largeCheckBoxClass">
              <el-checkbox v-if="!large" v-model="extendEquipmentForm.p_codeChecked"
                           class='largeCheckClass' @change="checkedRadioChange2()"></el-checkbox>
              <el-form-item label="资产号" label-width="30%">
                <el-input v-model="extendEquipmentForm.p_code" autocomplete="off" type="text"></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
       <el-button class="confrim" type="primary" @click="extendSave()">立即创建</el-button>
				<el-button class="cancle" @click="extendDialog=false">取消</el-button>
      </span>
      </template>
    </el-dialog>
    <!--子设备注册弹窗-->
    <el-dialog v-model="registerAlert" :close-on-click-modal="false" class="registerAlert" title="子设备注册" width="80%">
      <div class="btn">
        <el-button @click="readFun">读取拓扑</el-button>
        <el-button @click="readFun2(2)">读取完整档案</el-button>
        <el-button @click="queryFun">查询执行结果</el-button>
        <el-button @click="unbindFun">子设备解绑</el-button>
        <el-button @click="deleteFun">子设备删除</el-button>
        <el-button @click="sonRegisterFun">子设备注册</el-button>
      </div>
      <el-input v-model="result" readonly style="width: 100%;;height: 200px"></el-input>
    </el-dialog>
    <!--读取拓扑的弹窗-->
    <el-dialog v-model="readAlert" :close-on-click-modal="false" class="readAlert" title="子设备拓扑" width="80%">
      <el-table v-loading="readTopologyLoading" :data="readTopologyData" border style="width: 100%" table-layout="auto">
        <el-table-column label="序号" prop="idx" width="100px">
          <template #default="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="PID编号" prop="pid_no"/>
        <el-table-column label="PID" prop="pid"/>
        <el-table-column label="注册ID" prop="reg_id"/>
        <el-table-column label="设备ID" prop="did"/>
        <el-table-column label="设备名称" prop="d_name"/>
      </el-table>
      <el-button @click="readAlert=false">确定</el-button>
    </el-dialog>
    <!--删除子设备弹窗-->
    <el-dialog v-model="delSonEquipmentAlert" :close-on-click-modal="false" class="delSonEquipmentAlert" title=""
               width="40%">
      <!--确认发布提示-->
      <div class="confirmBody">
        <img alt="" src="../../../assets/images/jinggao.svg">
        <p>确认删除子设备，删除不可逆！</p>
      </div>
      <!--取消确认-->
      <div class="operation">
        <el-button @click="delSonEquipmentSubmit">确认</el-button>
      </div>
    </el-dialog>
    <!--发送解绑、注册、删除的弹窗-->
    <el-dialog v-model="timeAlert" :close-on-click-modal="false" class="timeAlert">
      <el-icon>
        <Select/>
      </el-icon>
      <p>已发送{{ timeAlertValue }}</p>
    </el-dialog>
    <!--读取弹窗-->
    <el-dialog v-model="readDialog" :close-on-click-modal="false" title="档案读取列表" width="80%">
      <div>
        <el-table v-loading="readLoading" :data="readData" border style="width: 100%" table-layout="auto">
          <el-table-column label="上报时间" min-width="1%" prop="apply_time">
          </el-table-column>
          <el-table-column label="上报内容" min-width="3%" prop="apply">
          </el-table-column>
          <el-table-column label="操作" min-width="1%" prop="">
            <template #default="scope">
              <a class="editClass" @click.prevent="analysisClick(scope.row.command_log_id)">解析</a>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="confrim" style='background-color: #2477F2;color:#fff;'
                   @click="readDialog=false">确认</el-button>
      </span>
      </template>
    </el-dialog>
    <!--解析弹窗-->
    <el-dialog v-model="analysisDialog" :close-on-click-modal="false" title="档案解析" width="95%">
      <!--档案解析-->
      <el-table v-loading="analysisLoading" :data="analysisList" border style="width: 100%" table-layout="auto">
        <el-table-column v-if="readShow==2" label="PID" prop="pid" width="130">
        </el-table-column>
        <el-table-column v-if="readShow==2" label="设备ID" prop="did" width="130">
        </el-table-column>
        <el-table-column v-if="readShow==2" label="设备状态" prop="code" width="130">
        </el-table-column>
        <el-table-column label="设备编号" prop="archive_no" width="130">
        </el-table-column>
        <el-table-column label="通讯地址" prop="tsa" width="130">
        </el-table-column>
        <el-table-column label="用户类型" prop="user_type" width="130">
        </el-table-column>
        <el-table-column label="端口" prop="port" width="130">
        </el-table-column>
        <el-table-column label="波特率" prop="rate_type" width="130">
        </el-table-column>
        <el-table-column label="规约类型" prop="ruler_type" width="130">
        </el-table-column>
        <el-table-column label="电压变比" prop="rate_v" width="130">
        </el-table-column>
        <el-table-column label="电流变比" prop="rate_a" width="130">
        </el-table-column>
        <el-table-column label="通讯密码" prop="password" width="130">
        </el-table-column>
        <el-table-column label="费率个数" prop="fee_num" width="130">
        </el-table-column>
        <el-table-column label="接线方式" prop="join_type" width="130">
        </el-table-column>
        <el-table-column label="额定电压" prop="rated_v" width="130">
        </el-table-column>
        <el-table-column label="额定电流" prop="rated_a" width="130">
        </el-table-column>
        <el-table-column label="资产号" prop="p_code" width="130">
        </el-table-column>
      </el-table>
      <template #footer>
				<span class="dialog-footer">
       <el-button class="confrim" type="primary" @click="analysisDialog=false">确认</el-button>
      </span>
      </template>
    </el-dialog>
    <div v-if="progressShow" class="progressPop">
      <Progress></Progress>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref, watch} from "vue"
import {ElMessage, ElMessageBox, ElLoading} from "element-plus";
import {Select} from '@element-plus/icons-vue'
import {
  getTotalList,
  getList,
  saveList,
  getModelList,
  addList,
  cleanList,
  readList,
  applyList,
  readSubTopoList,
  readSubArchiveList,
  unbandSubFun,
  delSubFun,
  registerSubFun,
  querySubFun,
  uploadFile,
  importDevice, editList
} from '../../../api/modules/gw_device.js'
import userObj from '../../../assets/js/cookie'
import {v1 as uuidv1} from 'uuid'
import moment from 'moment'
import Progress from '../../../components/progress.vue'

// 定义传值类型
const props = defineProps({
  value: String //这里就接收到父组件传递的value
})

// 分页 **********************************************************
// 当前页数
let pageIndex = ref(1)
// 每页条数
let pageSize = ref(20)
//每页多少
const handleSizeChange = function (val) {
  pageSize.value = val;
  getListFun();
}
//更改页数
const handleCurrentChange = function (val) {
  pageIndex.value = val;
  getListFun();
}
// 获取总条数
const total = ref(0)
const getTotalListFun = function () {
  getTotalList({
    gateway_id: props.value[0].id
  }).then(res => {
    //console.log(res);
    total.value = res.data
  }).catch(err => {
    console.log(err);
  })
}
// 获取列表
let loading = ref(false)
let equipmentData = ref([])
const getListFun = function () {
  loading.value = true
  getList({
    // pageSize: pageSize.value,
    // pageIndex: pageIndex.value,
    // gateway_id: props.value[0].id

    pageSize: pageSize.value,
    pageNum: pageIndex.value,
    gatewayId: props.value[0].id
  }).then(res => {
    //console.log(res);
    loading.value = false
    if (res.data.result.list.length > 0) {
      // equipmentData.value = res.data
      equipmentData.value = res.data.result.list
      equipmentData.value.forEach(item => {
        portData.value.forEach(items => {
          if (item.port == items.value) {
            item.portVal = items.name
          }
        })
        rate_typeData.value.forEach(itemc => {
          if (item.rate_type == itemc.id) {
            item.rate_typeVal = itemc.name
          }
        })
        ruler_typeData.value.forEach(itemz => {
          if (item.ruler_type == itemz.id) {
            item.ruler_typeVal = itemz.name
          }
        })
      })
      total.value = Number(res.data.result.total)
      // getTotalListFun()
    } else {
      equipmentData.value = []
    }
  }).catch(err => {
    console.log(err);
  })
}
//获取设备型号列表
let modelData = ref()
const getModelListFun = function () {
  getModelList({
    pageSize: -1,
    pageIndex: 1,
    b_id: userObj().b_id
  }).then(res => {
    //console.log(res);
    if (res.data.length > 0) {
      modelData.value = res.data
      modelData.value.forEach(item => {
        item.codeName = item.code + '+' + item.name
      })
    } else {
      modelData.value = []
    }
  }).catch(err => {
    console.log(err);
  })
}

//端口列表
let portData = ref([{
  name: 'F2010201:4851',
  value: 'F2010201'
},
  {
    name: 'F2010202:4852',
    value: 'F2010202'
  },
  {
    name: 'F2010203:4853',
    value: 'F2010203'
  },
  {
    name: 'F2010204:4584',
    value: 'F2010204'
  },
  {
    name: 'F20B0201:蓝牙',
    value: 'F20B0201'
  },
  {
    name: 'F2090201:载波/微功率无线接口',
    value: 'F2090201'
  }
])

// 新增设备相关事件及数据***********************************************************
// 新增设备弹窗
let createEquipmentAlert = ref(false)
// 新建设备弹窗表单数据
let createEquipmentForm = ref({
  model_id: '',
  port: '',
  rate_a: 1,
  rate_v: 1,
  addr: '',
  ruler_type: 5, //规约类型
  password: '123456020000', //通信密码
  fee_num: 4, //费率个数
  user_type: 1, //用户类型
  rate_type: 134, //波特率
  join_type: 3, //接线方式
  rated_v: 1, //额定电压
  rated_a: 1, //额定电流
  p_code: '000000000000' //资产号
})
// 点击新增设备显示弹窗
const createEquipmentFun = function () {
  createEquipmentAlert.value = true
  createEquipmentForm.value = {
    model_id: '',
    port: '',
    rate_a: 1,
    rate_v: 1,
    addr: '',
    ruler_type: 5, //规约类型
    password: '123456020000', //通信密码
    fee_num: 4, //费率个数
    user_type: 1, //用户类型
    rate_type: 134, //波特率
    join_type: 3, //接线方式
    rated_v: 1, //额定电压
    rated_a: 1, //额定电流
    p_code: '000000000000' //资产号
  }
}
//通讯地址改变
const addrChange = function () {
  createEquipmentForm.value.addr = createEquipmentForm.value.addr.padStart(12, '0')
}
const addrChangeCopy = function () {
  extendEquipmentForm.value.addr = extendEquipmentForm.value.addr.padStart(12, '0')
}

// 点击提交新增设备
const createEquipmentFormSubmit = function () {
  let params = {
    b_manager_id: userObj().b_manager_id,
    model_id: createEquipmentForm.value.model_id,
    gateway_id: props.value[0].id,
    password: createEquipmentForm.value.password, //通信密码
    ruler_type: createEquipmentForm.value.ruler_type, //规约类型
    fee_num: createEquipmentForm.value.fee_num, //费率个数
    user_type: createEquipmentForm.value.user_type, //用户类型
    rate_type: createEquipmentForm.value.rate_type, //波特率
    join_type: createEquipmentForm.value.join_type, //接线方式
    rated_v: createEquipmentForm.value.rated_v, //额定电压
    rated_a: createEquipmentForm.value.rated_a, //额定电流
    p_code: createEquipmentForm.value.p_code, //资产号
    port: createEquipmentForm.value.port, //端口
    rate_v: createEquipmentForm.value.rate_v, //电压变比
    rate_a: createEquipmentForm.value.rate_a, //电流变比
    addr: createEquipmentForm.value.addr, //通讯地址
    create_time: Date.now(),
    status: 1,
    version: 1
  }
  if (createEquipmentForm.value.port && createEquipmentForm.value.rate_v && createEquipmentForm.value.rate_a && createEquipmentForm.value.addr && createEquipmentForm.value.model_id) {
    saveList(params).then(res => {
      //console.log(res);
      getListFun()
      createEquipmentAlert.value = false
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      message: '请输入必填项！',
      type: 'info',
    })
  }
}

//扩展录入相关事件**************************************************************
let join_typeData = ref([{
  id: 0,
  name: '未知（0）'
},
  {
    id: 1,
    name: '单相（1）'
  },
  {
    id: 2,
    name: '三相三线（2）'
  },
  {
    id: 3,
    name: '三相四线（3）'
  },
]) //接线方式列表
let rate_typeData = ref([{
  id: 255,
  name: '自适应（偶校验）'
},
  {
    id: 0,
    name: '300bps（偶校验）'
  },
  {
    id: 1,
    name: '600bps（偶校验）'
  },
  {
    id: 2,
    name: '1200bps（偶校验）'
  },
  {
    id: 3,
    name: '2400bps（偶校验）'
  },
  {
    id: 4,
    name: '4800bps（偶校验）'
  },
  {
    id: 5,
    name: '7200bps（偶校验）'
  },
  {
    id: 6,
    name: '9600bps（偶校验）'
  },
  {
    id: 7,
    name: '19200bps（偶校验）'
  },
  {
    id: 8,
    name: '38400bps（偶校验）'
  },
  {
    id: 9,
    name: '57600bps（偶校验）'
  },
  {
    id: 10,
    name: '115200bps（偶校验）'
  },
  {
    id: 128,
    name: '300bps（无校验）'
  },
  {
    id: 129,
    name: '600bps（无校验）'
  },
  {
    id: 130,
    name: '1200bps（无校验）'
  },
  {
    id: 131,
    name: '2400bps（无校验）'
  },
  {
    id: 132,
    name: '4800bps（无校验）'
  },
  {
    id: 133,
    name: '7200bps（无校验）'
  },
  {
    id: 134,
    name: '9600bps（无校验）'
  },
  {
    id: 135,
    name: '19200bps（无校验）'
  },
  {
    id: 136,
    name: '38400bps（无校验）'
  },
  {
    id: 137,
    name: '57600bps（无校验）'
  },
  {
    id: 138,
    name: '115200bps（无校验）'
  }
]) //波特率列表
let ruler_typeData = ref([{
  id: 0,
  name: '未知（0）'
},
  {
    id: 1,
    name: 'DL/T 645-1997（1）'
  },
  {
    id: 2,
    name: 'DL/T 645—2007（2）'
  },
  {
    id: 3,
    name: 'DL/T 698.45（3）'
  },
  {
    id: 4,
    name: 'CJ/T 188—2004（4）'
  },
  {
    id: 5,
    name: 'ModBus（5）'
  },
  {
    id: 6,
    name: 'ModBus高字节在前CRC（6）'
  }
]) //规约类型列表
// 扩展录入******************************************************************
let large = ref(false)
let extendDialog = ref(false)
let extendEquipmentForm = ref({
  model_id: '',
  rate_v: '',
  rate_a: '',
  addr: '',
  fee_num: '',
  user_type: '',
  rated_v: '',
  rated_a: '',
  remark: '',
  p_code: '',
  password: '',
  port: '',
  join_type: '',
  rate_type: '',
  ruler_type: '',
  category_id: '',
  product_id: '',
  equipmentCode: '',
  /*左*/
  model_idChecked: false,
  category_idChecked: false,
  product_idChecked: false,
  rate_vChecked: false,
  rate_aChecked: false,
  addrChecked: false,
  rate_typeChecked: false,
  /*右*/
  ruler_typeChecked: false,
  portChecked: false,
  passwordChecked: false,
  fee_numChecked: false,
  user_typeChecked: false,
  join_typeChecked: false,
  rated_vChecked: false,
  rated_aChecked: false,
  rated_remarkChecked: false,
  p_codeChecked: false,
})
const batchClick = function () {
  large.value = false
  if (multipleSelection.value.length > 0) {
    extendDialog.value = true
  } else {
    ElMessage({
      type: 'info',
      message: '请至少选择一条批量扩展录入的数据!'
    })
  }
  extendEquipmentForm.value.edit_id = ''
  extendEquipmentForm.value.nameFour = ''
  extendEquipmentForm.value.model_id = ''
  extendEquipmentForm.value.rate_v = ''
  extendEquipmentForm.value.rate_a = ''
  extendEquipmentForm.value.addr = ''
  extendEquipmentForm.value.fee_num = ''
  extendEquipmentForm.value.user_type = ''
  extendEquipmentForm.value.rated_v = ''
  extendEquipmentForm.value.rated_a = ''
  extendEquipmentForm.value.remark = ''
  extendEquipmentForm.value.p_code = ''
  extendEquipmentForm.value.password = ''
  extendEquipmentForm.value.port = ''
  extendEquipmentForm.value.join_type = ''
  extendEquipmentForm.value.rate_type = ''
  extendEquipmentForm.value.ruler_type = ''
  extendEquipmentForm.value.category_id = ''
  extendEquipmentForm.value.product_id = ''
  extendEquipmentForm.value.equipmentCode = ''
  /*左*/
  extendEquipmentForm.value.model_idChecked = false
  extendEquipmentForm.value.category_idChecked = false
  extendEquipmentForm.value.product_idChecked = false
  extendEquipmentForm.value.rate_vChecked = false
  extendEquipmentForm.value.rate_aChecked = false
  extendEquipmentForm.value.addrChecked = false
  extendEquipmentForm.value.rate_typeChecked = false
  /*右*/
  extendEquipmentForm.value.ruler_typeChecked = false
  extendEquipmentForm.value.portChecked = false
  extendEquipmentForm.value.passwordChecked = false
  extendEquipmentForm.value.fee_numChecked = false
  extendEquipmentForm.value.user_typeChecked = false
  extendEquipmentForm.value.join_typeChecked = false
  extendEquipmentForm.value.rated_vChecked = false
  extendEquipmentForm.value.rated_aChecked = false
  extendEquipmentForm.value.rated_remarkChecked = false
  extendEquipmentForm.value.p_codeChecked = false
}
let edit_Id = ref()
const extendClick = function (val) {
  edit_Id.value = val.id
  large.value = true
  extendDialog.value = true
  // extendEquipmentForm.value.model_id = val.model_id
  // extendEquipmentForm.value.gatewayId = val.gatewayId
  // extendEquipmentForm.value.password = val.password //通信密码
  // extendEquipmentForm.value.ruler_type = val.ruler_type //规约类型
  // extendEquipmentForm.value.fee_num = val.fee_num //费率个数
  // extendEquipmentForm.value.user_type = val.user_type //用户类型
  // extendEquipmentForm.value.rate_type = val.rate_type //波特率
  // extendEquipmentForm.value.join_type = val.join_type //接线方式
  // extendEquipmentForm.value.rated_v = val.rated_v //额定电压
  // extendEquipmentForm.value.rated_a = val.rated_a //额定电流
  // extendEquipmentForm.value.remark = val.remark //备注
  // extendEquipmentForm.value.p_code = val.p_code //资产号
  // extendEquipmentForm.value.port = val.port //端口
  // extendEquipmentForm.value.rate_v = val.rate_v //电压变比
  // extendEquipmentForm.value.rate_a = val.rate_a //电流变比
  // extendEquipmentForm.value.addr = val.addr //通讯地址


  extendEquipmentForm.value.model_id = Number(val.modelId)
  extendEquipmentForm.value.gatewayId = val.gatewayId
  extendEquipmentForm.value.password = val.password //通信密码
  extendEquipmentForm.value.ruler_type = val.rulerType //规约类型
  extendEquipmentForm.value.fee_num = val.feeNum //费率个数
  extendEquipmentForm.value.user_type = val.userType //用户类型
  extendEquipmentForm.value.rate_type = val.rateType //波特率
  extendEquipmentForm.value.join_type = val.joinType //接线方式
  extendEquipmentForm.value.rated_v = val.ratedV //额定电压
  extendEquipmentForm.value.rated_a = val.ratedA //额定电流
  extendEquipmentForm.value.remark = val.remark //备注
  extendEquipmentForm.value.p_code = val.pCode //资产号
  extendEquipmentForm.value.port = val.port //端口
  extendEquipmentForm.value.rate_v = val.rateV //电压变比
  extendEquipmentForm.value.rate_a = val.rateA //电流变比
  extendEquipmentForm.value.addr = val.addr //通讯地址

}
//复选框触发
let multipleSelection = ref([])
const handleSelectionChange = function (val) {
  multipleSelection.value = val;
}
let checkedAll2 = ref(false)
//批量扩展录入全选
const checkedAllChange2 = function () {
  if (checkedAll2.value == true) {
    extendEquipmentForm.value.model_idChecked = true
    extendEquipmentForm.value.category_idChecked = true
    extendEquipmentForm.value.product_idChecked = true
    extendEquipmentForm.value.rate_vChecked = true
    extendEquipmentForm.value.rate_aChecked = true
    extendEquipmentForm.value.addrChecked = true
    extendEquipmentForm.value.rate_typeChecked = true
    /*右*/
    extendEquipmentForm.value.ruler_typeChecked = true
    extendEquipmentForm.value.portChecked = true
    extendEquipmentForm.value.passwordChecked = true
    extendEquipmentForm.value.fee_numChecked = true
    extendEquipmentForm.value.user_typeChecked = true
    extendEquipmentForm.value.join_typeChecked = true
    extendEquipmentForm.value.rated_vChecked = true
    extendEquipmentForm.value.rated_aChecked = true
    extendEquipmentForm.value.rated_remarkChecked = true
    extendEquipmentForm.value.p_codeChecked = true
  } else {
    extendEquipmentForm.value.model_idChecked = false
    extendEquipmentForm.value.category_idChecked = false
    extendEquipmentForm.value.product_idChecked = false
    extendEquipmentForm.value.rate_vChecked = false
    extendEquipmentForm.value.rate_aChecked = false
    extendEquipmentForm.value.addrChecked = false
    extendEquipmentForm.value.rate_typeChecked = false
    /*右*/
    extendEquipmentForm.value.ruler_typeChecked = false
    extendEquipmentForm.value.portChecked = false
    extendEquipmentForm.value.passwordChecked = false
    extendEquipmentForm.value.fee_numChecked = false
    extendEquipmentForm.value.user_typeChecked = false
    extendEquipmentForm.value.join_typeChecked = false
    extendEquipmentForm.value.rated_vChecked = false
    extendEquipmentForm.value.rated_aChecked = false
    extendEquipmentForm.value.rated_remarkChecked = false
    extendEquipmentForm.value.p_codeChecked = false
  }
}
const checkedRadioChange2 = function () {
  /*extendEquipmentForm.value.category_idChecked && extendEquipmentForm.value.product_idChecked*/
  if (extendEquipmentForm.value.model_idChecked && extendEquipmentForm.value.rate_vChecked && extendEquipmentForm.value.rate_aChecked && extendEquipmentForm.value.addrChecked && extendEquipmentForm.value.rate_typeChecked && extendEquipmentForm.value.ruler_typeChecked && extendEquipmentForm.value.portChecked && extendEquipmentForm.value.passwordChecked && extendEquipmentForm.value.fee_numChecked && extendEquipmentForm.value.user_typeChecked && extendEquipmentForm.value.join_typeChecked && extendEquipmentForm.value.rated_vChecked && extendEquipmentForm.value.rated_aChecked && extendEquipmentForm.value.rated_remarkChecked && extendEquipmentForm.value.p_codeChecked) {
    checkedAll2.value = true
  } else {
    checkedAll2.value = false
  }
}
//扩展录入的保存
const extendSave = function () {
  if (large.value) {
    extendConfirm()
  } else {
    batchExtendConfirm()
  }
}
//扩展录入编辑保存
const extendConfirm = function () {
  var params = {
    id: edit_Id.value,
    // model_id: extendEquipmentForm.value.model_id,
    // gatewayId: extendEquipmentForm.value.gatewayId,
    // password: extendEquipmentForm.value.password, //通信密码
    // ruler_type: extendEquipmentForm.value.ruler_type, //规约类型
    // fee_num: extendEquipmentForm.value.fee_num, //费率个数
    // user_type: extendEquipmentForm.value.user_type, //用户类型
    // rate_type: extendEquipmentForm.value.rate_type, //波特率
    // join_type: extendEquipmentForm.value.join_type, //接线方式
    // rated_v: extendEquipmentForm.value.rated_v, //额定电压
    // rated_a: extendEquipmentForm.value.rated_a, //额定电流
    // remark: extendEquipmentForm.value.remark, //额定电流
    // p_code: extendEquipmentForm.value.p_code, //资产号
    // port: extendEquipmentForm.value.port, //端口
    // rate_v: extendEquipmentForm.value.rate_v, //电压变比
    // rate_a: extendEquipmentForm.value.rate_a, //电流变比
    // addr: extendEquipmentForm.value.addr, //通讯地址


    modelId: extendEquipmentForm.value.model_id,
    gatewayId: extendEquipmentForm.value.gatewayId,
    password: extendEquipmentForm.value.password, //通信密码
    rulerType: extendEquipmentForm.value.ruler_type, //规约类型
    feeNum: extendEquipmentForm.value.fee_num, //费率个数
    userType: extendEquipmentForm.value.user_type, //用户类型
    rateType: extendEquipmentForm.value.rate_type, //波特率
    joinType: extendEquipmentForm.value.join_type, //接线方式
    ratedV: extendEquipmentForm.value.rated_v, //额定电压
    ratedA: extendEquipmentForm.value.rated_a, //额定电流
    remark: extendEquipmentForm.value.remark, //额定电流
    pCode: extendEquipmentForm.value.p_code, //资产号
    port: extendEquipmentForm.value.port, //端口
    rateV: extendEquipmentForm.value.rate_v, //电压变比
    rateA: extendEquipmentForm.value.rate_a, //电流变比
    addr: extendEquipmentForm.value.addr, //通讯地址
  }
  editList(params).then(res => {
    //console.log(res);
    getListFun()
    extendDialog.value = false
  }).catch(err => {
    console.log(err);
  })
}
//批量扩展录入
const batchExtendConfirm = function () {
  var params = {}
  for (var i = 0; i < multipleSelection.value.length; i++) {
    params['id'] = multipleSelection.value[i].id
    params['status'] = 1
    if (extendEquipmentForm.value.model_idChecked && extendEquipmentForm.value.model_id) {
      params['model_id'] = extendEquipmentForm.value.model_id
    }
    if (extendEquipmentForm.value.rate_vChecked && extendEquipmentForm.value.rate_v) {
      params['rate_v'] = extendEquipmentForm.value.rate_v
    }
    if (extendEquipmentForm.value.rate_aChecked && extendEquipmentForm.value.rate_a) {
      params['rate_a'] = extendEquipmentForm.value.rate_a
    }
    if (extendEquipmentForm.value.addrChecked && extendEquipmentForm.value.addr) {
      params['addr'] = extendEquipmentForm.value.addr
    }
    if (extendEquipmentForm.value.rate_typeChecked && extendEquipmentForm.value.rate_type) {
      params['rate_type'] = extendEquipmentForm.value.rate_type
    }
    if (extendEquipmentForm.value.ruler_typeChecked && extendEquipmentForm.value.ruler_type) {
      params['ruler_type'] = extendEquipmentForm.value.ruler_type
    }
    if (extendEquipmentForm.value.portChecked && extendEquipmentForm.value.port) {
      params['port'] = extendEquipmentForm.value.port
    }
    if (extendEquipmentForm.value.passwordChecked && extendEquipmentForm.value.password) {
      params['password'] = extendEquipmentForm.value.password
    }
    if (extendEquipmentForm.value.fee_numChecked && extendEquipmentForm.value.fee_num) {
      params['fee_num'] = extendEquipmentForm.value.fee_num
    }
    if (extendEquipmentForm.value.user_typeChecked && extendEquipmentForm.value.user_type) {
      params['user_type'] = extendEquipmentForm.value.user_type
    }
    if (extendEquipmentForm.value.join_typeChecked && extendEquipmentForm.value.join_type) {
      params['join_type'] = extendEquipmentForm.value.join_type
    }
    if (extendEquipmentForm.value.rated_vChecked && extendEquipmentForm.value.rated_v) {
      params['rated_v'] = extendEquipmentForm.value.rated_v
    }
    if (extendEquipmentForm.value.rated_aChecked && extendEquipmentForm.value.rated_a) {
      params['rated_a'] = extendEquipmentForm.value.rated_a
    }
    if (extendEquipmentForm.value.rated_remarkChecked && extendEquipmentForm.value.remark) {
      params['remark'] = extendEquipmentForm.value.remark
    }
    if (extendEquipmentForm.value.p_codeChecked && extendEquipmentForm.value.p_code) {
      params['p_code'] = extendEquipmentForm.value.p_code
    }
    //console.log(params)
    saveList(params).then(res => {
      //console.log(res);
      getListFun()
      extendDialog.value = false
    }).catch(err => {
      console.log(err);
    })
  }
}
// 删除弹窗***********************************************************
let delEquipmentAlert = ref(false)
// 删除物模型

const delEquipmentFun = function (id) {
  delEquipmentAlert.value = true
  edit_Id.value = id
}
// 确定删除物模型
const delEquipmentSubmit = function () {
  let params = {
    id: edit_Id.value,
    status: 0
  }
  saveList(
      params
  ).then(res => {
    //console.log(res);
    if (res.data.id) {
      delEquipmentAlert.value = false
      ElMessage({
        message: '删除成功',
        type: 'success',
      })
      getListFun()
    }
  }).catch(err => {
    console.log(err);
  })
}

// 批量导入设备相关事件及数据***********************************************************
let personData = ref({}) //自定义上传参数
let fileList = ref([]) //上传文件
const importFun = function () {
  document.getElementById("fileName2").click();
}
// 上传
const beforeUpload = function (file) {
  let fd = new FormData();
  fd.append('file', file); //传文件
  fd.append('path', '/mnt/xvdb/html/gw/storage/upload/' + new Date().getFullYear() + new Date().getMonth() + 1); //传其他参数
  var name = uuidv1()
  var index = file.name.lastIndexOf(".");
  var type = file.name.substring(index, file.name.length);
  fd.append('fileName', name + type); //传其他参数
  uploadFile(fd).then(res => {
    if (res.data) {
      importDeviceFun(res.data);
    }
  }).catch(err => {
    console.log(err)
  })
}
// 批量导入设备
const importDeviceFun = function (file) {
  let fd = new FormData();
  fd.append('file', file); //传文件
  fd.append('b_manager_id ', userObj().b_manager_id); //传其他参数
  fd.append('gateway_id  ', props.value[0].id);
  importDevice(fd).then(res => {
    if (res.data.res) {
      getListFun()
      ElMessage({
        message: '导入成功',
        type: 'success'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//批量删除
const batchDeleteFun = function () {
  if (multipleSelection.value.length > 0) {
    ElMessageBox.confirm("确认要删除吗？", '注意:', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'success',
    }).then(() => {
      for (var i = 0; i < multipleSelection.value.length; i++) {
        saveList({
          id: multipleSelection.value[i].id,
          status: 0
        }).then(res => {
          //console.log(res);
          if (res.data.id) {
            getListFun()
          }
        }).catch(err => {
          console.log(err)
          ElMessage({
            type: 'error',
            message: '删除失败!'
          });
          return
        })
      }
      ElMessage({
        type: 'success',
        message: '删除成功!'
      });
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: '已取消删除!'
      });
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请至少选择一条批量删除的数据!'
    })
  }
}

// 子设备注册相关事件及数据***********************************************************
// 子设备注册弹窗
let registerAlert = ref(false)
// 点击显示注册弹窗
const registerFun = function () {
  registerAlert.value = true
}

// 读取拓扑相关事件及数据***********************************************************
let readTopologyLoading = ref(false)
// 读取拓扑弹窗
let readAlert = ref(false)
// 读取拓扑弹窗数据
let readTopologyData = ref([])
// 点击显示读取拓扑弹窗
const readFun = function () {
  readAlert.value = true
  readTopologyLoading.value = true
  readSubTopoList({
    gateway_id: props.value[0].id,
    b_manager_id: userObj().b_manager_id
  }).then(res => {
    //console.log(res);
    readTopologyLoading.value = false
    if (res.data.res == 1) {
      if (res.data.dataRes && res.data.dataRes.length > 0) {
        readTopologyData.value = res.data.dataRes
      } else {
        readTopologyData.value = []
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.msg + '!'
      });
    }
  }).catch(err => {
    console.log(err);
  })
}
// 查询注册结果相关事件及数据***********************************************************
let result = ref()
const queryFun = function () {
  openFullScreen()
  querySubFun({
    gateway_id: props.value[0].id,
    b_manager_id: userObj().b_manager_id
  }).then(res => {
    //console.log(res);
    loadingVal.value.close()
    if (res.data.res != 0) {
      result.value = res.data.msg + '！'
      ElMessage({
        type: 'success',
        message: '查询结果成功!'
      });
    } else {
      ElMessage({
        type: 'error',
        message: res.data.msg + '!'
      });
    }
  }).catch(err => {
    console.log(err);
  })
}

// 子设备解绑相关事件及数据***********************************************************
// 发送解绑、注册、删除的弹窗
let timeAlert = ref(false)
let timeAlertValue = ref('')
const unbindFun = function () {
  timeAlertValue.value = '解绑'
  openFullScreen()
  unbandSubFun({
    gateway_id: props.value[0].id,
    b_manager_id: userObj().b_manager_id
  }).then(res => {
    //console.log(res);
    loadingVal.value.close()
    if (res.data.id) {
      result.value = res.data.msg + '！'
      timeAlert.value = true
      setTimeout(() => {
        timeAlert.value = false
      }, 1000)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.msg + '!'
      });
    }
  }).catch(err => {
    console.log(err);
  })
}

// 子设备注册相关事件及数据***********************************************************
const sonRegisterFun = function () {
  timeAlertValue.value = '注册'
  openFullScreen()
  registerSubFun({
    gateway_id: props.value[0].id,
    b_manager_id: userObj().b_manager_id
  }).then(res => {
    //console.log(res);
    loadingVal.value.close()
    if (res.data.id) {
      result.value = res.data.msg + '！'
      timeAlert.value = true
      setTimeout(() => {
        timeAlert.value = false
      }, 1000)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.msg + '!'
      });
    }
  }).catch(err => {
    console.log(err);
  })
}

// 子设备删除相关事件及数据***********************************************************
// 子设备删除弹窗
let delSonEquipmentAlert = ref(false)
// 点击显示子设备删除弹窗
const deleteFun = function () {
  delSonEquipmentAlert.value = true
}
const delSonEquipmentSubmit = function () {
  openFullScreen()
  delSonEquipmentAlert.value = false
  timeAlertValue.value = '删除'
  delSubFun({
    gateway_id: props.value[0].id,
    b_manager_id: userObj().b_manager_id
  }).then(res => {
    //console.log(res);
    loadingVal.value.close()
    if (res.data.id) {
      result.value = res.data.msg + '！'
      timeAlert.value = true
      setTimeout(() => {
        timeAlert.value = false
      }, 1000)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.msg + '!'
      });
    }
  }).catch(err => {
    console.log(err);
  })
}
// 读取档案相关事件及数据***********************************************************
let readLoading = ref(false)
let readDialog = ref(false)
let analysisDialog = ref(false)
// 读取完整档案相关事件及数据
const readFun2 = function (val) {
  readShow.value = val
  analysisLoading.value = true
  analysisList.value = []
  readData.value = []
  //openFullScreen()
  progressShow.value = true
  readDialog.value = true
  readSubArchiveList({
    gateway_id: props.value[0].id,
    b_manager_id: userObj().b_manager_id
  }).then(res => {
    //console.log(res);
    //loadingVal.value.close()
    progressShow.value = false
    analysisLoading.value = false
    if (res.data.res == 1) {
      if (res.data.dataRes.length > 0) {
        analysisList.value = res.data.dataRes
      } else {
        analysisList.value = []
      }
      getReadList(res.data.id);
      ElMessage({
        type: 'success',
        message: '读取完整档案成功!'
      });
    } else {
      ElMessage({
        type: 'error',
        message: res.data.msg + '!'
      });
    }
  }).catch(err => {
    console.log(err);
  })
}
// 读取完整档案相关事件及数据
const readShow = ref()
const readFun3 = function (val) {
  readShow.value = val
  analysisLoading.value = true
  analysisList.value = []
  readData.value = []
  //openFullScreen()
  progressShow.value = true
  readDialog.value = true
  readList({
    gateway_id: props.value[0].id,
    b_manager_id: userObj().b_manager_id
  }).then(res => {
    //console.log(res);
    //loadingVal.value.close()
    progressShow.value = false
    analysisLoading.value = false
    readLoading.value = true
    readLoading.value = false
    if (res.data.res == 1) {
      if (res.data.dataRes.length > 0) {
        analysisList.value = res.data.dataRes
      } else {
        analysisList.value = []
      }
      getReadList(res.data.id);
      ElMessage({
        type: 'success',
        message: '读取档案成功!'
      });
    } else {
      ElMessage({
        type: 'error',
        message: res.data.msg + '!'
      });
    }
  }).catch(err => {
    console.log(err);
  })
}
let readData = ref([])
const getReadList = function (id) {
  applyList({
    pageSize: -1,
    pageIndex: 1,
    command_log_id: id
  }).then(res => {
    //console.log(res);
    readLoading.value = false
    if (res.data.length > 0) {
      readData.value = res.data
      readData.value.forEach(item => {
        item.apply_time = moment(item.apply_time).format('YYYY-MM-DD HH:mm:ss')
      })
    } else {
      readData.value = []
    }
  }).catch(err => {
    console.log(err);
  })
}
//解析
const analysisClick = function () {
  analysisDialog.value = true
}
let analysisLoading = ref(false)
let analysisList = ref([])
// 清空档案*********************************************************************
const kongFileClick = function () {
  ElMessageBox.confirm("确认清空此网关的档案吗？", '确认要清空档案吗？', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'success',
  }).then(() => {
    //openFullScreen()
    progressShow.value = true
    cleanList({
      gateway_id: props.value[0].id,
      b_manager_id: userObj().b_manager_id
    }).then(res => {
      //console.log(res);
      //loadingVal.value.close()
      progressShow.value = false
      if (res.data.res == 1) {
        ElMessage({
          message: '档案清空成功！',
          type: 'success',
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.msg + '!'
        });
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消清空档案！',
    })
  })
}
// 下发档案******************************************************************
const sendFileClick = function () {
  ElMessageBox.confirm("确认下发此网关的档案吗？", '确认要下发档案吗？', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'success',
  }).then(() => {
    //openFullScreen()
    progressShow.value = true
    addList({
      gateway_id: props.value[0].id,
      b_manager_id: userObj().b_manager_id
    }).then(res => {
      //loadingVal.value.close()
      progressShow.value = false
      //console.log(res);
      if (res.data.res == 1) {
        ElMessage({
          message: '档案下发成功！',
          type: 'success',
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.msg + '!'
        });
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消档案下发！',
    })
  })
}
/*加载loading*/
const loadingVal = ref()
const progressShow = ref(false)
const openFullScreen = () => {
  loadingVal.value = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
}

watch(() => props.value, (newValue, oldValue) => {
  getListFun()
  getModelListFun()
}, {
  immediate: true
})
</script>

<style lang="less">
.equipment {
  position: relative;
  background: #fff;
  padding-bottom: 20px;
  //显示弹窗头部
  .el-dialog__header {
    display: block !important;

    span {
      font-size: 14px;
    }
  }

  //按钮列表
  .equipmentBth {
    display: flex;
    align-items: center;
    height: 40px;
    position: absolute;
    right: 10px;
    top: -45px;

    .el-button {
      height: 30px;
      background-color: #2477F2;
      color: #eeeeee;
      padding: 5px;
    }
  }

  //表格
  .el-table {
    padding: 10px;

    .el-scrollbar__view,
    .el-table__body {
      width: 100% !important;
    }
  }

  //删除设备弹窗
  .delEquipmentAlert {
    padding: 50px 20px 20px;
  }

  //新增设备弹窗
  .createEquipmentAlert {
    .el-form {
      padding: 30px 20px;

      .el-form-item {
        display: block;
      }

      .el-form-item:nth-child(3),
      .el-form-item:nth-child(4) {
        width: 49%;
        display: inline-block;
      }

      .el-form-item:nth-child(4) {
        margin-left: 2%;
      }

      .el-select {
        width: 100%;
      }
    }

    .el-button {
      width: 80px;
      height: 30px;
      background-color: #2477F2;
      color: #eeeeee;
      margin-left: 50%;
      transform: translateX(-40px);
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }

  //批量导入弹窗
  .importEquipmentAlert {
    .close {
      width: 80px;
      height: 30px;
      margin-top: 30px;
      margin-bottom: 20px;
      background-color: #A4ADB3;
      color: #eeeeee;
      margin-left: 50%;
      transform: translateX(-40px);
      border: 0;
    }
  }

  //子设备注册
  .registerAlert {
    .el-dialog__body {
      padding: 0 10px;

      .el-input {
        margin-bottom: 50px;
      }

      .btn {
        display: flex;
        justify-content: right;
        align-items: center;
        height: 40px;
        margin-bottom: 10px;

        .el-button {
          height: 30px;
          background-color: #2477F2;
          color: #eeeeee;
          padding: 5px;
        }
      }
    }
  }

  //读取拓扑弹窗
  .readAlert {
    .el-button {
      margin: 30px 50% 20px;
      transform: translateX(-40px);
      width: 80px;
      background-color: #2477F2;
      color: #eeeeee;
    }
  }

  //发送解绑、注册、删除的弹窗
  .timeAlert {
    width: 120px;
    height: 120px;
    background-color: rgba(0, 0, 0, 0.8);
    margin-top: 10%;

    .el-dialog__header {
      display: none !important;
    }

    .el-dialog__body {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .el-icon {
        color: aliceblue;
        font-size: 40px;
      }

      p {
        color: #eeeeee;
      }
    }
  }

  //删除子设备弹窗
  .delSonEquipmentAlert {
    .el-dialog__header {
      display: none;
    }

    //确认描述
    .confirmBody {
      width: 100%;
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      img {
        width: 50px;
      }

      p {
        font-size: 20px;
        color: #2d2d2d;
      }
    }

    //操作按钮
    .operation {
      display: flex;
      justify-content: center;
      font-size: 14px;

      .el-button {
        margin: 30px auto 20px;
        width: 80px;
        height: 30px;
        background-color: #2477F2;
        color: #fff;
      }
    }
  }

  /*档案读取*/

  .editClass {
    color: #2477F2;
    cursor: pointer;
  }

  .el-dialog__footer {
    position: relative;
    text-align: center;
  }

  .largeCheckBoxClass {
    position: relative;
  }

  .largeCheckClass {
    position: absolute;
    top: 0px;
    left: 3%;
  }

  .extendDialogClass .el-dialog__body {
    padding: 30px !important;
  }

  .progressPop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
  }
}
</style>
