import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'


import Login from '../pages/login/login'
import Admin from "@/pages/admin/admin";
import Gateway from "@/pages/gateway/gateway";
import IntegratedEquipment from "@/pages/integratedEquipment/integratedEquipment";
import Monitoring from "@/pages/monitoring/monitoring";
import Project from "@/pages/project/project";
import Administrator from "@/pages/administrator/administrator";

// 路由文件
const routes = [
// 路由的默认路径
    {
        path: '/',
        redirect: "/admin"
    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/admin',
        redirect: "/admin/gateway",
        meta: {
            requireAuth: true
        },
        component: Admin,
        children: [
            {
                path: '/admin/gateway',
                meta: {
                    requireAuth: true
                },
                component: Gateway
            },
            {
                path: '/admin/integratedEquipment',
                meta: {
                    requireAuth: true
                },
                component: IntegratedEquipment,
            },
            {
                path: '/admin/monitoring',
                meta: {
                    requireAuth: true
                },
                component: Monitoring,
            },
            {
                path: '/admin/project',
                meta: {
                    requireAuth: true
                },
                component: Project
            },
            {
                path: '/admin/administrator',
                meta: {
                    requireAuth: true
                },
                component: Administrator,
            }
        ]
    }
]


const router = createRouter({
    // history: createWebHistory(),
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})
export default router;