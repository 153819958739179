import { api, apiCopy } from "@/api";
import headers from '../../assets/js/headers'

// 获取列表总数
export const getTotalList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getListByLimitCount').pass_token,
			"time": headers.headers('getListByLimitCount').timestamp
		},
		url: '/plan/getListByLimitCount',
		method: 'GET',
		params: data
	})
}

// 获取某页列表
export const getList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getListByLimit').pass_token,
			"time": headers.headers('getListByLimit').timestamp
		},
		url: '/plan/getListByLimit',
		method: 'GET',
		params: data
	})
}

// 新增,编辑，删除 列表
export const saveList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('save').pass_token,
			"time": headers.headers('save').timestamp
		},
		url: '/plan/save',
		method: 'POST',
		data: data
	})
}
//下发
export const addList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('addCollect').pass_token,
			"time": headers.headers('addCollect').timestamp
		},
		url: '/gateway/addCollect',
		method: 'GET',
		params: data
	})
}
//清空
export const cleanList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('cleanCollect').pass_token,
			"time": headers.headers('cleanCollect').timestamp
		},
		url: '/gateway/cleanCollect',
		method: 'GET',
		params: data
	})
}
//读取
export const readList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('readCollect').pass_token,
			"time": headers.headers('readCollect').timestamp
		},
		url: '/gateway/readCollect',
		method: 'GET',
		params: data
	})
}
//解析
export const applyList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getListByLimit').pass_token,
			"time": headers.headers('getListByLimit').timestamp
		},
		url: '/command_apply/getListByLimit',
		method: 'GET',
		params: data
	})
}

// 获取设备列表
export const getModelList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getListByLimit').pass_token,
			"time": headers.headers('getListByLimit').timestamp
		},
		url: '/model/getListByLimit',
		method: 'GET',
		params: data
	})
}
// 获取设备任务列表
export const getModelPlanList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getListByLimit').pass_token,
			"time": headers.headers('getListByLimit').timestamp
		},
		url: '/model_plan/getListByLimit',
		method: 'GET',
		params: data
	})
}
// 获取某网关下可用的方案列表
export const getGatewayPlanList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getByGateway').pass_token,
			"time": headers.headers('getByGateway').timestamp
		},
		url: '/model_plan/getByGateway',
		method: 'GET',
		params: data
	})
}

// 新增采集方案记录列表
export const savePlanList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('save').pass_token,
			"time": headers.headers('save').timestamp
		},
		url: '/plan_column/save',
		method: 'POST',
		data: data
	})
}

// 获取采集方案记录列表
export const getPlanList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getListByLimit').pass_token,
			"time": headers.headers('getListByLimit').timestamp
		},
		url: '/plan_column/getListByLimit',
		method: 'GET',
		params: data
	})
}
//设备型号方案
export const getListModelPlan = (data) => {
	return apiCopy({
		headers: {
			"Content-Type": "application/x-www-form-urlencoded"
		},
		url: '/zouk/modelPlan/listModelPlan',
		method: 'POST',
		data: data
	})
}
//设备型号信息
export const getListModel = (data) => {
	return apiCopy({
		headers: {
			"Content-Type": "application/x-www-form-urlencoded"
		},
		url: '/zouk/model/listModel',
		method: 'POST',
		data: data
	})
}