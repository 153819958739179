import {api} from "@/api";
import headers from '../../assets/js/headers'

// 获取列表总数
export const getTotalList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getListByLimitCount').pass_token,
			"time": headers.headers('getListByLimitCount').timestamp
		},
		url: '/task/getListByLimitCount',
		method: 'GET',
		params: data
	})
}

// 获取某页列表
export const getList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getListByLimit').pass_token,
			"time": headers.headers('getListByLimit').timestamp
		},
		url: '/task/getListByLimit',
		method: 'GET',
		params: data
	})
}

// 新增,编辑，删除 列表
export const saveList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('save').pass_token,
			"time": headers.headers('save').timestamp
		},
		url: '/task/save',
		method: 'POST',
		data: data
	})
}
//下发
export const addList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('addTask').pass_token,
			"time": headers.headers('addTask').timestamp
		},
		url: '/gateway/addTask',
		method: 'GET',
		params: data
	})
}
//清空
export const cleanList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('cleanTask').pass_token,
			"time": headers.headers('cleanTask').timestamp
		},
		url: '/gateway/cleanTask',
		method: 'GET',
		params: data
	})
}
//读取
export const readList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('readTask').pass_token,
			"time": headers.headers('readTask').timestamp
		},
		url: '/gateway/readTask',
		method: 'GET',
		params: data
	})
}
//解析
export const applyList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getListByLimit').pass_token,
			"time": headers.headers('getListByLimit').timestamp
		},
		url: '/command_apply/getListByLimit',
		method: 'GET',
		params: data
	})
}
//方案列表
export const getPlanList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getListByLimit').pass_token,
			"time": headers.headers('getListByLimit').timestamp
		},
		url: '/plan/getListByLimit',
		method: 'GET',
		params: data
	})
}