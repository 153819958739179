import { api, apiCopy } from "@/api";
import headers from '../../assets/js/headers'

// 获取列表总数
export const getTotalList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getListByLimitCount').pass_token,
			"time": headers.headers('getListByLimitCount').timestamp
		},
		url: '/gateway_dp/getListByLimitCount',
		method: 'GET',
		params: data
	})
}

// 获取某页列表
export const getList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getListByLimit').pass_token,
			"time": headers.headers('getListByLimit').timestamp
		},
		url: '/gateway_dp/getListByLimit',
		method: 'GET',
		params: data
	})
}
// 获取物模型列表总数
export const getModel_dpTotalList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getListByLimitCount').pass_token,
			"time": headers.headers('getListByLimitCount').timestamp
		},
		url: '/model_dp/getListByLimitCount',
		method: 'GET',
		params: data
	})
}
// 获取设备型号列表
export const getModelList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getListByLimit').pass_token,
			"time": headers.headers('getListByLimit').timestamp
		},
		url: '/model/getListByLimit',
		method: 'GET',
		params: data
	})
}
// 获取物模型列表
export const getModel_dpList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('getListByLimit').pass_token,
			"time": headers.headers('getListByLimit').timestamp
		},
		url: '/model_dp/getListByLimit',
		method: 'GET',
		params: data
	})
}

// 新增,编辑，删除 列表
export const saveList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('save').pass_token,
			"time": headers.headers('save').timestamp
		},
		url: '/gateway_dp/save',
		method: 'POST',
		data: data
	})
}
//下发模型PID
export const addPIDList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('addPID').pass_token,
			"time": headers.headers('addPID').timestamp
		},
		url: '/gateway/addPID',
		method: 'GET',
		params: data
	})
}
//下发模型DP
export const addDPList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('addDP').pass_token,
			"time": headers.headers('addDP').timestamp
		},
		url: '/gateway/addDP',
		method: 'GET',
		params: data
	})
}
//清空模型DP
export const cleanDPList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('cleanDP').pass_token,
			"time": headers.headers('cleanDP').timestamp
		},
		url: '/gateway/cleanDP',
		method: 'GET',
		params: data
	})
}
//清空模型PID
export const cleanPIDList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('cleanPID').pass_token,
			"time": headers.headers('cleanPID').timestamp
		},
		url: '/gateway/cleanPID',
		method: 'GET',
		params: data
	})
}
//读取模型
export const readDPList = (data) => {
	return api({
		headers: {
			"pass-token": headers.headers('readDP').pass_token,
			"time": headers.headers('readDP').timestamp
		},
		url: '/gateway/readDP',
		method: 'GET',
		params: data
	})
}
//物模型列表
export const getGatewayDp = (data) => {
	return apiCopy({
		headers: {
			"Content-Type": "application/x-www-form-urlencoded"
		},
		url: '/zouk/gatewayDp/listStoreSub',
		method: 'POST',
		data: data
	})
}