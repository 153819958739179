<template>
	<div class="history">
		<el-table :data="historyData" border style="width: 100%" v-loading="analysisLoading">
			<el-table-column label="时间" prop="create_time" width="180px" />
			<el-table-column prop="msg" label="上报内容" min-width="1%">
			</el-table-column>
			<el-table-column prop="" label="回复" min-width="1%">
				<template #default="scope">
					<div v-for="item in scope.row.applyList">
						{{item.apply}}
					</div>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="prev, pager, next" :page-size="pageSize" :total="total" hide-on-single-page />
	</div>
</template>

<script setup>
	//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
	//例如:import 《组件名称》 from '《组件路径》';
	import { onMounted, reactive, ref, watch } from "vue"
	import { getTotalList, getList, saveList } from '../../../api/modules/gw_history.js'
	import userObj from '../../../assets/js/cookie'
	import moment from 'moment'

	// 定义传值类型
	const props = defineProps({
		value: String //这里就接收到父组件传递的value
	})

	// 分页 **********************************************************
	// 当前页数
	let pageIndex = ref(1)
	// 每页条数
	let pageSize = ref(20)
	//每页多少
	const handleSizeChange = function(val) {
		pageSize.value = val;
		getListFun();
	}
	//更改页数
	const handleCurrentChange = function(val) {
		pageIndex.value = val;
		getListFun();
	}
	// 获取总条数
	const total = ref(0)
	const getTotalListFun = function() {
		getTotalList({
			gateway_id: props.value[0].id
		}).then(res => {
			//console.log(res);
			total.value = res.data
		}).catch(err => {
			console.log(err);
		})
	}
	// 获取列表
	let analysisLoading = ref(false)
	let historyData = ref([])
	const getListFun = function() {
		analysisLoading.value = true
		getList({
			pageSize: pageSize.value,
			pageIndex: pageIndex.value,
			gateway_id: props.value[0].id
		}).then(res => {
			//console.log(res);
			analysisLoading.value = false
			if(res.data.length > 0) {
				historyData.value = res.data
				historyData.value.forEach(item => {
					item.create_time = moment(item.create_time).format('YYYY-MM-DD HH:mm:ss')
				})
				getTotalListFun()
			} else {
				historyData.value = []
			}
		}).catch(err => {
			console.log(err);
		})
	}
	watch(() => props.value, (newValue, oldValue) => {
		getListFun()
	}, {
		immediate: true
	})
</script>

<style lang="less">
	.history {
		//表格
		.el-table {
			padding: 10px;
			.el-scrollbar__view,
			.el-table__body {
				width: 100% !important;
			}
		}
	}
</style>