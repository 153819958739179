<template>
	<div class="projectBox">
		<!--标题-->
		<div class="title">
			<p>项目管理</p>
			<button @click="createFun">新建项目</button>
			<!--<a download="模板文件.xlsx" class="editClass downLoadClass" href="./template/template.xlsx">模板下载</a>-->
		</div>
		<!--项目表格-->
		<el-table :data="projectData" border style="width: 100%;margin-top: 10px" table-layout="auto" v-loading="loading">
			<el-table-column label="序号" prop="idx" width="100px">
				<template #default="scope">
					{{(pageIndex-1)*pageSize+scope.$index+1}}
				</template>
			</el-table-column>
			<el-table-column label="项目名称" prop="name" />
			<el-table-column fixed="right" label="操作" width="150px">
				<template #default="scope">
					<ul>
						<li @click="editProject(scope)">编辑</li>
						<li @click="delProject(scope)">删除</li>
					</ul>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="prev, pager, next" :page-size="pageSize" :total="total" hide-on-single-page/>

		<!--新建编辑项目弹窗-->
		<el-dialog v-model="projectAlert" class="projectAlert" title="新建项目" width="40%" :close-on-click-modal="false">
			<el-form ref="projectFormRef" :model="projectForm" :rules="projectRules" :validate="validate">
				<el-form-item label="项目名称" prop="name">
					<el-input v-model="projectForm.name" placeholder="单行输入" />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="projectFormSubmit">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<!--删除项目弹窗-->
		<el-dialog v-model="delProjectAlert" class="delProjectAlert" width="40%" :close-on-click-modal="false">
			<h3>您确定要删除该项目吗？</h3>
			<template #footer>
				<span class="dialog-footer">
          <el-button @click="delProjectAlert = false">取消</el-button>
          <el-button type="primary" @click="delProjectFun">确定</el-button>
        </span>
			</template>
		</el-dialog>
	</div>
</template>

<script setup>
	import { onMounted, reactive, ref } from "vue"
	import { ElMessage } from "element-plus";
	import './project.less'
	import { getTotalList, getList, saveList } from '../../api/modules/project'
	import userObj from '../../assets/js/cookie'

	onMounted(() => {
		getListFun()
	})
	// 分页 **********************************************************
	// 当前页数
	let pageIndex = ref(1)
	// 每页条数
	let pageSize = ref(20)
	//每页多少
	const handleSizeChange = function(val) {
		pageSize.value = val;
		getListFun();
	}
	//更改页数
	const handleCurrentChange = function(val) {
		pageIndex.value = val;
		getListFun();
	}
	// 获取总条数
	const total = ref(0)
	const getTotalListFun = function() {
		getTotalList({
			b_id: userObj().b_id
		}).then(res => {
			//console.log(res);
			total.value = res.data
		}).catch(err => {
			console.log(err);
		})
	}
	// 获取列表
	let loading = ref(false)
	let projectData = ref([])
	const getListFun = function() {
		loading.value=true
		getList({
			pageSize: pageSize.value,
			pageIndex: pageIndex.value,
			b_id: userObj().b_id
		}).then(res => {
			//console.log(res);
			loading.value=false
			if(res.data.length > 0) {
				projectData.value = res.data
				getTotalListFun()
			} else {
				projectData.value = []
			}
		}).catch(err => {
			console.log(err);
		})
	}

	// 新建编辑的相关事件与数据******************************************************
	// 新建或编辑弹窗
	let projectAlert = ref(false)
	// 新建或编辑的弹窗表单
	let projectForm = ref({
		name: '',
	})

	// 新建的点击
	const createFun = function() {
		edit_Id.value = ''
		projectForm.value = {}
		projectAlert.value = true
	}
	let edit_Id = ref()
	// 编辑的点击
	const editProject = function(scope) {
		edit_Id.value = scope.row.id
		projectAlert.value = true
		projectForm.value.name = scope.row.name
	}

	// 编辑新建的表单规则
	const projectRules = reactive({
		name: [{
			required: true,
			message: "请输入项目名称",
			trigger: "blur"
		}],
	})

	// 表单元素
	let projectFormRef = ref()
	// 新建或编辑提交
	const projectFormSubmit = function() {
		if(projectFormRef) {
			projectFormRef.value.validate((valid) => {
				//提交成功
				if(valid) {
					let params = {
						id: edit_Id.value,
						b_id: userObj().b_id,
						b_manager_id: userObj().b_manager_id,
						name: projectForm.value.name,
						status: 1,
						version: 1
					}
					saveList(
						params
					).then(res => {
						//console.log(res);
						getListFun()
						projectAlert.value = false
					}).catch(err => {
						console.log(err);
					})
				}
			})
		}
	}

	// 删除的相关事件与数据******************************************************
	let delProjectAlert = ref(false)
	// 删除
	const delProject = function(scope) {
		delProjectAlert.value = true
		edit_Id.value = scope.row.id
	}
	// 确定删除
	const delProjectFun = function() {
		let params = {
			id: edit_Id.value,
			status: 0
		}
		saveList(
			params
		).then(res => {
			//console.log(res);
			if(res.data.id) {
				delProjectAlert.value = false
				ElMessage({
					message: '删除成功',
					type: 'success',
				})
				getListFun()
			}
		}).catch(err => {
			console.log(err);
		})
	}
</script>