<template>
	<div class="integrateEquipment">
		<!--一体设备查询-->
		<div class="queryTitle">一体设备查询</div>
		<!--一体设备查询操作-->
		<div class="queryOperation">
			<el-select v-model="queryData.project_id" placeholder="请选择项目" clearable>
				<el-option v-for="item in projectData" :value="item.id" :label="item.name"></el-option>
			</el-select>
			<el-select v-model="queryData.product_id" placeholder="请选择网关pid+别名" clearable>
				<el-option v-for="item in productData" :value="item.id" :label="item.codeName"></el-option>
			</el-select>
			<el-select v-model="queryData.model_id" placeholder="请选择监测设备+别名" clearable>
				<el-option v-for="item in modelData" :value="item.id" :label="item.codeName"></el-option>
			</el-select>
			<el-input v-model="queryData.code" placeholder="网关别名" clearable></el-input>
			<el-button @click="getListFun('search')">查询</el-button>
		</div>
		<!--批量导入和新增一体设备操作-->
		<div class="gatewayOperation">
			<p>一体设备管理</p>
			<div style="display: none;">
				<el-upload v-model:file-list="fileList" :before-upload="beforeUpload" :data="personData" :show-file-list="false" accept=".xls,.xlsx" action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" class="upload-demo">
					<button id="fileName" slot="trigger" class="selectFile" size="small" type="primary">选择文件</button>
				</el-upload>
			</div>
			<div class="operation">
				<el-button><a style="color: #fff;" href="https://lotb.380v.com/template/batch_import_one_device_template.xlsx">模板下载</a></el-button>
				<el-button @click="batchImport">批量导入一体设备</el-button>
				<el-button @click="createGatewayFun">新增一体设备</el-button>
			</div>
		</div>
		<!--选择校时-->
		<div class="timeCorrection">
			<div></div>
			<el-button @click="checkTimeClick">校时</el-button>
		</div>
		<!--一体设备表格-->
		<el-table :data="gatewayData" border style="width: 100%;margin-top: 5px" table-layout="auto" v-loading="loading" @selection-change="handleSelectionChange">
			<el-table-column label="选取" type="selection" width="60" style="height:20px" />
			<el-table-column label="序号" prop="idx" width='60'>
				<template #default="scope">
					{{(pageIndex-1)*pageSize+scope.$index+1}}
				</template>
			</el-table-column>
			<el-table-column label="批次" prop="batch_no" width='70' />
			<el-table-column label="通讯地址" prop="addr" width='180' />
			<el-table-column label="软件版本号" prop="soft_v" width='160' />
			<el-table-column label="硬件版本号" prop="hard_v" width='160' />
			<el-table-column label="在线状态" prop="use_status" width='90'>
				<template #default="scope">{{ scope.row.use_status == '1' ? '在线' : '离线' }}</template>
			</el-table-column>
			<el-table-column label="最后上报时间" prop="last_time" width='170'>
				<template #default="scope">{{ moment(scope.row.last_time).format('YYYY-MM-DD HH:mm:ss') }}</template>
			</el-table-column>
			<el-table-column label="网关PID" prop="product_code" width='140' />
			<el-table-column label="网关标识" prop="code" width='140' />
			<el-table-column label="网关PID别名" prop="product_name" width='140' />
			<el-table-column label="备注" prop="remark" width='140' />
			<el-table-column fixed="right" label="操作" width="230">
				<template #default="scope">
					<ul>
						<li @click="editGatewayFun(scope)">编辑</li>
						<li @click="transmissionFun(scope.row.id)">透传</li>
						<li @click="readNetworkParameterFun(scope.row)">网络参数</li>
						<li @click="managementFun(scope)">管理</li>
						<li @click="delGatewayFun(scope)">删除</li>
					</ul>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="prev, pager, next" :page-size="pageSize" :total="total" hide-on-single-page />

		<!--新建或编辑一体设备弹窗-->
		<el-dialog v-model="gatewayAlert" class="gatewayAlert" :title="title" width="40%" :close-on-click-modal="false">
			<el-form :model="gatewayForm">
				<el-form-item label="选择项目" prop="project">
					<el-select v-model="gatewayForm.project_id" placeholder="请选择项目">
						<el-option v-for="item in projectData" :value="item.id" :label="item.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="网关PID" prop="product_id">
					<el-select v-model="gatewayForm.product_id" placeholder="pid+别名">
						<el-option v-for="item in productData" :value="item.id" :label="item.codeName"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="通讯地址" prop="addr">
					<el-input v-model="gatewayForm.addr" placeholder="单行输入" @change="addrChangeFun" />
				</el-form-item>
				<el-form-item label="设备PID" prop="model_id">
					<el-select v-model="gatewayForm.model_id" placeholder="请选择设备PID+设备别名">
						<el-option v-for="item in modelData" :value="item.id" :label="item.codeName"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="端口" prop="port">
					<el-select v-model="gatewayForm.port" placeholder="请选择端口">
						<el-option v-for="item in portData" :key="item.value" :label="item.name" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="变比" prop="rate_a">
					<el-input v-model="gatewayForm.rate_a" placeholder="电流" />
				</el-form-item>
				<el-form-item prop="rate_v">
					<el-input v-model="gatewayForm.rate_v" placeholder="电压" />
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input v-model="gatewayForm.remark" placeholder="单行输入" />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="gatewayFormSubmit">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<!--读取网络参数-->
		<el-dialog v-model="networkParameter" title="网络参数" width="80%" :close-on-click-modal="false">
			<el-table :data="networkParameterData" style="width: 100%" border v-loading="networkParameterLoading">
				<el-table-column label="序号" prop="idx" width="100px">
					<template #default="scope">
						{{scope.$index+1}}
					</template>
				</el-table-column>
				<el-table-column prop="codeName" label="PID+别名" min-width="2%">
				</el-table-column>
				<el-table-column prop="project_name" label="项目" min-width="2%">
				</el-table-column>
				<el-table-column prop="addr" label="通讯地址" min-width="2%">
				</el-table-column>
				<el-table-column prop="CELL_ID" label="CELL_ID" min-width="2%">
				</el-table-column>
				<el-table-column prop="RSRP" label="RSRP" min-width="2%">
				</el-table-column>
				<el-table-column prop="SINR" label="SINR" min-width="2%">
				</el-table-column>
				<el-table-column prop="RSRQ" label="RSRQ" min-width="2%">
				</el-table-column>
				<el-table-column prop="CSQ" label="信号强度" min-width="2%">
				</el-table-column>
			</el-table>
			<div style="text-align: center;margin-top: 20px;">
				<el-button @click="networkParameter=false" class="" style="background-color: #2477F2;color: #fff;">确定</el-button>
			</div>
		</el-dialog>
		<!--透传一体设备弹窗-->
		<el-dialog v-model="gatewayTrans" class="gatewayTrans" title="透传" width="50%" :close-on-click-modal="false">
			<div class="textarea1">
				<el-table :data="readData" style="width: 100%" v-if="readData.length>0" border v-loading="readLoading">
					<el-table-column prop="apply_time" label="上报时间" min-width="1%">
					</el-table-column>
					<el-table-column prop="apply" label="上报内容" min-width="2%">
					</el-table-column>
				</el-table>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-input type="textarea" autosize v-model="msg" class="textarea2">
				</el-input>
				<el-button @click="sendSave()" class="confrim1" style="background-color: #2477F2;color: #fff;">发送</el-button>
			</div>
		</el-dialog>

		<!--管理一体设备弹窗-->
		<el-dialog v-model="managementAlert" class="managementAlert" width="80%" :close-on-click-modal="false">
			<!--一体设备管理-->
			<div class="title">
				<p>一体设备管理</p>
				<el-button @click="managementAlert=false">关闭</el-button>
			</div>
			<!--表格-->
			<el-table :data="managementData" border v-loading='managementLoading'>
				<el-table-column label="通道名称" prop="lotName" width="120" />
				<el-table-column label="通讯地址" prop="addr" width="200" />
				<el-table-column label="设备状态" prop="" width="100">
					<template #default="scope">
						<span v-if="scope.row.use_status==0">离线</span>
						<span v-else-if="scope.row.use_status==1">在线</span>
					</template>
				</el-table-column>
				<el-table-column label="信号强度" prop="intensity" width="100" />
				<el-table-column label="ICCID" prop="iccid" width="240" />
				<el-table-column label="IMEI" prop="imei" width="200" />
				<el-table-column label="IMSI" prop="imsi" width="200" />
				<el-table-column label="硬件版本" prop="hard_v" width="160" fixed="right" />
				<el-table-column label="软件版本" prop="soft_v" width="160" fixed="right" />
			</el-table>
			<!--导航栏-->
			<div class="navTitle">
				<div class="navBar">
					<ul>
						<li :class="activeNav===1?'activeNav':''" @click="navClick(1)" v-if="has_model==1">物模型</li>
						<li :class="activeNav===2?'activeNav':''" @click="navClick(2)">设备</li>
						<li :class="activeNav===3?'activeNav':''" @click="navClick(3)">方案</li>
						<li :class="activeNav===4?'activeNav':''" @click="navClick(4)">任务</li>
						<li :class="activeNav===5?'activeNav':''" @click="navClick(5)">上报历史</li>
					</ul>
				</div>
			</div>
			<!--每个导航的内容-->
			<div class="navCont">
				<Model v-if="activeNav===1&&has_model==1" :value="managementData" />
				<Equipment v-if="activeNav===2" :value="managementData" />
				<Scheme v-if="activeNav===3" :value="managementData" />
				<Task v-if="activeNav===4" :value="managementData" />
				<History v-if="activeNav===5" :value="managementData" />
			</div>
		</el-dialog>

		<!--删除一体设备弹窗-->
		<el-dialog v-model="delGatewayAlert" class="delGatewayAlert" width="40%" :close-on-click-modal="false">
			<h3>您确定要删除该一体设备吗？</h3>
			<template #footer>
				<span class="dialog-footer">
        <el-button @click="delGatewayAlert = false">取消</el-button>
        <el-button type="primary" @click="delGatewaySubmit">确定</el-button>
      </span>
			</template>
		</el-dialog>

	</div>
</template>

<script setup>
	//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
	//例如:import 《组件名称》 from '《组件路径》';
	import { onMounted, reactive, ref } from "vue"
	// 引入样式
	import './integratedEquipment.less'
	import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
	import Model from "./navCont/model"
	import Equipment from "./navCont/device";
	import Scheme from "./navCont/plan";
	import Task from "./navCont/task";
	import History from "./navCont/history";
	import { getTotalList, getList, getGatewayList, getProjectList, getProductList, addrChange, saveList, getProductById, getChannelById, uploadFile, importWhole, checkTimeFun, sendCommandList, command_applyList, getModelList, saveDeviceList, getDeviceList, deviceSave, readNetParamsList } from '../../api/modules/integratedEquipment.js'
	import userObj from '../../assets/js/cookie'
	import moment from 'moment'
	import { v1 as uuidv1 } from 'uuid'

	onMounted(() => {
		getListFun() //一体设备列表
		getProjectListFun() //项目列表
		getProductListFun() //网关产品列表
		getModelListFun()
	})

	// 分页 **********************************************************
	// 当前页数
	let pageIndex = ref(1)
	// 每页条数
	let pageSize = ref(20)
	//每页多少
	const handleSizeChange = function(val) {
		pageSize.value = val;
		getListFun();
	}
	//更改页数
	const handleCurrentChange = function(val) {
		pageIndex.value = val;
		getListFun();
	}
	// 获取总条数
	const total = ref(0)
	const getTotalListFun = function() {
		let params = {}
		params['b_id'] = userObj().b_id
		params['isbind'] = 1
		if(queryData.value.project_id) {
			params['project_id'] = queryData.value.project_id
		}
		if(queryData.value.product_id) {
			params['product_id'] = queryData.value.product_id
		}
		if(queryData.value.model_id) {
			params['model_id'] = queryData.value.model_id
		}
		if(queryData.value.code) {
			params['code'] = queryData.value.code
		}
		getTotalList(params).then(res => {
			//console.log(res);
			total.value = res.data
		}).catch(err => {
			console.log(err);
		})
	}
	// 获取列表
	let gatewayData = ref([])
	let loading = ref(false)
	const getListFun = function(val) {
		var pageIndexCopy;
		if(val == 'search') {
			pageIndexCopy = 1
		} else {
			pageIndexCopy = pageIndex.value
		}
		loading.value = true
		let params = {}
		params['b_id'] = userObj().b_id
		params['isbind'] = 1
		params['pageSize'] = pageSize.value
		params['pageIndex'] = pageIndexCopy
		if(queryData.value.project_id) {
			params['project_id'] = queryData.value.project_id
		}
		if(queryData.value.product_id) {
			params['product_id'] = queryData.value.product_id
		}
		if(queryData.value.model_id) {
			params['model_id'] = queryData.value.model_id
		}
		if(queryData.value.code) {
			params['code'] = queryData.value.code
		}
		getList(params).then(res => {
			//console.log(res);
			loading.value = false
			if(res.data.length > 0) {
				gatewayData.value = res.data
				getTotalListFun()
			} else {
				gatewayData.value = []
			}
		}).catch(err => {
			console.log(err);
		})
	}
	//获取所属B端的未绑定的一体设备列表
	let gatewayNoBandList = ref([])
	const gatewayNoBandListFun = function() {
		getGatewayList({
			pageSize: -1,
			pageIndex: 1,
			b_id: userObj().b_id,
			isbind: 0,
		}).then(res => {
			//console.log(res);
			loading.value = false
			if(res.data.length > 0) {
				gatewayNoBandList.value = res.data
			} else {
				gatewayNoBandList.value = []
			}
		}).catch(err => {
			console.log(err);
		})
	}
	// 获取项目列表
	let projectData = ref([])
	const getProjectListFun = function() {
		getProjectList({
			pageSize: -1,
			pageIndex: 1,
			b_id: userObj().b_id
		}).then(res => {
			//console.log(res);
			if(res.data.length > 0) {
				projectData.value = res.data
			} else {
				projectData.value = []
			}
		}).catch(err => {
			console.log(err);
		})
	}
	// 获取网管产品列表
	let productData = ref([])
	const getProductListFun = function() {
		getProductList({
			pageSize: -1,
			pageIndex: 1,
			b_id: userObj().b_id
		}).then(res => {
			//console.log(res);
			if(res.data.length > 0) {
				productData.value = res.data
				productData.value.forEach(item => {
					item.codeName = item.code + '+' + item.name
				})
			} else {
				productData.value = []
			}
		}).catch(err => {
			console.log(err);
		})
	}
	//addr是否存在判断
	let notDo = ref(false)
	const addrChangeFun = function() {
		addrChange({
			addr: gatewayForm.value.addr
		}).then(res => {
			//console.log(res);
			if(res.data.b_id == userObj().b_id) { //存在，所属b端
				edit_Id.value = res.data.id
				if(res.data.isbind == 0) { //没绑定
					notDo.value = false
				} else { //已绑定
					if(edit_Id.value && gatewayForm.value.addr == listRow.value.addr) {
						notDo.value = false
						return
					}
					ElMessage({
						message: '该地址已存在！',
						type: 'info',
					})
					gatewayForm.value.addr = ''
					notDo.value = true
				}
			} else { //不存在
				ElMessage({
					message: '该地址不存在！',
					type: 'info',
				})
				gatewayForm.value.addr = ''
				notDo.value = true
			}
		}).catch(err => {
			console.log(err);
		})
	}

	// 一体设备查询相关事件与数据********************************************************
	// 一体设备查询数据
	let queryData = ref({
		project_id: '',
		product_id: '',
		product_name: '',
		model_id: ''
	})

	// 新增编辑一体设备数据及相关事件********************************************************
	// 新建编辑一体设备弹窗
	let gatewayAlert = ref(false)
	let title = ref()

	// 新建编辑弹窗数据
	let gatewayForm = ref({
		project_id: '',
		product_id: '',
		addr: '',
		remark: '',
		model_id: '',
		port: '',
		rate_v: 1,
		rate_a: 1
	})
	//获取设备型号列表
	let modelData = ref()
	const getModelListFun = function() {
		getModelList({
			pageSize: -1,
			pageIndex: 1,
			b_id: userObj().b_id
		}).then(res => {
			//console.log(res);
			if(res.data.length > 0) {
				modelData.value = res.data
				modelData.value.forEach(item => {
					item.codeName = item.code + '+' + item.name
				})
			} else {
				modelData.value = []
			}
		}).catch(err => {
			console.log(err);
		})
	}
	//端口列表
	let portData = ref([{
			name: 'F2010201:4851',
			value: 'F2010201'
		},
		{
			name: 'F2010202:4852',
			value: 'F2010202'
		},
		{
			name: 'F20B0201:蓝牙',
			value: 'F20B0201'
		},
		{
			name: 'F2090201:载波/微功率无线接口',
			value: 'F2090201'
		},
	])
	// 点击新增一体设备
	const createGatewayFun = function() {
		listRow.value = {}
		title.value = "新增一体设备"
		edit_Id.value = ''
		gatewayForm.value = {
			project_id: '',
			product_id: '',
			addr: '',
			remark: '',
			model_id: '',
			port: '',
			rate_v: 1,
			rate_a: 1
		}
		gatewayAlert.value = true
		gatewayNoBandListFun() //未绑定一体设备
	}

	let edit_Id = ref()
	let listRow = ref({})
	// 点击编辑一体设备
	const editGatewayFun = function(scope) {
		title.value = "编辑一体设备"
		gatewayNoBandListFun() //未绑定一体设备
		gatewayForm.value = {}
		edit_Id.value = scope.row.id
		listRow.value = scope.row
		gatewayForm.value.project_id = scope.row.project_id
		gatewayForm.value.addr = scope.row.addr
		gatewayForm.value.remark = scope.row.remark
		gatewayForm.value.product_id = scope.row.product_id
		gatewayAlert.value = true
		getDeviceListFun(scope.row.id)
	}
	//设备列表
	let device_Id = ref()
	const deviceList = ref([])
	const getDeviceListFun = function(gateway_id) {
		getDeviceList({
			pageSize: -1,
			pageIndex: 1,
			gateway_id: gateway_id
		}).then(res => {
			//console.log(res);
			if(res.data.length > 0) {
				deviceList.value = res.data
				device_Id.value = res.data[0].id
				gatewayForm.value.port = res.data[0].port
				gatewayForm.value.rate_a = res.data[0].rate_a
				gatewayForm.value.rate_v = res.data[0].rate_v
				gatewayForm.value.model_id = res.data[0].model_id
			} else {
				deviceList.value = []
			}
		}).catch(err => {
			console.log(err);
		})
	}
	// 新建编辑一体设备提交
	const gatewayFormSubmit = function() {
		let params = {
			id: edit_Id.value,
			type: 2,
			isbind: 1,
			project_id: gatewayForm.value.project_id,
			addr: gatewayForm.value.addr,
			remark: gatewayForm.value.remark,
		}
		let paramsDelete = {
			id: listRow.value.id,
			isbind: 0,
		}
		if(title.value == '编辑一体设备') { //编辑
			if(listRow.value.addr == gatewayForm.value.addr) { //没更换addr
				saveGatewayFun(params)
			} else { //更换了addr
				if(!notDo.value) {
					saveList(
						paramsDelete
					).then(res => {
						//console.log(res);
						saveGatewayFun(params)
					}).catch(err => {
						console.log(err);
					})
				}
			}
		} else { //新增
			saveGatewayFun(params)
		}
	}
	//一体设备的网关保存
	const saveGatewayFun = function(params) {
		if(!notDo.value) {
			if(gatewayForm.value.addr && gatewayForm.value.project_id && gatewayForm.value.product_id && gatewayForm.value.model_id && gatewayForm.value.port && gatewayForm.value.rate_v && gatewayForm.value.rate_a) {
				saveList(
					params
				).then(res => {
					//console.log(res);
					if(res.data.isbind == 1) {
						saveDeviceFun(res.data.id)
					}
					gatewayAlert.value = false
				}).catch(err => {
					console.log(err);
				})
			} else {
				ElMessage({
					message: '请输入必填项！',
					type: 'info',
				})
			}
		}
	}
	//一体设备的设备保存
	const saveDeviceFun = function(gateway_id) {
		var addr;
		addr = gatewayForm.value.addr.padStart(12, '0')
		var params = {
			id: device_Id.value,
			b_manager_id: userObj().b_manager_id,
			model_id: gatewayForm.value.model_id,
			gateway_id: gateway_id,
			password: '123456020000', //通信密码
			ruler_type: 5, //规约类型
			fee_num: 4, //费率个数
			user_type: 1, //用户类型
			rate_type: 134, //波特率
			join_type: 3, //接线方式
			rated_v: 1, //额定电压
			rated_a: 1, //额定电流
			p_code: '000000000000', //资产号
			port: gatewayForm.value.port, //端口
			rate_v: gatewayForm.value.rate_v, //电压变比
			rate_a: gatewayForm.value.rate_a, //电流变比
			addr: addr, //通讯地址
			create_time: Date.now(),
			status: 1,
			version: 1
		}
		saveDeviceList(
			params
		).then(res => {
			//console.log(res);
			getListFun()
		}).catch(err => {
			console.log(err);
		})
	}
	// 删除一体设备相关数据及事件********************************************************
	// 一体设备弹窗是否显示
	let delGatewayAlert = ref(false)
	let rowObj = ref({})
	// 删除一体设备
	const delGatewayFun = function(scope) {
		edit_Id.value = scope.row.id
		rowObj.value = scope.row
		delGatewayAlert.value = true
		getDeviceListFun(scope.row.id)
	}
	// 确定删除一体设备
	const delGatewaySubmit = function() {
		for(var i = 0; i < deviceList.value.length; i++) {
			deviceSave({
				id: deviceList.value[i].id,
				status: 0,
			}).then(res => {
				//console.log(res);
			}).catch(err => {
				console.log(err);
			})
		}
		let params = {
			id: edit_Id.value,
			isbind: 0
		}
		saveList(
			params
		).then(res => {
			//console.log(res);
			if(res.data.id) {
				delGatewayAlert.value = false
				ElMessage({
					message: '删除成功',
					type: 'success',
				})
				getListFun()
			}
		}).catch(err => {
			console.log(err);
		})
	}

	// 透传相关事件********************************************************
	let gatewayTrans = ref(false)

	const transmissionFun = function(id) {
		gatewayTrans.value = true
		edit_Id.value = id
	}
	let readLoading = ref(false)
	let readData = ref([])
	let msg = ref()
	// 透传提交
	const sendSave = function(id) {
		if(msg.value) {
			msg.value = msg.value.replace(/\s*/g, ""); //去除空格
			openFullScreen()
			sendCommandList({
				gateway_id: edit_Id.value,
				b_manager_id: userObj().b_manager_id,
				msg: msg.value
			}).then(res => {
				//readLoading.value = true
				//console.log(res)
				if(res.data.res == 1) {
					command_applyListFun(res.data.id);
					ElMessage({
						type: 'success',
						message: '透传发送成功!'
					});
				} else {
					ElMessage({
						type: 'error',
						message: res.data.msg + '!'
					});
					//readLoading.value = false
					loadingVal.value.close()
				}
			}).catch(err => {
				console.log(err);
			})
		} else {
			ElMessage({
				type: 'info',
				message: '请先输入报文!'
			});
		}
	}

	//透传读取列表
	const command_applyListFun = function(id) {
		var params = {
			pageSize: -1,
			pageIndex: 1,
			command_log_id: id
		}
		command_applyList(params).then(res => {
			//readLoading.value = false
			loadingVal.value.close()
			readData.value = []
			if(res.data.length > 0) {
				readData.value = res.data
				readData.value.forEach(item => {
					item.apply_time = moment(item.apply_time).format('YYYY-MM-DD HH:mm:ss')
				})
			} else {
				readData.value = []
			}
		}).catch(err => {
			console.log(err);
		})
	}
	// 导入一体设备
	let personData = ref({}) //自定义上传参数
	let fileList = ref([]) //上传文件
	let b_id = ref()
	const batchImport = function() {
		document.getElementById("fileName").click();
	}
	// 上传
	const beforeUpload = function(file) {
		let fd = new FormData();
		fd.append('file', file); //传文件
		fd.append('path', '/mnt/xvdb/html/gw/storage/upload/' + new Date().getFullYear() + new Date().getMonth() + 1); //传其他参数
		var name = uuidv1()
		var index = file.name.lastIndexOf(".");
		var type = file.name.substring(index, file.name.length);
		fd.append('fileName', name + type); //传其他参数
		uploadFile(fd).then(res => {
			if(res.data) {
				importWholeFun(res.data);
			}
		}).catch(err => {
			console.log(err)
		})
	}
	// 导入一体设备
	const importWholeFun = function(file) {
		let fd = new FormData();
		fd.append('file', file); //传文件
		fd.append('b_manager_id ', userObj().b_manager_id); //传其他参数
		importWhole(fd).then(res => {
			if(res.data.res) {
				getListFun()
				ElMessage({
					message: '导入成功',
					type: 'success'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}

	// 管理一体设备相关数据及事件********************************************************
	// 管理弹窗是否显示---------------------------
	let managementAlert = ref(false)
	let gatwayId = ref(0)
	let has_model = ref()
	// 点击显示管理弹窗
	const managementFun = function(scope) {
		managementData.value = []
		managementAlert.value = true
		managementData.value.push(scope.row)
		managementLoading.value = true
		gatwayId.value = scope.row.id
		getProductById(scope.row.product_id).then(res => {
			//console.log(res);
			if(res.data.id) {
				has_model.value = res.data.has_model
				if(has_model.value == 0) {
					activeNav.value = 2
				} else if(has_model.value == 1) {
					activeNav.value = 1
				}
				getChannelById(res.data.channel_id).then(res => {
					//console.log(res);
					managementLoading.value = false
					if(res.data.id) {
						managementData.value[0].lotName = res.data.name
					}
				}).catch(err => {
					console.log(err);
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	let managementLoading = ref(false)
	// 管理弹窗上面的表格数据
	let managementData = ref([])
	// 管理弹窗的导航栏---------------------------
	let activeNav = ref(1)
	// 点击导航栏
	const navClick = function(idx) {
		activeNav.value = idx
	}

	/*加载loading*/
	const loadingVal = ref()
	const openFullScreen = () => {
		loadingVal.value = ElLoading.service({
			lock: true,
			text: '',
			background: 'rgba(0, 0, 0, 0.7)',
		})
	}
	//对选
	let multipleSelection = ref([])
	const handleSelectionChange = function(val) {
		multipleSelection.value = val
	}
	//读取网络参数
	let networkParameter = ref(false)
	let networkParameterData = ref([])
	let networkParameterLoading = ref(false)
	const readNetworkParameterFun = function(row) {
		networkParameterLoading.value = true
		networkParameter.value = true
		networkParameterData.value = []
		readNetParamsList({
			gateway_id: row.id,
			b_manager_id: userObj().b_manager_id,
		}).then(res => {
			//console.log(res)
			networkParameterLoading.value = false
			if(res.data.res == 1) {
				var obj = {}
				if(res.data.dataRes.length > 0) {
					res.data.dataRes.forEach(item => {
						item = item.replace(/\s*/g, "")
						item = item.split(':')
						obj[item[0]] = item[1]
					})
					networkParameterData.value.push(obj)
					networkParameterData.value[0].codeName = row.product_code + '+' + row.product_name
					networkParameterData.value[0].project_name = row.project_name
					networkParameterData.value[0].addr = row.addr
				}
			} else {
				networkParameterData.value = []
				ElMessage({
					type: 'error',
					message: res.data.msg + '!'
				});
			}
		}).catch(err => {
			console.log(err);
		})
	}
	//校时
	const checkTimeClick = function() {
		if(multipleSelection.value.length > 0) {
			ElMessageBox.confirm("确认校时吗？", '确认要校时吗？', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'success',
			}).then(() => {
				openFullScreen()
				for(var i = 0; i < multipleSelection.value.length; i++) {
					checkTimeFun({
						gateway_id: multipleSelection.value[i].id,
						b_manager_id: userObj().b_manager_id
					}).then(res => {
						loadingVal.value.close()
						//console.log(res);
						if(res.data.res == 1) {
							ElMessage({
								message: '校时成功！',
								type: 'success',
							})
						} else {
							ElMessage({
								type: 'error',
								message: res.data.msg + '!'
							});
						}
						getListFun()
					}).catch(err => {
						console.log(err);
					})
				}
			}).catch(() => {
				ElMessage({
					type: 'info',
					message: '已取消校时！',
				})
			})
		} else {
			ElMessage({
				message: '请至少选择一个一体设备数据！',
				type: 'info',
			})
		}
	}
</script>